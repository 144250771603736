import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GeneralModel, ViewModel } from '@cyferd/client-engine';
import { styles } from './styles';
import type { IOptionListItem } from '../OptionMenu';
import { CTA, CTAType } from '../CTA';
import { InputWrapper } from '../InputWrapper';
import { Modal } from '../Modal';
import { useOutsideClick } from '@utils/useOutsideClick';
import { RichEditorComposition } from './RichEditorComposition';

export interface RichTextEditorProps {
  description?: string;
  disabled?: boolean;
  errorMessage?: string;
  label?: string;
  onChange: (editorState: string) => void;
  optionList?: IOptionListItem[];
  required?: boolean;
  id?: string;
  value: string;
  info?: string;
  color?: GeneralModel.Color.ThemeColor;
  unlimitedHeight?: boolean;
  disabledType?: GeneralModel.DisabledType;
}

export const RichTextEditor: FC<RichTextEditorProps> = ({
  description,
  disabled,
  errorMessage,
  label,
  optionList,
  required,
  id,
  value,
  onChange,
  info,
  color,
  unlimitedHeight,
  disabledType
}) => {
  const testid = id || 'rich-text-editor';

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const onToggleModal = useCallback(() => setModalOpen(open => !open), []);
  const onFocus = useCallback(() => setIsFocused(true), []);
  const onBlur = useCallback(() => setIsFocused(false), []);
  const mainRef = useOutsideClick(onBlur, 'mousedown', true);

  const inputContainerRef = useRef<HTMLDivElement>(null);
  const modalContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (modalContainerRef.current) modalContainerRef.current.scrollTo(0, 0);
    if (inputContainerRef.current) inputContainerRef.current.scrollTo(0, 0);
  }, [modalOpen]);

  const isReadonly = !!disabled && [GeneralModel.DisabledType.VIEW_ONLY, null, undefined].includes(disabledType);
  const internalOptionList = useMemo(
    () =>
      [
        ...(optionList || []),
        !unlimitedHeight &&
          (!isReadonly || !!value) && {
            tooltip: 'Full screen',
            image: 'open_in_full',
            onClick: onToggleModal,
            type: CTAType.LINK,
            important: !optionList?.length,
            size: ViewModel.CTASize.MEDIUM
          }
      ].filter(Boolean) as IOptionListItem[],
    [isReadonly, onToggleModal, optionList, unlimitedHeight, value]
  );

  const onInternalChange = useCallback(
    (newValue: string) => {
      if (!disabled) {
        onChange(newValue);
      }
    },
    [disabled, onChange]
  );

  return (
    <div ref={mainRef}>
      <InputWrapper
        color={color}
        id={testid}
        optionList={internalOptionList}
        description={description}
        disabled={!!disabled}
        errorMessage={errorMessage}
        unlimitedHeight={true}
        showPlaceholderLine={true}
        label={label}
        required={required}
        testid={testid}
        value={value}
        info={info}
        disabledType={disabledType}
        disablePadding={isFocused}
      >
        <div css={[styles.container({ unlimitedHeight }), !!color && styles.containerColor]} tabIndex={0} onFocus={onFocus} ref={inputContainerRef}>
          <RichEditorComposition
            value={value}
            onChange={onInternalChange}
            isEditable={!disabled}
            isFocused={isFocused}
            modalOpen={modalOpen}
            isCollapsed={true}
            isReadOnly={isReadonly}
          />
        </div>
      </InputWrapper>
      {modalOpen && (
        <Modal
          title={label}
          description={description}
          open={true}
          type={ViewModel.ModalType.REGULAR}
          onClose={() => setModalOpen(false)}
          footer={<CTA type={CTAType.PRIMARY} label="OK" onClick={onToggleModal} />}
        >
          <div css={[styles.modalEditorContainer]} ref={modalContainerRef}>
            <RichEditorComposition
              value={value}
              onChange={onInternalChange}
              isEditable={!disabled}
              isFocused={true}
              modalOpen={modalOpen}
              isCollapsed={false}
              isReadOnly={isReadonly}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export interface RichTextProps {
  value: string;
}

export const RichText = ({ value }: RichTextProps) => {
  if (typeof value !== 'string') return null;

  return <RichEditorComposition value={value} isEditable={false} isFocused={false} isReadOnly={true} />;
};
