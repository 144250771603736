import Skeleton from '@components/elements/Skeleton/Skeleton';
import { CTA, CTAType } from '@components/elements/CTA';
import { PreventClickPropagation } from '@components/elements/PreventClickPropagation';
import { ViewModel } from '@cyferd/client-engine';
import { NavigationSkeletonNewLayout } from './components/NavigationMenuSkeleton';
import { styles } from './styles';
import { NavigationMenuContent } from './components/NavigationMenuContent';
import { useNavigationMenu } from '@components/platform/MainLayout/context/NavigationMenuContext';
import React, { useEffect } from 'react';
import { NavigationMenuSearch } from './components/NavigationMenuSearch';
import { useSearchContext } from '@components/platform/MainLayout/context/SearchContext';

// TODO: the app/builder is not highlighted if the its views, collections are selected
// TODO: the recent views dropdown is located too high ?
export const NavigationMenu = ({ user, data = [], isLoading, recentViews }) => {
  const { isHidden, isExpanded, toggleSidebarMode } = useNavigationMenu();

  const { onSearch } = useSearchContext();

  const isAdmin = !!user?.roles?.length;

  useEffect(() => {
    if (isHidden || !isExpanded) onSearch('');
  }, [isHidden, isExpanded]);

  return (
    <Skeleton loading={isLoading} content={<NavigationSkeletonNewLayout />}>
      <div css={styles.container}>
        <div css={[styles.menu, !isExpanded && styles.collapsedMenu, isHidden && styles.menuHidden]} data-testid="main-nav-content">
          <div css={[styles.header, !isExpanded && styles.headerCollapsed]}>
            <NavigationMenuSearch />

            <PreventClickPropagation>
              <CTA
                testid="AppNavigationWrapper-cta"
                type={CTAType.LINK}
                size={ViewModel.CTASize.LARGE}
                onClick={toggleSidebarMode}
                icon={{ name: isExpanded ? 'keyboard_double_arrow_left' : 'keyboard_double_arrow_right' }}
              />
            </PreventClickPropagation>
          </div>

          <div css={[styles.containerTab]}>
            <NavigationMenuContent isOpen={isExpanded} data={data} recentViews={recentViews} isAdmin={isAdmin} />
          </div>
        </div>
      </div>
    </Skeleton>
  );
};
