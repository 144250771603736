import { getItemHeight } from '@components/elements/Layout/styles';
import { useBannerStore } from '../providers/bannerProvider';

import { useEffect, useState } from 'react';

export const useGetItemHeight = (config: string, top: number | undefined = 0): string => {
  const [height, setHeight] = useState<string>(() => getItemHeight(config, top));

  const bannerHeight = useBannerStore(state => state.bannerHeight);
  const startBannerHeightListener = useBannerStore(state => state.startBannerHeightListener);

  useEffect(() => {
    const stopListener = startBannerHeightListener();
    setHeight(getItemHeight(config, top));
    return () => stopListener();
  }, [config, top, bannerHeight, startBannerHeightListener]);

  return height;
};
