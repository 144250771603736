import { memo, useCallback } from 'react';

import { DropdownMenu } from '@components/elements/DropdownMenu';
import { CheckboxMenu } from '@components/elements/CheckboxMenu';
import type { ChipDropdownProps } from './types';
import { Chip } from '../Chip';
import { ChipType } from '../types';
import { styles } from './styles';

export const ChipDropdown = memo(({ id, activeValues = [], options, testid = 'chip-dropdown', isLoading, ...props }: ChipDropdownProps) => {
  const onSelect = useCallback(opt => {
    opt.onClick?.();
  }, []);

  return (
    <div id={id} data-testid={testid}>
      <DropdownMenu
        horizontalResponsive={false}
        renderTrigger={({ onClick, ref, testid, isOpen }) => (
          <div onClick={!isLoading ? onClick : undefined} ref={ref} data-testid={testid}>
            <Chip {...props} dropdownOpen={isOpen} type={ChipType.DROPDOWN} isLoading={isLoading} activeValues={activeValues} />
          </div>
        )}
        vertical={4}
      >
        <div css={styles.menuContainer}>
          <CheckboxMenu optionList={options} hideSearch={true} selectedOptions={activeValues} onSelect={onSelect} defaultCheckboxColor="BRAND_3" />
        </div>
      </DropdownMenu>
    </div>
  );
});
