import { COLOR, GAP, GLOBAL_HEADER_HEIGHT } from '@constants';
import { css } from '@emotion/react';

export const cyListHeight = `calc(100vh - ${GLOBAL_HEADER_HEIGHT} - (${GAP.L} * 1))`;

export const styles = {
  toolbar: css`
    display: flex;
    gap: ${GAP.M};
    align-items: center;
    justify-content: center;
  `,
  prevent: css`
    display: block;
    width: 100%;
    height: 100%;
  `,
  avatar: css`
    cursor: pointer;
    width: 32px;
    height: 32px;
  `,
  relativeContainer: css`
    position: relative;
  `,
  dot: css`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    right: 0px;
    transition: background-color 0.2s ease-in-out;
    background-color: ${COLOR.RD_1};
  `
};
