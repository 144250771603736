import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';
import { $getSelection, $isRangeSelection, COPY_COMMAND } from 'lexical';
import { $generateHtmlFromNodes } from '@lexical/html';
import { normalizeHtml } from '../utils/normalization';

export const CopyPlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      COPY_COMMAND,
      (event: ClipboardEvent) => {
        event.preventDefault();

        const clipboardData = event.clipboardData;

        if (clipboardData) {
          editor.update(() => {
            const selection = $getSelection();

            if ($isRangeSelection(selection)) {
              const htmlContent = $generateHtmlFromNodes(editor, selection);

              if (htmlContent) {
                const normalizedHtml = normalizeHtml(htmlContent);

                clipboardData.setData('text/html', normalizedHtml);
                clipboardData.setData('text/plain', selection.getTextContent());
              }
            }
          });
        }

        return true;
      },
      1
    );
  }, [editor]);

  return null;
};
