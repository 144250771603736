import { COLOR, FONT_SIZE, GAP, RADIUS } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  contentItem: css`
    min-height: 20px;
    padding: ${GAP.XXS};
    color: ${COLOR.NEUTRAL_1};
  `,
  numericFormat: css``,
  booleanFormat: css`
    justify-content: center;
  `,
  associationDropdownContainer: css`
    display: flex;
  `,
  associationIconContainer: css`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    overflow: hidden;
    width: ${FONT_SIZE.XM};
    height: ${FONT_SIZE.XM};
    margin-right: ${GAP.XS};
    img {
      max-width: 100%;
    }
  `,
  ratingContainer: css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${GAP.XS};
    padding-top: 0;
    padding-bottom: 0;
  `,
  ratingIcon: css`
    line-height: 0;
  `,
  ratingText: css`
    color: ${COLOR.NEUTRAL_1};
    display: inline-block;
  `,
  icon: css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  `,
  iconImageContainer: css`
    line-height: 0;
  `,
  colorContainer: css`
    display: flex;
    justify-content: center;
  `,
  ctaContainer: css`
    border-radius: ${RADIUS.S};
    padding: ${GAP.XXXS} ${GAP.S};
    background-color: ${COLOR.BASE_BACKGROUND_ALT_1};
    max-width: 100%;
    overflow: hidden;
  `,
  colorItem: css`
    width: 24px;
    height: 24px;
    border-radius: 100px;
    border: 1px solid ${COLOR.NEUTRAL_3};
  `,
  arrayContainer: css`
    max-height: 150px;
    overflow: auto;
    display: grid;
    grid-gap: ${GAP.XXS};
    flex: 1;
  `,
  evaluation: css`
    width: 100%;
    min-width: 150px;
  `
};
