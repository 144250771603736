import { useCyActions } from '@utils';
import { useCallback, useState } from 'react';
import { styles } from '../styles';
import { ItemMenuPopover } from '@components/platform/GlobalHeader/components/ItemMenuPopover';
import { ItemMenu } from './ItemMenu';
import { SubItemMenu } from '../../SubItemMenu';
import { BUILDER_ICON, BUILDER_ROUTE } from '@constants';
import { ErrorBoundary, ViewModel } from '@cyferd/client-engine';
import { Playground } from '../../../../../../../../builder/views/shared/Playground';
import { DevMenuData } from '@components/elements/DevMenuData';
import { DevMenuActions } from '@components/elements/DevMenuActions';
import { DevMenuInspector } from '@components/elements/DevMenuInspector';
import { Modal } from '@components/elements/Modal';

export const DevTools = ({ onClick, isOpen, isShowMore }) => {
  const [status, setStatus] = useState(ModalStatus.IDLE);

  const { onDispatchNavigateTo } = useCyActions();

  const onHandleModal = useCallback(
    (currentModal: ModalStatus) => setStatus(prevStatus => (prevStatus === currentModal ? ModalStatus.IDLE : currentModal)),
    []
  );

  const tools = [
    {
      title: 'Traffic',
      icon: 'compare_arrows',
      onClick: () => onHandleModal(ModalStatus.TRAFFIC)
    },
    {
      title: 'Data',
      icon: 'code_blocks',
      onClick: () => onHandleModal(ModalStatus.DATA)
    },
    {
      title: 'Resources',
      icon: 'account_tree',
      onClick: () => onHandleModal(ModalStatus.RESOURCES)
    },
    {
      title: 'Playground tools',
      icon: 'terminal',
      onClick: () => onHandleModal(ModalStatus.PLAYGROUND)
    },
    {
      title: 'Cyferd guide',
      icon: BUILDER_ICON.HELP,
      onClick: () => onDispatchNavigateTo({ path: BUILDER_ROUTE.GUIDE.ROOT })
    }
  ];

  const closeModal = useCallback(() => setStatus(ModalStatus.IDLE), []);

  return (
    <>
      <ItemMenuPopover disabled={isOpen} content={<ToolsList tools={tools} />}>
        <ItemMenu
          onClick={onClick}
          optionList={[]}
          containChildren={true}
          isActive={false}
          recordTitle="Dev tools"
          isShowMore={isShowMore}
          recordImage="terminal"
          isOpen={isOpen}
          onToggle={onClick}
          id={''}
        />
      </ItemMenuPopover>
      <div css={isOpen && isShowMore ? styles.show : styles.hide}>
        <ToolsList tools={tools} />
      </div>

      <ErrorBoundary>
        <Playground open={status === ModalStatus.PLAYGROUND} onClose={closeModal} />

        <Modal open={status === ModalStatus.DATA} title="Data" type={ViewModel.ModalType.FULL_SCREEN} onClose={closeModal}>
          <DevMenuData />
        </Modal>

        <Modal open={status === ModalStatus.TRAFFIC} title="Traffic" type={ViewModel.ModalType.FULL_SCREEN} onClose={closeModal}>
          <DevMenuActions />
        </Modal>

        <Modal open={status === ModalStatus.RESOURCES} title="Resources" type={ViewModel.ModalType.FULL_SCREEN} onClose={closeModal}>
          <DevMenuInspector />
        </Modal>
      </ErrorBoundary>
    </>
  );
};

const ToolsList = ({ tools }) => {
  return (
    <>
      {tools.map(tool => (
        <SubItemMenu.Value key={tool.title} title={tool.title} icon={tool.icon} isActive={false} onClick={tool.onClick} />
      ))}
    </>
  );
};

enum ModalStatus {
  IDLE = 'idle',
  DATA = 'data',
  TRAFFIC = 'traffic',
  RESOURCES = 'resources',
  PLAYGROUND = 'playground'
}
