import type { INavigationItem, INavigationList } from '@components/platform/GlobalHeader/types';
import { useCyActions } from '@utils';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { styles } from './styles';
import { styles as navigationStyles } from '../../styles';
import { SubItemMenu } from '../../../SubItemMenu';
import { useLocation } from 'react-router-dom';
import { ApiModel, ViewModel } from '@cyferd/client-engine';
import { ItemMenuPopover } from '@components/platform/GlobalHeader/components/ItemMenuPopover';
import { ItemMenu } from '../ItemMenu';
import { SideContent } from '../../components/SideContent';
import { useGetNavigateTo } from '../../../../hooks/useGetNavigateTo';
import { useSearchContext } from '@components/platform/MainLayout/context/SearchContext';

interface AppsProps {
  openId: string;
  setOpenId: (id) => void;
  isOpen: boolean;
  data: INavigationList;
  isAppActive: (id) => boolean;
}

const MAX_LIST_ITEM = 17;

// TODO: use memo()
// TODO: distribute components
export const Apps = ({ isOpen, data, openId, setOpenId, isAppActive }: AppsProps) => {
  const { getNavigateTo } = useGetNavigateTo();

  const { setAppsHaveResults, searchQuery } = useSearchContext();

  const extracount: number | false = data.length > MAX_LIST_ITEM && data.length - MAX_LIST_ITEM;

  const memoizedData = useMemo(() => {
    const filteredData = data.filter(({ recordTitle, views, collections }) => {
      const isAppTitleMatch = recordTitle.toLowerCase().includes(searchQuery.toLowerCase());
      const isViewsMatch = views.filter(({ recordTitle: viewTitle }) => viewTitle.toLowerCase().includes(searchQuery.toLowerCase())).length;
      const isCollectionsMatch = collections.filter(({ recordTitle: collectionTitle }) =>
        collectionTitle.toLowerCase().includes(searchQuery.toLowerCase())
      ).length;

      return isAppTitleMatch || isCollectionsMatch || isViewsMatch;
    });

    if (filteredData.length <= MAX_LIST_ITEM || isOpen) return filteredData;
    return filteredData.slice(0, MAX_LIST_ITEM);
  }, [data, isOpen, searchQuery]);

  useEffect(() => setAppsHaveResults(Boolean(memoizedData.length)), [searchQuery, memoizedData, setAppsHaveResults]);

  const onToggleOpenMore = useCallback(
    (id: string) => {
      setOpenId(prevId => (prevId === id ? null : id));
    },
    [setOpenId]
  );

  const onClick = useCallback(
    (appId: string) => getNavigateTo({ path: 'APP_HOME', qs: { id: appId } }, undefined, () => setOpenId(prevId => (prevId === appId ? null : appId))),
    [getNavigateTo, setOpenId]
  );

  return (
    <>
      <div css={styles.content} data-testid="AppNavigationWrapper">
        {memoizedData.map(props => (
          <RenderRow
            {...props}
            onToggle={onToggleOpenMore}
            isShowMoreOpen={props.id === openId}
            onClick={onClick(props.id)}
            key={props.id}
            isOpen={isOpen}
            isActive={isAppActive(props.id)}
            searchQuery={searchQuery}
          />
        ))}
        {extracount && !isOpen && <div css={styles.extraCount}>+{extracount}</div>}
      </div>
    </>
  );
};

interface RenderRowProps extends INavigationItem {
  isActive?: boolean;
  isOpen?: boolean;
  isShowMoreOpen: boolean;
  onToggle: (id: string) => void;
  searchQuery: string;
  onClick: (payload?) => void;
}

const RenderRow = memo(function RenderRow(props: RenderRowProps) {
  const { pathname, search } = useLocation();
  const { onDispatchFormModal } = useCyActions();
  const { id, recordTitle, recordColor, recordImage, isActive, isOpen, onClick, collections, views, flows, isShowMoreOpen, onToggle, searchQuery } = props;

  const containChildren = [...collections, ...views].length > 0;

  const showMore = (isShowMoreOpen || !!searchQuery) && isOpen;

  const activeId = new URLSearchParams(search).get('collectionId') || pathname.replace('/', '');

  const { getNavigateTo } = useGetNavigateTo();
  const onNavigateToView = viewId => getNavigateTo({ path: viewId });
  const onNavigateToCollection = collectionId => getNavigateTo({ path: 'TABLE', qs: { collectionId } });

  const displayItems = useMemo(() => {
    const filteredViews = views.filter(({ recordTitle }) => recordTitle.toLowerCase().includes(searchQuery.toLowerCase()));
    const filteredCollections = collections.filter(({ recordTitle }) => recordTitle.toLowerCase().includes(searchQuery.toLowerCase()));

    const displayViews = filteredViews.map(item => ({ title: item.recordTitle, id: item.id, onClick: onNavigateToView(item.id), icon: item.recordImage }));
    const displayCollections = filteredCollections.map(item => ({
      title: item.recordTitle,
      id: item.id,
      onClick: onNavigateToCollection(item.id),
      icon: item.recordImage
    }));

    return [...displayViews, ...displayCollections].sort((a, b) => a.title.localeCompare(b.title));
  }, [searchQuery, collections, views, onNavigateToCollection, onNavigateToView]);

  const onFlowClick = useCallback(
    (flowId: string, event: unknown) => {
      onDispatchFormModal({ flowId, type: ApiModel.ApiEntity.FLOW, formType: ViewModel.CyFormType.STEPPER }, event);
    },
    [onDispatchFormModal]
  );

  const isAppActive = useCallback((itemId: string) => activeId === itemId, [activeId]);

  const optionList = useMemo(
    () =>
      flows.map(flow => ({
        label: flow.recordTitle,
        image: flow.recordImage,
        color: flow.recordColor,
        onClick: (event: unknown) => onFlowClick(flow.id, event)
      })),
    [flows, onFlowClick]
  );

  return (
    <>
      <ItemMenuPopover
        content={
          <SideContent
            title={recordTitle}
            icon={recordImage}
            color={recordColor}
            isActive={isActive}
            isItemActive={isAppActive}
            items={displayItems}
            onClick={onClick}
          />
        }
        disabled={isOpen}
      >
        <ItemMenu
          id={id}
          isActive={isActive}
          isOpen={isOpen}
          onToggle={onToggle}
          onClick={onClick}
          isShowMore={isShowMoreOpen}
          containChildren={containChildren}
          recordTitle={recordTitle}
          recordImage={recordImage}
          optionList={optionList}
          recordColor={recordColor}
          searchQuery={searchQuery}
        />
      </ItemMenuPopover>
      <div css={showMore ? navigationStyles.show : navigationStyles.hide}>
        <SubItemMenu items={displayItems} isItemActive={isAppActive} searchQuery={searchQuery} />
      </div>
    </>
  );
});
