import React, { memo, useCallback, useContext, useState } from 'react';

import type { ApiModel, ParsedListDefinition, ParsedListItem } from '@cyferd/client-engine';
import { ErrorBoundary, GeneralModel, ViewModel, actionHooks, getAddressValue, isObject, noop, swallowError } from '@cyferd/client-engine';

import { CyWrapperContext } from '../../smart/CyWrapper';
import { COLOR, FONT_SIZE, MAPS_URL } from '@constants';
import { getFileIconProps, getLabel, isIcon } from '@utils';
import { getColorValue } from '@utils/getColorValue';
import { CTA } from '../CTA';
import { FileViewerModal } from '../FileViewerModal';
import { ImageWithPopover } from '../ImageWithPopover';
import { TableCell, tableCellNeedsColor } from '../Table/TableCell';
import { ToolTip } from '../Tooltip';
import { styles } from './styles';
import { PreventClickPropagation } from '../PreventClickPropagation';
import { IconImage } from '../Icon/renderIcon';
import { Modal } from '../Modal';
import { OptionMenu } from '../OptionMenu';
import { RichText } from '../RichTextEditor';
import { ReadonlyAssociation } from '../ReadonlyAssociation';
import { JSONSyntaxEditor } from '../JSONSyntaxEditor';
import { SyntaxEditor } from '../SyntaxEditor';
import { MultiFileInputContext } from '../MultiFileInput/MultiFileInputContext';
import { PopoverTrigger } from '../Popover';
import { FormulaPreviewContent } from '../Evaluator/FormulaPreview';

const allNumberFormats = Array.from(
  new Set([
    GeneralModel.JSONSchemaFormat.NUMBER,
    ...Object.entries(GeneralModel.formatToTypeMap)
      .filter(([_, type]) => type === 'number')
      .map(([format]) => format as GeneralModel.JSONSchemaFormat)
  ])
);

export interface ReadonlyFormatProps {
  item: ParsedListItem;
  definition: ParsedListDefinition;
  fullRecord?: ApiModel.ApiRecord;
  collectionId?: string;
  recordId?: string;
  disabled?: boolean;
}

// TODO: this would be much leaner and usable if we applied component composition with a render prop instead of a huge switch statement
export const ReadonlyFormat = memo(({ item, fullRecord, definition, collectionId, recordId, disabled }: ReadonlyFormatProps) => {
  const { useOnOpenEmailClient, useOnOpenExternalUrl, useAction } = useContext(CyWrapperContext);
  const onOpenEmailClient = useOnOpenEmailClient();
  const onOpenUrl = useOnOpenExternalUrl();
  const [showModal, setShowModal] = useState<boolean>(false);
  const onToggleModal = useCallback(() => setShowModal(p => !p), []);
  const format = definition.property.format;
  const specialArrayFormats = [GeneralModel.JSONSchemaFormat.NUMERIC_RANGE, GeneralModel.JSONSchemaFormat.ASSOCIATION];

  const onNavigateTo = useAction('dispatchNavigateTo');
  const navigateToDetail = (recordId: string, collectionId: string) => onNavigateTo({ path: 'DETAIL', qs: { id: recordId, collectionId }, openInNewTab: true });

  const dispatchDownloadFile = actionHooks.useOnDownloadFile();
  const onDownload = (fileId: string) => {
    dispatchDownloadFile({ id: fileId }).pipe(swallowError()).subscribe();
  };

  if (Array.isArray(item.value) && !specialArrayFormats.includes(format)) {
    return (
      <div css={styles.arrayContainer} data-testid="array-container">
        {item.value.map((_, i) => {
          const singleItem = {
            ...item,
            color: item.color?.[i],
            calculatedValue: item.calculatedValue?.[i],
            rawValue: item.rawValue?.[i],
            secondaryColor: item.secondaryColor?.[i],
            icon: item.icon?.[i],
            value: item.value[i]
          } as ReadonlyFormatProps['item'];

          const isFile = [GeneralModel.JSONSchemaFormat.FILE_LIST, GeneralModel.JSONSchemaFormat.FILE].includes(definition.rawProperty.format);
          const renderReadOnlyInput = () => (
            <ReadonlyFormat
              fullRecord={fullRecord}
              item={singleItem}
              definition={definition}
              collectionId={collectionId}
              recordId={recordId}
              disabled={disabled}
            />
          );
          return (
            <TableCell
              key={i}
              id={String(i)}
              item={
                isFile ? (
                  <MultiFileInputContext.Provider value={{ indexedValues: item.value }}>{renderReadOnlyInput()}</MultiFileInputContext.Provider>
                ) : (
                  renderReadOnlyInput()
                )
              }
              color={tableCellNeedsColor(item.value) ? (item.color?.[i] as GeneralModel.Color.ThemeColor) : /* istanbul ignore next */ undefined}
              format={definition.property?.format}
            />
          );
        })}
      </div>
    );
  }

  return (
    <>
      {(() => {
        switch (format) {
          case GeneralModel.JSONSchemaFormat.RATING:
            if (typeof item.value !== 'number') return null;
            return (
              <div css={[styles.contentItem, styles.ratingContainer]} data-testid="rating-container">
                <div>
                  <IconImage
                    title=""
                    iconProps={{ size: FONT_SIZE.L, fill: COLOR[item.color] || COLOR.OE_6, css: styles.ratingIcon }}
                    icon={item.icon || /* istanbul ignore next */ 'star'}
                  />
                </div>
                <span css={styles.ratingText}>
                  {Number(item.value.toFixed(2))} / <small>{definition.property.maximum}</small>
                </span>
              </div>
            );
          case GeneralModel.JSONSchemaFormat.NUMERIC_RANGE:
            if (!Array.isArray(item.calculatedValue)) return null;
            return (
              <div css={styles.contentItem} data-testid="numeric-range-container">
                {definition.applyMask(item.calculatedValue[0])} to {definition.applyMask(item.calculatedValue[1])}
              </div>
            );
          case GeneralModel.JSONSchemaFormat.CHECKBOX:
          case GeneralModel.JSONSchemaFormat.SWITCH:
            const labelTrue = definition?.property?.metadata?.booleanLabelTrue || '✓';
            const labelFalse = definition?.property?.metadata?.booleanLabelFalse || '✕';

            return (
              <div css={[styles.contentItem, styles.booleanFormat]} data-testid="boolean-container">
                {[undefined, null].includes(item.value) ? '' : item.value ? labelTrue : labelFalse}
              </div>
            );
          case GeneralModel.JSONSchemaFormat.EMAIL:
            if ([null, undefined].includes(item.value)) return null;
            return (
              <div css={styles.ctaContainer} data-testid="email-container">
                <PreventClickPropagation>
                  <CTA disabled={disabled} type={ViewModel.CTAType.LINK} label={item.value} onClick={() => onOpenEmailClient(item.value)} />
                </PreventClickPropagation>
              </div>
            );
          case GeneralModel.JSONSchemaFormat.URL:
            if ([null, undefined].includes(item.value)) return null;
            return (
              <div css={styles.ctaContainer} data-testid="url-container">
                <PreventClickPropagation>
                  <CTA disabled={disabled} type={ViewModel.CTAType.LINK} label={item.value} onClick={() => onOpenUrl(item.value)} />
                </PreventClickPropagation>
              </div>
            );
          case GeneralModel.JSONSchemaFormat.PHONE_NUMBER:
            if ([null, undefined].includes(item.value)) return null;
            return (
              <div css={styles.ctaContainer} data-testid="phone-number-container">
                <PreventClickPropagation>
                  <CTA disabled={disabled} type={ViewModel.CTAType.LINK} label={item.value} onClick={() => onOpenUrl(`tel:${item.value}`)} />
                </PreventClickPropagation>
              </div>
            );
          case GeneralModel.JSONSchemaFormat.ICON:
          case GeneralModel.JSONSchemaFormat.ICON_IMAGE:
            if (!item.value) return null;
            if (isIcon(item.value)) {
              return (
                <div css={styles.icon} data-testid="icon-container">
                  <div>
                    <PopoverTrigger value={<IconImage title={item.value} iconProps={{ size: FONT_SIZE.L, fill: COLOR.NEUTRAL_1 }} icon={item.value} />}>
                      <IconImage title={item.value} iconProps={{ size: FONT_SIZE.L, fill: COLOR.NEUTRAL_1 }} icon={item.value} />
                    </PopoverTrigger>
                  </div>
                </div>
              );
            }
            return (
              <div css={styles.icon} data-testid="icon-container">
                <PreventClickPropagation>
                  <ImageWithPopover alt={item.value} src={item.value} />
                </PreventClickPropagation>
              </div>
            );
          case GeneralModel.JSONSchemaFormat.COLOR:
            if (!item.value) return null;
            return (
              <div css={styles.colorContainer}>
                <div css={styles.colorItem} style={{ backgroundColor: getColorValue(item.value) }} data-testid="color-container" />
              </div>
            );
          case GeneralModel.JSONSchemaFormat.EVALUATION:
            if ([null, undefined].includes(item.value)) return null;
            return (
              <div css={styles.evaluation} data-testid="evaluation-container">
                <FormulaPreviewContent formula={item.value} inputList={definition.property?.metadata?.inputList} avoidOpenOnClick={false} />
              </div>
            );
          case GeneralModel.JSONSchemaFormat.RICH_TEXT:
            if (!item.value || typeof item.value !== 'string') return null;
            return (
              <PreventClickPropagation>
                <div data-testid="rich-text-container">
                  <CTA
                    disabled={disabled}
                    icon="font_download"
                    type={ViewModel.CTAType.TERTIARY}
                    color={item.color}
                    size={ViewModel.CTASize.SMALL}
                    label="Read text"
                    onClick={onToggleModal}
                  />
                </div>
                {!!showModal && (
                  <Modal
                    title={getLabel(definition.displayNamePath)}
                    description={definition.property.description}
                    icon={item.icon}
                    open={true}
                    type={ViewModel.ModalType.FULL_SCREEN}
                    onClose={onToggleModal}
                    footer={<CTA type={ViewModel.CTAType.PRIMARY} label="OK" onClick={onToggleModal} />}
                  >
                    <ErrorBoundary>
                      <RichText value={item.value} />
                    </ErrorBoundary>
                  </Modal>
                )}
              </PreventClickPropagation>
            );
          case GeneralModel.JSONSchemaFormat.ADDRESS:
            const address = getAddressValue(item.value);
            const addressLabel = address.summary || address.additionalInfo;
            if ([null, undefined].includes(addressLabel)) return null;
            if (!address.lat || !address.lng) {
              return (
                <ToolTip text={address.summary}>
                  <div data-testid="address-disabled-container" css={styles.contentItem}>
                    {addressLabel}
                  </div>
                </ToolTip>
              );
            }
            return (
              <div css={styles.ctaContainer} data-testid="address-container">
                <PreventClickPropagation>
                  <CTA
                    type={ViewModel.CTAType.LINK}
                    disabled={disabled || !address.lat || !address.lng}
                    label={addressLabel}
                    onClick={() => onOpenUrl(`${MAPS_URL}${address.lat},${address.lng}`)}
                  />
                </PreventClickPropagation>
              </div>
            );
          case GeneralModel.JSONSchemaFormat.FILE:
            if (!isObject(item.value)) return null;

            const fileValue = item.value as GeneralModel.FileValue;
            return (
              <>
                <PreventClickPropagation>
                  <div data-testid="file-container">
                    <CTA
                      disabled={disabled}
                      icon={getFileIconProps(fileValue?.mimeType).name as any}
                      type={ViewModel.CTAType.TERTIARY}
                      size={ViewModel.CTASize.SMALL}
                      label={fileValue.name}
                      onClick={onToggleModal}
                    />
                  </div>
                  {!!showModal && (
                    <FileViewerModal title={getLabel(definition.displayNamePath)} value={fileValue} onClose={onToggleModal} onDownload={onDownload} />
                  )}
                </PreventClickPropagation>
              </>
            );
          case GeneralModel.JSONSchemaFormat.COLLECTION_FILTER:
          case GeneralModel.JSONSchemaFormat.JSON:
          case GeneralModel.JSONSchemaFormat.SQL:
          case GeneralModel.JSONSchemaFormat.GRAPHQL:
          case GeneralModel.JSONSchemaFormat.XML:
          case GeneralModel.JSONSchemaFormat.YAML:
            if (!item.value) return null;
            return (
              <div css={styles.contentItem}>
                <PreventClickPropagation>
                  <CTA
                    disabled={disabled}
                    icon="code"
                    type={ViewModel.CTAType.TERTIARY}
                    color={item.color}
                    size={ViewModel.CTASize.SMALL}
                    label={format.toUpperCase()}
                    onClick={onToggleModal}
                  />
                  {!!showModal && (
                    <Modal
                      open={true}
                      title={getLabel(definition.displayNamePath)}
                      onClose={onToggleModal}
                      type={ViewModel.ModalType.FULL_SCREEN}
                      footer={
                        <OptionMenu optionList={[{ important: true, testid: 'close', type: ViewModel.CTAType.PRIMARY, label: 'OK', onClick: onToggleModal }]} />
                      }
                    >
                      {[GeneralModel.JSONSchemaFormat.JSON, GeneralModel.JSONSchemaFormat.COLLECTION_FILTER].includes(format) ? (
                        <JSONSyntaxEditor value={item.value} disabled={true} onChange={noop} expanded={true} avoidExpandOption={true} label="" height={500} />
                      ) : (
                        <SyntaxEditor value={item.value} disabled={true} onChange={noop} expanded={true} label="" language={format} height={500} />
                      )}
                    </Modal>
                  )}
                </PreventClickPropagation>
              </div>
            );
          case GeneralModel.JSONSchemaFormat.ASSOCIATION:
            const totalCount = fullRecord?.[`$$${definition.id}`]?.totalCount;
            if (totalCount === 0) return null;
            const isDropdown = definition?.property?.metadata?.subtype === GeneralModel.JSONSchemaSubtype.ASSOCIATION_DROPDOWN;
            const isCount = definition?.property?.metadata?.subtype === GeneralModel.JSONSchemaSubtype.ASSOCIATION_KPI;
            const isPreventLoad = !!definition?.property?.metadata?.association?.preventLoad;
            const assFirstValue = fullRecord?.[definition.id]?.[0];

            return (
              <div data-testid="association-container">
                <PreventClickPropagation>
                  {(() => {
                    if (isDropdown && !isPreventLoad && assFirstValue) {
                      return (
                        <div css={styles.ctaContainer}>
                          <div css={styles.associationDropdownContainer}>
                            {!!assFirstValue.recordImage && (
                              <div css={styles.associationIconContainer}>
                                <IconImage icon={assFirstValue.recordImage as any} title={assFirstValue.recordImage} />
                              </div>
                            )}
                            <CTA
                              disabled={disabled}
                              type={ViewModel.CTAType.LINK}
                              label={assFirstValue.recordTitle}
                              onClick={() => navigateToDetail(assFirstValue.id, assFirstValue.collectionId)}
                            />
                          </div>
                        </div>
                      );
                    }
                    return (
                      <CTA
                        disabled={disabled}
                        icon={isCount ? undefined : 'format_list_bulleted'}
                        type={ViewModel.CTAType.TERTIARY}
                        color={item.color}
                        size={ViewModel.CTASize.SMALL}
                        label={(() => {
                          if (isCount) return !isNaN(totalCount) ? Number(totalCount).toLocaleString() : 'View quantity';
                          if (!!isObject(assFirstValue)) return [assFirstValue.recordTitle, totalCount > 1 && `(${totalCount})`].filter(Boolean).join(' ');
                          return 'View list';
                        })()}
                        onClick={onToggleModal}
                      />
                    );
                  })()}
                  {!!showModal && (
                    <ReadonlyAssociation
                      title={[getLabel(definition.displayNamePath), !!totalCount && `(${totalCount})`].filter(Boolean).join(' ')}
                      apiQuery={{ cursor: { collectionId, id: recordId }, schema: null }}
                      schema={definition.rawProperty}
                      onClose={onToggleModal}
                    />
                  )}
                </PreventClickPropagation>
              </div>
            );
          default:
            const content = [null, undefined].includes(item.value) ? '' : String(item.value);
            const isNumericContent = allNumberFormats.includes(format);
            return content ? (
              <ToolTip text={!isNumericContent && content}>
                <div css={[styles.contentItem, isNumericContent && styles.numericFormat]} data-selector="cell-content">
                  {content}
                </div>
              </ToolTip>
            ) : (
              <div css={styles.contentItem}>{content}</div>
            );
        }
      })()}
    </>
  );
});

ReadonlyFormat.displayName = 'ReadonlyFormat';
