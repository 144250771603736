import type { SearchProps } from '@components/elements/Search';
import { APP_CONTAINER_ID } from '@constants';
import { ClientEngineContext, useUnmountObservable } from '@cyferd/client-engine';
import type { Storage } from '@utils';
import { useGetElementSize } from '@utils';
import type { PropsWithChildren } from 'react';
import { useCallback, useContext } from 'react';
import { BannerNewLayout } from '../BannerNewLayout';
import { Header } from './components/Header';
import { useGlobalHeaderFetcher } from '../GlobalHeader/hooks/useGlobalHeaderFetcher';
import useBannerData from './hooks/useBannerData';
import { styles } from './styles';
import { NavigationMenu } from './components/NavigationMenu';
import { NavigationMenuProvider } from './context/NavigationMenuContext';
import { SearchContextProvider } from './context/SearchContext';

export interface IMainLayoutConditional extends PropsWithChildren {
  hidden?: boolean;
  searchMenu?: SearchProps;
  hiddenFavorites?: boolean;
  hiddenSearch?: boolean;
  hiddenShortlink?: boolean;
  hiddenNotifications?: boolean;
  hiddenPreferences?: boolean;
  hiddenPrint?: boolean;
  storage?: Storage;
}

export const MainLayoutConditional = ({ children, hidden, ...rest }: IMainLayoutConditional) => {
  return (
    <NavigationMenuProvider>{hidden ? <LayoutWithoutHeader>{children}</LayoutWithoutHeader> : <Layout {...rest}>{children}</Layout>}</NavigationMenuProvider>
  );
};

const Layout = ({
  children,
  searchMenu,
  hiddenSearch,
  hiddenShortlink,
  hiddenFavorites,
  hiddenNotifications,
  hiddenPreferences,
  hiddenPrint
}: Omit<IMainLayoutConditional, 'hidden'>) => {
  const { useUserSelector } = useContext(ClientEngineContext);
  const user = useUserSelector();

  const onDestroy$ = useUnmountObservable();
  const { isLoading, apps, recentViews } = useGlobalHeaderFetcher(onDestroy$);

  const { bannerData, isBannerTop, containBannerData } = useBannerData();
  const { ref: bannerRef, height: bannerHeight } = useGetElementSize();

  const renderBanner = useCallback(() => {
    return <BannerNewLayout {...bannerData} ref={bannerRef} />;
  }, [bannerData, bannerRef]);

  return (
    <div data-testid="main-layout" css={styles.mainWrapper}>
      <div css={styles.maxContainer}>
        {containBannerData && <div style={{ order: isBannerTop ? -1 : 1 }}>{renderBanner()}</div>}
        <div css={styles.header}>
          <Header
            searchMenu={searchMenu}
            hiddenSearch={hiddenSearch}
            hiddenShortlink={hiddenShortlink}
            hiddenFavorites={hiddenFavorites}
            hiddenNotifications={hiddenNotifications}
            hiddenPreferences={hiddenPreferences}
            hiddenPrint={hiddenPrint}
            bannerHeight={isBannerTop ? 0 : bannerHeight}
          />
        </div>
        <div css={styles.wrapper}>
          <div css={styles.nav}>
            <SearchContextProvider>
              <NavigationMenu isLoading={isLoading} data={apps} user={user} recentViews={recentViews} />
            </SearchContextProvider>
          </div>
          <div css={styles.content} id={APP_CONTAINER_ID}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

const LayoutWithoutHeader = ({ children }: PropsWithChildren) => {
  const { bannerData, isBannerTop, containBannerData } = useBannerData();
  return (
    <div data-testid="main-layout-hidden-header" css={styles.mainWrapper}>
      <div css={styles.maxContainer}>
        {containBannerData && <div style={{ order: isBannerTop ? -1 : 1 }}>{<BannerNewLayout {...bannerData} />}</div>}
        <div css={styles.content} id={APP_CONTAINER_ID}>
          {children}
        </div>
      </div>
    </div>
  );
};
