import { Icon } from '@components/elements/Icon';
import { styles } from './styles';
import { COLOR, GAP, SECONDARY_COLOR } from '@constants';
import type { BlockProperties, InfoBlockType } from './types';
import { LexicalNestedComposer } from '@lexical/react/LexicalNestedComposer';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import type { LexicalEditor, NodeKey } from 'lexical';
import { styleHelpers } from '@utils/styleHelpers';
import { OnChangePlugin } from '@components/elements/RichTextEditor/RichEditorComposition/plugins/OnChangePlugin';
import { $getNodeByKey } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { useSharedHistoryContext } from '@components/elements/RichTextEditor/RichEditorComposition/context';
import * as Popover from '@radix-ui/react-popover';
import { InfoBlockButton } from './components/InfoBlockButton';
import { $isInfoBlockNode } from '../../nodes/InfoBlockNode';

interface InfoBlockProps {
  nodeKey: NodeKey;
  type: InfoBlockType;
  editor: LexicalEditor;
}

export const InfoBlock = ({ nodeKey, type, editor }: InfoBlockProps) => {
  const [parentEditor] = useLexicalComposerContext();
  const { historyState } = useSharedHistoryContext();

  const isEditable = parentEditor.isEditable();

  const getBlockProperties = (): BlockProperties => {
    switch (type) {
      case 'info':
        return {
          icon: 'info',
          bgColor: SECONDARY_COLOR.BE_2,
          highlightColor: COLOR.BE_2
        };
      case 'warning':
        return {
          icon: 'warning',
          bgColor: SECONDARY_COLOR.OE_3,
          highlightColor: COLOR.OE_3
        };
      case 'alert':
        return {
          icon: 'error',
          bgColor: SECONDARY_COLOR.RD_3,
          highlightColor: COLOR.RD_3
        };
      case 'success':
        return {
          icon: 'check_circle',
          bgColor: SECONDARY_COLOR.GN_3,
          highlightColor: COLOR.GN_3
        };
    }
  };

  const blockProperties = getBlockProperties();

  const handleDelete = () => {
    parentEditor.update(() => {
      const node = $getNodeByKey(nodeKey);

      if ($isInfoBlockNode(node)) {
        node.remove();
      }
    });
  };

  const handleTypeChange = (type: InfoBlockType) => {
    parentEditor.update(() => {
      const node = $getNodeByKey(nodeKey);

      if ($isInfoBlockNode(node)) {
        node.setType(type);
      }
    });
  };

  // TODO: investigate and understand this mystique
  const handleCaptionChange = () => {
    parentEditor.update(() => {
      const node = $getNodeByKey(nodeKey);

      if ($isInfoBlockNode(node)) {
        node.getWritable();
      }
    });
  };

  return (
    <Popover.Root>
      <Popover.Trigger asChild={true}>
        <div css={styles.wrapper(blockProperties.bgColor)}>
          <div css={[styleHelpers.flexStart(GAP.XS), styleHelpers.flex1]}>
            <div css={styles.divider(blockProperties.highlightColor)} />

            <Icon name={blockProperties.icon} size="13px" fill={blockProperties.highlightColor} outlined={true} />

            <LexicalNestedComposer initialEditor={editor}>
              <PlainTextPlugin placeholder={<></>} contentEditable={<ContentEditable style={{ width: '100%' }} />} ErrorBoundary={LexicalErrorBoundary} />
              <HistoryPlugin externalHistoryState={historyState} />
              <OnChangePlugin value={''} onChange={handleCaptionChange} returnType="text" />
            </LexicalNestedComposer>
          </div>
        </div>
      </Popover.Trigger>

      {isEditable && (
        <Popover.Content
          side="bottom"
          align="start"
          alignOffset={ALIGN_OFFSET}
          sideOffset={SIDE_OFFSET}
          onOpenAutoFocus={e => e.preventDefault()}
          onCloseAutoFocus={e => e.preventDefault()}
          style={{ zIndex: 10 }}
        >
          <div css={[styles.floatingMenu, styleHelpers.flexBetween]}>
            <InfoBlockButton icon={{ name: 'info', size: '21px', fill: COLOR.BE_2 }} onClick={() => handleTypeChange('info')} />
            <InfoBlockButton icon={{ name: 'check_circle', size: '21px', fill: COLOR.GN_3 }} onClick={() => handleTypeChange('success')} />
            <InfoBlockButton icon={{ name: 'warning', size: '21px', fill: COLOR.OE_3 }} onClick={() => handleTypeChange('warning')} />
            <InfoBlockButton icon={{ name: 'error', size: '21px', fill: COLOR.RD_3 }} onClick={() => handleTypeChange('alert')} />
            <InfoBlockButton icon={{ name: 'delete', size: '21px', fill: COLOR.NEUTRAL_1 }} onClick={handleDelete} />
          </div>
        </Popover.Content>
      )}
    </Popover.Root>
  );
};

const ALIGN_OFFSET = 0;
const SIDE_OFFSET = 0;
