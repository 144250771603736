import { styles } from '../styles';
import Skeleton from '@components/elements/Skeleton/Skeleton';

export const NavigationSkeletonNewLayout = () => (
  <div data-testid="navigation-skeleton" css={[styles.menu, styles.collapsedMenu]}>
    <div css={styles.loadinSkeleton}>
      <Skeleton.Avatar size={32} />
      <Skeleton.Content round={true} />
      <Skeleton.Content delay={0.3} round={true} />
      <Skeleton.Content delay={0.5} round={true} />
      <Skeleton.Content round={true} />
      <Skeleton.Content delay={0.3} round={true} />
    </div>
  </div>
);
