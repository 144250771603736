import { COLOR, GAP, INPUT_HEIGHT, RADIUS, SECONDARY_COLOR, TRANSITION_SPEED } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  table: css`
    border-collapse: separate;
    width: 100%;
  `,
  row: css`
    transition: background-color ${TRANSITION_SPEED};
    &:hover {
      background-color: ${SECONDARY_COLOR.BRAND_1};
    }
  `,
  cellTd: css`
    position: relative;
    height: 1px;
    overflow: hidden;
    cursor: pointer;
    box-sizing: content-box;
    border: 1px solid transparent;
    border-right-color: ${COLOR.NEUTRAL_4};
    border-bottom-color: ${COLOR.NEUTRAL_4};
    border-spacing: 0;
    vertical-align: middle;
    &:focus {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border: ${GAP.XXS} solid ${COLOR.BRAND_1};
        border-radius: ${GAP.XS};
        z-index: 2;
        pointer-events: none;
      }
    }
    [data-selector='dropdown-menu-container'] {
      width: 100%;
    }
  `,
  innerCell: css`
    height: ${INPUT_HEIGHT};
    min-width: calc(${GAP.XXL} * 2.5);
    max-width: 400px;
    overflow: hidden;
    display: inline-flex;
    user-select: none;
  `,
  cellContentWrapper: css`
    width: 100%;
  `,
  nonEditableTd: css`
    cursor: not-allowed;
    background-color: ${COLOR.NEUTRAL_5};
  `,
  editedTd: css`
    background-color: ${SECONDARY_COLOR.GN_5};
    &:before {
      content: '';
      position: absolute;
      top: 6px;
      right: 6px;
      left: auto;
      border-radius: 50%;
      width: 6px;
      height: 6px;
      background-color: ${COLOR.GN_5};
      z-index: 1;
    }
  `,
  selectedCell: css`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: ${GAP.XXS} solid ${COLOR.BRAND_1};
    border-radius: ${GAP.XS};
    z-index: 1;
    pointer-events: none;
  `,
  erroredCell: css`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: ${GAP.XXS} solid ${COLOR.RD_1};
    border-radius: ${GAP.XS};
    z-index: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: ${GAP.S};
  `,
  hidden: css`
    visibility: hidden;
  `,
  inputContainer: css`
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  `,
  recordColor: (color: string) => css`
    position: sticky;
    left: 0;
    width: ${GAP.XS};
    border-radius: ${RADIUS.S};
    background-color: ${color || COLOR.BASE_BACKGROUND_ALT_1};
  `,
  inSelection: css`
    border: 1px solid ${COLOR.BRAND_1};
  `,
  menuTrigger: css`
    position: relative;
  `,
  menuAnchor: css`
    position: absolute;
    visibility: hidden;
    width: 30px;
    height: 100%;
    right: 0;
    bottom: 0px;
  `
};
