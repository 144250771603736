import type { PropsWithChildren } from 'react';
import { memo, useContext, useMemo } from 'react';
import type { ViewModel } from '@cyferd/client-engine';
import { ClientEngineContext, useParsers } from '@cyferd/client-engine';
import { Global } from '@emotion/react';

import { HasFlagRenderer } from '@components/elements/HasFlagRenderer';
import { styles } from './styles';
import { FLAGS } from '@constants';
import { MainLayoutConditional } from '@components/platform/MainLayout';
import { GlobalHeader } from '@components/platform/GlobalHeader';

export const AppWrapper = memo(({ children }: PropsWithChildren) => {
  const { useViewSelector } = useContext(ClientEngineContext);
  const view = useViewSelector();
  const { parseData } = useParsers(view?.globalHeader);

  const globalHeaderOptions: ViewModel.View['globalHeader'] = useMemo(() => {
    const result: Record<string, boolean> = {};
    const entries = Object.entries(view?.globalHeader || {});

    for (const [k, v] of entries) {
      result[k] = !!parseData(v);
    }

    return result;
  }, [parseData, view?.globalHeader]);

  return (
    <>
      {!!globalHeaderOptions?.hiddenBg && <Global styles={styles.globalBg} />}
      <HasFlagRenderer flag={FLAGS.WIP} NewComponent={MainLayoutConditional} LegacyComponent={GlobalHeader} {...globalHeaderOptions}>
        {children}
      </HasFlagRenderer>
    </>
  );
});
