import type { ComponentProps, PropsWithChildren, ReactNode } from 'react';
import React, { memo, useCallback, useMemo } from 'react';
import { Observable } from 'rxjs';

import type { ApiModel, GeneralModel } from '@cyferd/client-engine';
import { Translate, ViewModel, isObject, removeKeyList, swallowError, useFinalizeWhileMounted, useTranslate } from '@cyferd/client-engine';

import type { IconKeys, IconProps } from '@components/elements/Icon';
import { Icon } from '@components/elements/Icon';
import { COLOR, GAP } from '@constants';
import { Spinner } from '../Spinner';
import { ToolTip } from '../Tooltip';
import { COLOR_CONFIGURATIONS, allEmotionClasses, getClassName, getColorFromStatus, getIconSize, getStyles } from './styles';
import type { ICTAType } from './types';
import { CTAType } from './types';

export type CtaProps = {
  label?: string;
  icon?: IconKeys | ({ position?: 'start' | 'end' } & IconProps);
  children?: ReactNode;
  type?: ICTAType;
  onClick?: (event: any, event_2?: any) => void | Observable<ApiModel.APIAction>;
  disabled?: boolean;
  testid?: string;
  width?: 'auto' | 'full';
  tooltip?: string;
  status?: ViewModel.Status;
  allowAutofocus?: boolean;
  size?: ViewModel.CTASize;
  color?: GeneralModel.Color.ThemeColor;
  outlined?: boolean;
  keepNativeEvent?: boolean;
  hideLoading?: boolean;
  preventDefault?: boolean;
};

const CtaWrapper = memo(({ children, shouldWrap, tooltip }: PropsWithChildren<{ shouldWrap: boolean; tooltip: ComponentProps<typeof ToolTip>['text'] }>) => (
  <>{!shouldWrap ? children : <ToolTip text={tooltip}>{children}</ToolTip>}</>
));

CtaWrapper.displayName = 'CtaWrapper';

export const CTA = memo(
  ({
    width = 'auto',
    type = CTAType.PRIMARY,
    onClick,
    size = ViewModel.CTASize.MEDIUM,
    testid = 'cta',
    status,
    icon,
    disabled,
    label,
    tooltip,
    allowAutofocus,
    children,
    color,
    outlined,
    keepNativeEvent,
    hideLoading,
    preventDefault
  }: CtaProps) => {
    const { translate } = useTranslate();
    const safeColor = COLOR[color] ? color : undefined;
    const [internalIsLoading, setIsLoading] = React.useState<boolean>(false);
    const isLoading = !hideLoading && internalIsLoading;
    const asActionButton = [CTAType.ACTION, CTAType.ACTION_SECONDARY, CTAType.ACTION_TERTIARY].includes(type as any);
    const asSeemlessButton = type === CTAType.SEEMLESS;

    const styles = useMemo(() => getStyles(type, size), [type, size]);
    const classname = useMemo(() => getClassName(type, status, safeColor), [type, status, safeColor]);
    const styleColor = allEmotionClasses[classname];

    const safeIcon = useMemo(() => {
      const iconName = !icon && asActionButton ? 'trip_origin' : icon;
      const iconColor = disabled ? COLOR_CONFIGURATIONS.BRAND_1[type]?.disabledText : COLOR_CONFIGURATIONS[safeColor || getColorFromStatus(status)][type]?.text;
      if (typeof iconName === 'string') return <Icon name={iconName} outlined={outlined} size={getIconSize(type)} fill={iconColor} />;
      if (isObject(icon)) {
        return (
          <Icon
            name={(icon as IconProps).name}
            size={(icon as IconProps).size || getIconSize(type)}
            fill={(icon as IconProps).fill || iconColor}
            outlined={outlined}
          />
        );
      }
    }, [asActionButton, safeColor, disabled, icon, outlined, status, type]);

    const completeTooltip = useMemo(() => {
      const list = !!asActionButton && Array.from(new Set([label, tooltip])).filter(Boolean);
      if (list?.length) {
        return (
          <p>
            {list.map((t, i) => (
              <React.Fragment key={i}>
                {!!i && <span css={styles.br} />}
                <Translate>{t}</Translate>
              </React.Fragment>
            ))}
          </p>
        );
      }
      return !!tooltip && <Translate>{tooltip}</Translate>;
    }, [asActionButton, label, styles.br, tooltip]);

    const finalize = useFinalizeWhileMounted();

    const onInternalClick = useCallback(
      (event: React.MouseEvent<HTMLElement>): void => {
        if (typeof onClick !== 'function') return;
        const safeEvent = keepNativeEvent
          ? event
          : removeKeyList(event, [
              '_reactName',
              '_targetInst',
              'nativeEvent',
              'target',
              'currentTarget',
              'view',
              'getModifierState',
              'isDefaultPrevented',
              'isPropagationStopped'
            ]);
        const result$ = onClick(
          safeEvent,
          safeEvent /** sending it twice to avoid remapping second one in cases the first one is empty (e.g. header action children) */
        );
        if (!(result$ instanceof Observable)) return;
        setIsLoading(true);
        result$
          .pipe(
            finalize(() => setIsLoading(false)),
            swallowError()
          )
          .subscribe();
      },
      [onClick, keepNativeEvent, finalize]
    );

    return (
      <CtaWrapper shouldWrap={!!completeTooltip} tooltip={completeTooltip}>
        <button
          type="button"
          css={[
            !asSeemlessButton && styles.base,
            !asSeemlessButton && styleColor,
            type === CTAType.LINK && styles.link,
            type === CTAType.PRIMARY && styles.buttonPrimary,
            type === CTAType.SECONDARY && styles.buttonSecondary,
            type === CTAType.TERTIARY && styles.buttonTertiary,
            asActionButton && styles.actionButton,
            asSeemlessButton && styles.seemlessButton,
            width === 'full' && styles.fullWidth,
            !asSeemlessButton && typeof icon === 'object' && icon?.position === 'end' && styles.positionEnd
          ]}
          onMouseDown={e => {
            preventDefault && e.preventDefault();
            e.stopPropagation();
          }}
          onClick={onInternalClick}
          disabled={disabled || isLoading}
          aria-label={translate(label || tooltip || testid)}
          data-testid={testid}
          data-renderedtype={type}
          data-focusable={!disabled && allowAutofocus}
        >
          {safeIcon}
          {!!label && !asActionButton && (
            <div data-selector="cta-label" css={styles.label}>
              <Translate>{label}</Translate>
            </div>
          )}
          {isLoading && (
            <span className="cta-spinner-container">
              <Spinner size={asActionButton ? GAP.XL : GAP.S} />
            </span>
          )}
          {children}
        </button>
      </CtaWrapper>
    );
  }
);

CTA.displayName = 'CTA';
