import { CollectionModel } from '@cyferd/client-engine';

export const parseOptionFieldQuickFilter = (
  filter: CollectionModel.QuickFilter
): CollectionModel.QuickFilter & {
  stringByDefault?: string;
  numberByDefault?: number;
  multipleStringByDefault?: string[];
  multipleNumberByDefault?: number[];
} => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { defaultFilter, ...cleanFilter } = filter;
  if (filter.type !== CollectionModel.QuickFilterType.FIELD || !filter.defaultFilter) return cleanFilter;

  const fieldCondition = filter.defaultFilter[filter.fieldId];
  if (!fieldCondition) return cleanFilter;

  const processFilter = (value: any, isMultiple: boolean) => {
    const isNumber = isMultiple ? value.every((item: any) => typeof item === 'number') : typeof value === 'number';
    const isString = isMultiple ? value.every((item: any) => typeof item === 'string') : typeof value === 'string';

    if (!isString && !isNumber) return cleanFilter;

    return {
      ...cleanFilter,
      optionListType: isMultiple ? CollectionModel.QuickFilterOptionListType.MULTIPLE : CollectionModel.QuickFilterOptionListType.ONE,
      ...(!isMultiple && isString ? { stringByDefault: value } : {}),
      ...(!isMultiple && isNumber ? { numberByDefault: value } : {}),
      ...(isMultiple && isString ? { multipleStringByDefault: value } : {}),
      ...(isMultiple && isNumber ? { multipleNumberByDefault: value } : {})
    };
  };

  if ('$eq' in fieldCondition) return processFilter(fieldCondition.$eq, false);

  if ('$in' in fieldCondition && Array.isArray(fieldCondition.$in)) return processFilter(fieldCondition.$in, true);

  return cleanFilter;
};
