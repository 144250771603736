import { CTAType } from '@components/elements/CTA';
import { type IOptionListItem, OptionMenu } from '@components/elements/OptionMenu';
import { PreventClickPropagation } from '@components/elements/PreventClickPropagation';
import { ViewModel } from '@cyferd/client-engine';
import { memo } from 'react';
import { styles } from './styles';
import type { IconKeys } from '@components/elements/Icon';
import { HighlightedParagraph } from '..//HighlightedParagraph';
import { IconImage } from '@components/elements/Icon/renderIcon';

interface ISubItemValue {
  title: string;
  isActive: boolean;
  optionList?: IOptionListItem[];
  onClick: () => void;
  renderFromPortal?: boolean;
  icon?: IconKeys;
  searchQuery?: string;
}

const SubItemValue = memo(function SubItemValue({ onClick, isActive, title, optionList, renderFromPortal, icon, searchQuery }: ISubItemValue) {
  return (
    <li onClick={onClick} css={[styles.item, styles.subtitle, isActive && styles.activeOptionContent]}>
      {icon ? <IconImage icon={icon} iconProps={{ size: '24px', outlined: true }} imageProps={{ size: '24px' }} /> : <div css={styles.noIconPlaceholder} />}
      <HighlightedParagraph text={title} searchQuery={searchQuery} styles={styles.label} />
      {optionList && !renderFromPortal && (
        <div css={styles.actionContainer} data-selector="subItemMenu-actionContainer">
          <PreventClickPropagation>
            <OptionMenu defaultBtnType={CTAType.ACTION_TERTIARY} defaultBtnSize={ViewModel.CTASize.SMALL} optionList={optionList} />
          </PreventClickPropagation>
        </div>
      )}
    </li>
  );
});

const SubItemMenuComponent = ({ items = [], testid = '', isItemActive, searchQuery = '' }) => {
  return (
    <ul data-testid={testid || 'SubItemMenu'} css={styles.container}>
      {items.map(item => (
        <SubItemValue onClick={item.onClick} isActive={isItemActive(item.id)} key={item.id} title={item.title} searchQuery={searchQuery} icon={item.icon} />
      ))}
    </ul>
  );
};

type MemoizedSubItemMenuType = typeof MemoizedSubItemMenu & {
  Value: typeof SubItemValue;
};
const MemoizedSubItemMenu = memo(SubItemMenuComponent);
export const SubItemMenu = MemoizedSubItemMenu as MemoizedSubItemMenuType;

SubItemMenu.Value = SubItemValue;
