import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, RADIUS, SECONDARY_COLOR, SHADOW } from '@constants';
import { css } from '@emotion/react';

export const getStyles = (color: string, buttonColor: string, toastWidth: string) => ({
  container: css`
    cursor: pointer;
    width: ${toastWidth};
    background-color: ${SECONDARY_COLOR[color]};
    border: 1px solid ${COLOR[color]};
    border-radius: ${RADIUS.M};
    overflow: hidden;
    box-shadow: ${SHADOW.S};
    padding: 16px ${GAP.M};
  `,
  content: css`
    display: grid;
    gap: ${GAP.M};
  `,
  header: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${GAP.XS};
  `,
  textContainer: css`
    display: grid;
    gap: ${GAP.M};
  `,
  textIconContainer: css`
    display: flex;
    align-items: center;
    gap: ${GAP.XS};
  `,
  title: css`
    color: ${COLOR.NEUTRAL_1};
    font-size: ${FONT_SIZE.M};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    white-space: pre-line;
  `,
  icon: css`
    display: inline-block;
    line-height: 0;
    align-self: flex-start;
  `,
  message: css`
    color: ${COLOR.NEUTRAL_1};
    font-size: ${FONT_SIZE.S};
    font-weight: ${FONT_WEIGHT.NORMAL};
    white-space: pre-line;
    display: flex;
    flex-direction: column;
    gap: ${GAP.M};
  `,
  cta: css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
  `,
  closeBtn: css`
    align-self: flex-start;
  `
});
