import { useCyActions } from '@utils';
import { useCallback } from 'react';
import { styles } from '../styles';
import { ItemMenuPopover } from '@components/platform/GlobalHeader/components/ItemMenuPopover';
import { ItemMenu } from './ItemMenu';
import { SubItemMenu } from '@components/platform/GlobalHeader/components/SubItemMenu';
import type { NavigationMenuProps } from '@components/platform/GlobalHeader/types';

interface IRecentViews {
  isOpen: boolean;
  onClick: () => void;
  isShowMore: boolean;
  recentViews: NavigationMenuProps['recentViews'];
}

export const RecentViews = ({ recentViews, onClick, isOpen, isShowMore }: IRecentViews) => {
  const showRecentViews = recentViews.length > 0;

  if (!showRecentViews) return null;

  return (
    <>
      <ItemMenuPopover disabled={isOpen} content={<RecentViewsList recentViews={recentViews} />}>
        <ItemMenu
          onClick={onClick}
          optionList={[]}
          containChildren={true}
          isActive={false}
          recordTitle="Recent views"
          isShowMore={isShowMore}
          recordImage="history"
          isOpen={isOpen}
          onToggle={onClick}
          id={''}
        />
      </ItemMenuPopover>
      <div css={isOpen && isShowMore ? styles.show : styles.hide}>
        <RecentViewsList recentViews={recentViews} />
      </div>
    </>
  );
};

const RecentViewsList = ({ recentViews }) => {
  const { onDispatchNavigateTo } = useCyActions();

  const handleClick = useCallback((path: string, qs: unknown) => () => onDispatchNavigateTo({ path, qs }), [onDispatchNavigateTo]);
  return recentViews.map(view => {
    const { navigationInfo, id, viewId, input } = view;
    const title = navigationInfo?.recordTitle || viewId;
    return <SubItemMenu.Value key={id} title={title} isActive={false} onClick={handleClick(viewId, input)} />;
  });
};
