import { useContext, useState } from 'react';
import { styles } from './styles';
import type { GeneralModel, TokenSet } from '@cyferd/client-engine';
import { ClientEngineContext, safeParse, useTranslate } from '@cyferd/client-engine';
import { Avatar } from '../Avatar';
import { CTA, CTAType } from '@components/elements/CTA';
import { CyWrapperContext } from '@components/smart/CyWrapper';
import { tokenSetStorageKey, tokenStorage } from '../../../client-app/state-mgmt/WSProvider';
import { ENV, GAP, THEME, TRANS } from '@constants';
import { GlobalMenuHeader } from '../GlobalMenuHeader';
import { UIContext } from '@components/providers/UIprovider';
import { useChangeLang } from '../../../translations/config';
import { DevicesTable } from '../Devices';
import { NotificationPreferences } from '../NotificationPreferences';
import { styleHelpers } from '@utils/styleHelpers';
import { OnlyLogo } from '../../../assets/OnlyLogo';
import { css } from '@emotion/react';

const themeList = [
  { value: THEME.LIGHT, title: TRANS.client.nav.accountMenu.light, icon: 'light_mode' },
  { value: THEME.DARK, title: TRANS.client.nav.accountMenu.dark, icon: 'dark_mode' },
  { value: THEME.OS, title: TRANS.client.nav.accountMenu.os, icon: 'routine' }
] as { value: THEME; title: string; icon: GeneralModel.IconName }[];

export interface AccountMenuProps {
  onClose: () => void;
}

export const AccountMenu = ({ onClose }: AccountMenuProps) => {
  const { resolvedLanguage } = useTranslate();
  const onChangeLang = useChangeLang();
  const { useUserSelector } = useContext(ClientEngineContext);
  const user = useUserSelector();
  const { useAction } = useContext(CyWrapperContext);
  const onNavigateTo = useAction('dispatchNavigateTo');
  const [tab, setTab] = useState<'terms' | 'theme' | 'settings' | 'language' | 'devices' | 'notifications'>(null);
  const { state, setState } = useContext(UIContext);

  const tenantName = user?.tenant?.name;

  const onChangeTheme = (themeKey: THEME) => {
    setState(prev => ({ ...prev, themeKey }));
  };

  const onBack = () => setTab(null);

  const getNavigateTo = (payload: Parameters<typeof onNavigateTo>[0]) => (event: any) => {
    onClose();
    onNavigateTo(payload, event).subscribe();
  };

  const onSignOut = /* istanbul ignore next */ () => {
    const tokenSet = safeParse<TokenSet>(localStorage.getItem(tokenSetStorageKey));
    tokenStorage.remove();
    window.location.href = `${ENV.LOGOUT_URL}?refresh_token=${tokenSet?.refresh}`;
  };

  return (
    <div data-testid="account-tab" css={styles.tabContainer}>
      {!tab && (
        <div css={styles.container}>
          <header css={styles.header}>
            <div css={styles.avatar}>
              <Avatar size="fill" src={user.recordImage} alt={user.fullName} />
            </div>
            <div css={styles.user}>
              <p css={styles.title}>{user.fullName}</p>
              <p css={styles.subtitle}>{user.email}</p>
            </div>
          </header>
          <div css={[styles.content, styles.menuContent]}>
            <div data-testid="theme-btn" css={styles.row} onClick={() => setTab('theme')}>
              <CTA
                type={CTAType.LINK}
                icon={themeList.find(t => t.value === state.themeKey)?.icon}
                label={TRANS.client.nav.accountMenu.theme}
                color="NEUTRAL_1"
              />
            </div>

            <div css={styles.division} />

            <div data-testid="notifications-btn" css={styles.row} onClick={() => setTab('notifications')}>
              <CTA type={CTAType.LINK} icon="settings" label={TRANS.client.nav.accountMenu.notifPreferences} color="NEUTRAL_1" />
            </div>
            <div data-testid="devices-btn" css={styles.row} onClick={() => setTab('devices')}>
              <CTA type={CTAType.LINK} icon="devices" label={TRANS.client.nav.accountMenu.devices} color="NEUTRAL_1" />
            </div>
            <div data-testid="language-btn" css={styles.row} onClick={() => setTab('language')}>
              <CTA type={CTAType.LINK} icon="translate" label={TRANS.client.nav.accountMenu.lang} color="NEUTRAL_1" />
            </div>

            <div css={styles.division} />

            <div data-testid="terms-btn" css={styles.row} onClick={() => setTab('terms')}>
              <CTA type={CTAType.LINK} icon="article" label={TRANS.client.nav.accountMenu.terms} color="NEUTRAL_1" />
            </div>

            <div css={styles.division} />

            <div data-testid="logout-btn" css={styles.row} onClick={onSignOut}>
              <CTA type={CTAType.LINK} icon="exit_to_app" label={TRANS.client.nav.accountMenu.logout} color="NEUTRAL_1" />
            </div>
          </div>
        </div>
      )}
      {tab === 'devices' && (
        <div css={styles.container} data-testid="devices-tab">
          <GlobalMenuHeaderWrapper>
            <GlobalMenuHeader title={TRANS.client.nav.accountMenu.devices} onBack={onBack} />
          </GlobalMenuHeaderWrapper>
          <div css={[styles.content, styles.deviceContent]}>
            <DevicesTable />
          </div>
        </div>
      )}
      {tab === 'notifications' && (
        <div css={styles.container} data-testid="notifications-tab">
          <GlobalMenuHeaderWrapper>
            <GlobalMenuHeader title={TRANS.client.nav.accountMenu.notifPreferences} onBack={onBack} />
          </GlobalMenuHeaderWrapper>
          <div css={[styles.content, styles.notificationsContent]}>
            <NotificationPreferences />
          </div>
        </div>
      )}
      {tab === 'terms' && (
        <div css={styles.container} data-testid="terms-tab">
          <GlobalMenuHeaderWrapper>
            <GlobalMenuHeader title={TRANS.client.nav.accountMenu.terms} onBack={onBack} />
          </GlobalMenuHeaderWrapper>
          <div css={styles.content}>
            <div css={styles.row} onClick={getNavigateTo({ path: ENV.PRIVACY_POLICY_URL, external: true })}>
              <CTA type={CTAType.LINK} testid="priv" icon="shield_lock" label={TRANS.client.nav.accountMenu.priv} color="NEUTRAL_1" />
            </div>
            <div css={styles.row} onClick={getNavigateTo({ path: ENV.THIRD_PARTY_SOFTWARE_URL, external: true })}>
              <CTA type={CTAType.LINK} testid="third" icon="wysiwyg" label={TRANS.client.nav.accountMenu.third} color="NEUTRAL_1" />
            </div>
          </div>
        </div>
      )}
      {tab === 'language' && (
        <div css={styles.container} data-testid="language-tab">
          <GlobalMenuHeaderWrapper>
            <GlobalMenuHeader title={TRANS.client.nav.accountMenu.lang} onBack={onBack} />
          </GlobalMenuHeaderWrapper>
          <div css={styles.content}>
            {[
              { lang: 'en', label: 'English' },
              { lang: 'es', label: 'Español' }
            ].map(config => (
              <div
                key={config.lang}
                data-testid="change-lang-btn"
                css={[styles.row, resolvedLanguage === config.lang && styles.activeRow]}
                onClick={() => onChangeLang(config.lang)}
              >
                <CTA type={CTAType.LINK} label={config.label} color={resolvedLanguage === config.lang ? 'BASE_BACKGROUND' : 'NEUTRAL_1'} />
              </div>
            ))}
          </div>
        </div>
      )}
      {tab === 'theme' && (
        <div css={styles.container} data-testid="theme-tab">
          <GlobalMenuHeaderWrapper>
            <GlobalMenuHeader title={TRANS.client.nav.accountMenu.theme} onBack={onBack} />
          </GlobalMenuHeaderWrapper>
          <div css={styles.content}>
            {themeList.map(({ value, title, icon }) => {
              const isActive = state.themeKey === value;
              return (
                <div css={[styles.row, isActive && styles.activeRow]} key={value} onClick={() => onChangeTheme(value)} data-testid={value}>
                  <CTA
                    testid={isActive ? 'theme-active' : 'theme-inactive'}
                    type={CTAType.LINK}
                    icon={icon}
                    label={title}
                    color={isActive ? 'BASE_BACKGROUND' : 'NEUTRAL_1'}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}

      <footer css={[styleHelpers.flexCenter, styles.sidebarFooter.footer]}>
        {tenantName && <p css={styles.sidebarFooter.tenant}>{tenantName}</p>}
        <div css={[styleHelpers.flexCenter, styles.sidebarFooter.cyferdContainer]}>
          <p css={styles.sidebarFooter.cyferdText}>Powered by Cyferd</p>
          <OnlyLogo width={16} height={16} />
        </div>
      </footer>
    </div>
  );
};

const GlobalMenuHeaderWrapper = ({ children }) => {
  return <div css={headerPadding}>{children}</div>;
};

const headerPadding = css`
  padding: ${GAP.L} ${GAP.L} 0 ${GAP.L};
`;

AccountMenu.displayName = 'AccountMenu';
