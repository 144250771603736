import { useCallback, useContext, useMemo } from 'react';

import {
  ViewModel,
  getParsedActionChildren,
  swallowError,
  useGeneralFetch,
  useOnEntityChange,
  useOnRefresh,
  useParsedCursors,
  useSearchFetch
} from '@cyferd/client-engine';

import { styles } from './styles';
import { CyWrapperContext } from '../CyWrapper/CyWrapper';
import { BaseChart } from '@components/elements/BaseChart';
import { PieChart } from '@components/elements/PieChart';
import { KPIChart } from '@components/elements/KPIChart';
import { ScatterChart } from '@components/elements/ScatterChart';
import { CTAType } from '@components/elements/CTA';
import { BBContainer } from '@components/elements/BBContainer';
import { PreventClickPropagation } from '@components/elements/PreventClickPropagation';
import { OptionMenu } from '@components/elements/OptionMenu';

const ChartWrapper = ({ children }) => (
  <div css={styles.chartWrapper}>
    <BBContainer framed={true}>{children}</BBContainer>
  </div>
);

const chartComponents = {
  [ViewModel.ChartType.BAR]: BaseChart,
  [ViewModel.ChartType.LINE]: BaseChart,
  [ViewModel.ChartType.SCATTER]: ScatterChart,
  [ViewModel.ChartType.PIE]: PieChart
};

export const CyChart = ({
  id,
  componentName,
  title,
  subtitle,
  color,
  value,
  type,
  icon,
  config,
  actionListChildren,
  onRetrieve,
  onClickItem,
  initialQuery,
  avoidFetch,
  effectChildren
}: ViewModel.CyChartProps) => {
  const { useParsers } = useContext(CyWrapperContext);
  const { parseChart } = useParsers(value?.query);
  const safeType = useMemo(() => (Object.values(ViewModel.ChartType).includes(type) ? type : null), [type]);
  const chartData = useMemo(() => parseChart(config, type, { entity: value?.query, list: value?.list }), [config, type, value?.query, value?.list, parseChart]);
  const { onGeneralFetch, isLoading, canFetch } = useGeneralFetch({ onFetch: onRetrieve, avoidFetch });
  const { onRefresh, onInitialFetch } = useSearchFetch({ onFetch: onGeneralFetch, isLoading, canFetch, value, initialFetchCriteria: initialQuery });
  const { initialCursor, currentCursor } = useParsedCursors({ initialFetchCriteria: initialQuery, value });

  useOnRefresh({ id, componentName }, onRefresh);
  useOnEntityChange(initialCursor, currentCursor, onInitialFetch);

  const handleOnClickItem = useMemo(
    () => (typeof onClickItem === 'function' ? (evt, meta) => onClickItem(evt, meta)?.pipe(swallowError()).subscribe() : undefined),
    [onClickItem]
  );

  const chartBaseProps = useMemo(() => {
    return {
      id,
      title,
      subtitle,
      value: chartData,
      isLoading,
      onClickItem: handleOnClickItem
    };
  }, [id, isLoading, subtitle, chartData, title, handleOnClickItem]);

  const renderActions = useCallback(
    () => (
      <div css={styles.actionsContainer}>
        <PreventClickPropagation>
          <OptionMenu
            defaultBtnColor="NEUTRAL_1"
            defaultBtnType={CTAType.LINK}
            optionList={getParsedActionChildren(actionListChildren).map((actionItem, i) => ({
              testid: `option-menu-action-${i}`,
              label: actionItem.label,
              image: actionItem.icon,
              disabled: !!actionItem.disabled,
              onClick: actionItem.onClick && (event => actionItem.onClick(undefined, { metaKey: event?.metaKey })),
              color: actionItem.color,
              type: actionItem.type,
              tooltip: actionItem.helperText,
              important: actionItem.important
            }))}
          />
        </PreventClickPropagation>
      </div>
    ),
    [actionListChildren]
  );

  const ChartComponent = useMemo(() => chartComponents[safeType], [safeType]);

  return (
    <div>
      <div data-testid="effects">{effectChildren}</div>
      <div>
        {safeType ? (
          safeType === ViewModel.ChartType.KPI ? (
            <>
              <KPIChart {...chartBaseProps} color={color} icon={icon} />
              {renderActions()}
            </>
          ) : (
            <ChartWrapper>
              <ChartComponent {...chartBaseProps} type={safeType} config={config} />
              {renderActions()}
            </ChartWrapper>
          )
        ) : null}
      </div>
    </div>
  );
};

CyChart.displayName = ViewModel.DisplayName.CY_CHART;
