import type { ReactNode } from 'react';
import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import type { ApiModel, GeneralModel, ParsedList, SchemaFormContextValue } from '@cyferd/client-engine';
import { ClientEngineContext, SchemaFormContext, ViewModel, noop, useDebounce, useTimeout } from '@cyferd/client-engine';

import { GAP, TRANS } from '@constants';
import { useBulkEditContext } from '@components/smart/CyTable/hooks';
import { getElementMenuPosition, useDropdownDimensions, useOutsideClick } from '@utils';
import { useDropdownInteractions } from '@utils/useDropdownInteractions';

import { getCoreOptions } from './getCoreOptions';
import { styles } from './styles';
import { useCollectionLookupFetch } from './useCollectionLookupFetch';

import { CTA, CTAType } from '../CTA';
import { DropdownOption } from '../DropdownOption';
import { Input } from '../Input';
import type { IOptionMenu } from '../OptionMenu';
import { PreventClickPropagation } from '../PreventClickPropagation';
import { Spinner } from '../Spinner';
import { CyWrapperContext } from '../../smart/CyWrapper';

export interface CollectionLookupProps {
  value?: string;
  description?: string;
  disabled?: boolean;
  disabledType?: GeneralModel.DisabledType;
  avoidFetch?: boolean;
  errorMessage?: string;
  id?: string;
  label?: string;
  required?: boolean;
  testid?: string;
  onChange: (value: string, record: ApiModel.ApiRecord) => void;
  collectionId: GeneralModel.EvaluatorFormula<string>;
  /** custom fetch criteria to list */
  fetchCriteria?: GeneralModel.FetchCriteria;
  delay?: number;
  truncate?: boolean;
  autoFocus?: boolean;
  hiddenOpenRecordBtn?: boolean;
  disableFreeText?: boolean;
  fixedFilter?: GeneralModel.FetchCriteria['fixedFilter'];
  fixedItems?: GeneralModel.JSONSchemaMetadata['optionList'];
  coreOptions?: boolean;
  coreOptionsFormula?: any;
  optionList?: IOptionMenu['optionList'];
  optionContainerHeight?: number;
  info?: string;
  renderCustomOption?: (props: { isLoadingList: boolean; search: string; onClose: () => void }) => ReactNode;
  showClear?: boolean;
  path?: string[];
}

export const CollectionLookup = memo(
  ({
    value,
    description,
    disabled,
    disabledType,
    avoidFetch,
    errorMessage,
    id,
    label,
    required,
    testid = 'collection-lookup',
    onChange,
    fixedFilter,
    // this is the prop optionList
    fixedItems,
    coreOptions,
    coreOptionsFormula,
    collectionId,
    fetchCriteria,
    delay = 1000,
    disableFreeText,
    hiddenOpenRecordBtn,
    optionContainerHeight = 270,
    truncate,
    autoFocus,
    // this is the context menu options
    optionList = [],
    info,
    showClear = true,
    path,
    renderCustomOption
  }: CollectionLookupProps) => {
    const { useNavigateToRecord } = useContext(CyWrapperContext);
    const { fullValue, useParsers } = useContext<SchemaFormContextValue<ApiModel.ApiRecord>>(SchemaFormContext);
    const { useUserSelector } = useContext(ClientEngineContext);
    const user = useUserSelector();
    const [internalValue, setInternalValue] = useState<string>('');
    const [selectedRes, setSelectedRes] = useState<ApiModel.ApiValue>();
    const search = useDebounce(internalValue, delay);
    const timeoutRunner = useTimeout();
    const { parseList, parseRecord, parseData } = useParsers({ fetchCriteria, collectionId });

    const { isLoadingList, isLoadingRecord, allItemsRetrieved, baseCursor, onGet, onList, listRes, setListRes, onCancel$ } = useCollectionLookupFetch({
      disableFreeText,
      collectionId,
      value,
      fetchCriteria,
      fixedFilter,
      onGetSuccess: setSelectedRes,
      parseData,
      fullValue,
      limit: 10,
      path
    });

    const items: ParsedList['items'] = useMemo(() => {
      const parsedFixedItems: ParsedList['items'] = fixedItems?.length
        ? fixedItems.map(item => ({
            title: item.label,
            description: item.description,
            color: item.color,
            image: item.image,
            fullItem: { id: item.value },
            raw: { id: item.value },
            list: []
          }))
        : [];

      const actualCollectionId = collectionId || fetchCriteria?.collectionId;
      const actualCoreOptions = coreOptions && actualCollectionId ? getCoreOptions(actualCollectionId, coreOptionsFormula, user) : [];

      const fullFixed = [...parsedFixedItems, ...actualCoreOptions];

      const filteredFixed = internalValue
        ? fullFixed.filter(i => i.title?.toLowerCase().includes(internalValue.toLowerCase()) || i.raw.id?.toLowerCase().includes(internalValue.toLowerCase()))
        : fullFixed;

      return [...filteredFixed, ...parseList({ entity: listRes?.query, list: listRes?.list }).items];
    }, [collectionId, fetchCriteria, coreOptions, coreOptionsFormula, fixedItems, internalValue, listRes?.list, listRes?.query, user, parseList]);

    const onNavigate = useNavigateToRecord();

    const { handleKeyDown, setOpen, selectRef, highlightedIndex, isOpen, handleSelectItem } = useDropdownInteractions({
      autoFocus,
      options: items,
      initialHighlightedIndex: null,
      onSelect: index => {
        onSelect(listRes?.query, items[index].fullItem);
      }
    });

    const { mainRef, dropdownRef, triggerDimensions, menuDimensions, clientDimensions } = useDropdownDimensions();

    const idMatch = useMemo(() => items?.find(i => i.fullItem?.id === search), [items, search]);

    const parsedSelection = useMemo(
      () => (value === selectedRes?.record?.id ? parseRecord({ value: selectedRes?.record, entity: selectedRes?.query, addDefaults: true }) : undefined),
      [parseRecord, selectedRes?.query, selectedRes?.record, value]
    );

    const displayValue = useMemo(() => {
      if (isLoadingRecord) return 'Loading...';
      if (isOpen && !avoidFetch) return internalValue;
      if ((parsedSelection?.output as any)?.recordTitle) return parsedSelection?.title;
      if (typeof value === 'string') return value;
      return '';
    }, [avoidFetch, internalValue, isLoadingRecord, isOpen, parsedSelection?.output, parsedSelection?.title, value]);

    const completeOptionList = useMemo(
      () => [
        ...optionList,
        !hiddenOpenRecordBtn && {
          important: !optionList?.length,
          tooltip: TRANS.client.buttons.open,
          image: 'open_in_new' as GeneralModel.IconName,
          type: CTAType.LINK,
          disabled: !value,
          size: ViewModel.CTASize.MEDIUM,
          onClick: () => onNavigate(baseCursor?.collectionId, value)
        }
      ],
      [baseCursor?.collectionId, hiddenOpenRecordBtn, onNavigate, optionList, value]
    );

    const loadMore = useCallback(() => onList(search, true), [onList, search]);

    const onClose = useCallback(
      () =>
        timeoutRunner(() => {
          if (isOpen) setOpen(false);
          /* istanbul ignore else */
          if (internalValue) {
            setListRes(undefined);
            onCancel$.current.next();
          }
          setInternalValue('');
        }, 100),
      [internalValue, onCancel$, setListRes, timeoutRunner, isOpen, setOpen]
    );

    const outsideRef = useOutsideClick(onClose);

    const onSelect = useCallback(
      (query: ApiModel.ApiValue['query'], record: ApiModel.ApiRecord) => {
        onChange(record?.id, record);
        setSelectedRes({ query, record, totalCount: 0 });
        onClose();
      },
      [onChange, onClose]
    );

    const onClear = useCallback(() => {
      onSelect(undefined, { id: null });
    }, [onSelect]);

    useEffect(() => {
      if (value) onGet();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collectionId, value]);

    useEffect(() => {
      if (!isOpen || avoidFetch) return;
      onCancel$.current.next();
      onList(search);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, search, baseCursor, fixedFilter]);

    const menuElementPosition = getElementMenuPosition({ triggerDimensions, menuDimensions, clientDimensions, verticalPadding: 2 });

    const onOpen = useCallback(() => {
      if (disabled) return;
      setOpen(true);
    }, [disabled, setOpen]);

    return (
      <div ref={outsideRef}>
        <div css={styles.inputContainer} ref={selectRef} onKeyDown={handleKeyDown} onClick={onOpen}>
          <Input
            value={displayValue}
            color={parsedSelection?.color as GeneralModel.Color.ThemeColor}
            onChange={setInternalValue}
            id={id}
            testid={testid}
            autoFocus={autoFocus}
            disabled={!baseCursor.collectionId || disabled}
            disabledType={disabledType}
            label={label}
            required={required}
            errorMessage={errorMessage}
            description={description}
            optionList={completeOptionList}
            showClear={showClear}
            onCustomClear={onClear}
            info={info}
            inputContainerRef={mainRef}
          />
        </div>
        {!!isOpen && (!avoidFetch || !!renderCustomOption) && (
          <div
            data-testid={`${testid}-options-container`}
            css={styles.dropdownContainer}
            ref={dropdownRef}
            style={{
              width: triggerDimensions.width,
              top: menuElementPosition.style.top
            }}
          >
            <div css={styles.options} style={{ maxHeight: optionContainerHeight }}>
              {!!renderCustomOption && renderCustomOption({ isLoadingList, search, onClose })}
              {!isLoadingList && !avoidFetch && !items?.length && (
                <DropdownOption title="No results found" description={null} color={null} image={null} onClick={noop} testid={`${testid}-no-results`} />
              )}
              {!disableFreeText && !isLoadingList && !avoidFetch && !idMatch && value !== search && !!search && (
                <DropdownOption
                  title={`Use "${search}" as manual input`}
                  description={null}
                  color={null}
                  image={null}
                  onClick={() => onSelect(undefined, { id: search })}
                  testid={`${testid}-item-fallback`}
                />
              )}
              {!avoidFetch &&
                items.map((item, index) => (
                  <div key={`${index}-${item.fullItem.id}`}>
                    <DropdownOption
                      title={item.title}
                      description={item.description}
                      color={item.color as any}
                      image={item.image}
                      truncate={truncate}
                      onClick={() => handleSelectItem(index)}
                      testid={`${testid}-item`}
                      highlight={highlightedIndex === index}
                    />
                  </div>
                ))}
              {!!isLoadingList && (
                <div css={styles.spinnerContainer}>
                  <Spinner size={GAP.L} />
                </div>
              )}
              {!isLoadingList && !avoidFetch && listRes?.list?.length && !allItemsRetrieved ? (
                <PreventClickPropagation>
                  <div css={styles.loadMoreContainer}>
                    <CTA testid={`${testid}-load-more`} type={CTAType.LINK} label="Load more" onClick={loadMore} disabled={isLoadingList} />
                  </div>
                </PreventClickPropagation>
              ) : null}
            </div>
          </div>
        )}
      </div>
    );
  }
);

CollectionLookup.displayName = 'CollectionLookup';

export const CollectionLookupEditTable = ({ recordId, ...props }: CollectionLookupProps & { recordId }) => {
  const { list, editedRecords } = useBulkEditContext();
  const [temp, setTemp] = useState();
  const { useParsers } = useContext(CyWrapperContext);
  const setFullValue = () => {};

  const fullValue = useMemo(() => editedRecords[recordId] ?? list.find(({ id }) => id === recordId), [list, recordId, editedRecords]);

  return (
    <SchemaFormContext.Provider value={{ fullValue, setFullValue, temp, setTemp, useParsers }}>
      <CollectionLookup {...props} label={null} />
    </SchemaFormContext.Provider>
  );
};
