import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, RADIUS } from '@constants';
import { css } from '@emotion/react';

const FOOTER_HEIGHT = '60px';

export const styles = {
  tabContainer: css`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  `,
  container: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.L};
    flex: 1;
    max-height: calc(100% - ${FOOTER_HEIGHT});
  `,
  header: css`
    display: flex;
    gap: ${GAP.M};
    align-items: center;
    justify-content: flex-start;
    padding: ${GAP.L} ${GAP.L} 0 ${GAP.L};
  `,
  avatar: css`
    width: 50px;
    height: 50px;
  `,
  user: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.XS};
  `,
  title: css`
    color: ${COLOR.NEUTRAL_1};
    font-size: ${FONT_SIZE.M};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  `,
  subtitle: css`
    color: ${COLOR.NEUTRAL_2};
    font-size: ${FONT_SIZE.XXS};
    font-weight: ${FONT_WEIGHT.NORMAL};
  `,
  menuContent: css`
    padding: 0;
  `,
  deviceContent: css`
    padding: 0 ${GAP.L} 0 ${GAP.L};
  `,
  notificationsContent: css`
    padding: 0 ${GAP.L} ${GAP.L} ${GAP.L};
  `,
  content: css`
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;

    [data-selector='cta-label'] {
      text-decoration: none;
    }
    button {
      gap: ${GAP.M};
    }
  `,
  row: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 36px;
    padding: 0 ${GAP.M};
    margin: 0 ${GAP.M};
    border-radius: ${RADIUS.S};
    cursor: pointer;
    :hover {
      background-color: ${COLOR.NEUTRAL_5};
    }
  `,
  activeRow: css`
    background-color: ${COLOR.BRAND_1}!important;
  `,
  division: css`
    width: 100%;
    border-top: 1px ${COLOR.NEUTRAL_6} solid;
    padding-top: ${GAP.S};
    margin-top: ${GAP.S};
  `,
  sidebarFooter: {
    footer: css`
      width: 100%;
      flex-direction: column;
      gap: ${GAP.S};
      height: ${FOOTER_HEIGHT};
    `,
    tenant: css`
      font-size: ${FONT_SIZE.S};
      color: ${COLOR.NEUTRAL_2};
      font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    `,
    cyferdContainer: css`
      gap: ${GAP.XS};
    `,
    cyferdText: css`
      font-size: ${FONT_SIZE.XXS};
      color: ${COLOR.NEUTRAL_2};
      font-weight: ${FONT_WEIGHT.NORMAL};
    `
  }
};
