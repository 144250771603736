import { useContext } from 'react';

import { ApiModel, ClientEngineContext, ViewModel } from '@cyferd/client-engine';
import { useRequest } from '@utils/useRequest';
import { BuilderCyList } from '../../../builder/views/shared/BuilderCyList';

export interface DevicesRow {
  id?: string;
  item: string | JSX.Element;
}

export const DevicesTable = () => {
  const { useUserSelector } = useContext(ClientEngineContext);
  const userId = useUserSelector()?.id;
  const request = useRequest();

  return (
    <BuilderCyList
      request={request}
      componentName={ApiModel.ApiEntity.DEVICE}
      showDelete={true}
      fixedFilter={{ userId: { $eq: userId } }}
      key={ApiModel.ApiEntity.DEVICE}
      collectionId={ApiModel.ApiEntity.DEVICE}
      type={ViewModel.CyListType.CARD}
      paginationHidden={true}
      orderHidden={true}
      recordActionsHidden={true}
      advancedFiltersHidden={true}
      quickFiltersHidden={true}
      searchStringHidden={true}
      typeSelectorHidden={true}
      framed={false}
    />
  );
};
