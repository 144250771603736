import { css } from '@emotion/react';
import { COLOR } from '@constants';

export const styles = {
  builderWrapper: css`
    background-color: ${COLOR.NEUTRAL_5};
    border-color: ${COLOR.NEUTRAL_4};
    border-style: solid;
    border-bottom-width: 1px;
  `,
  show: css`
    transition: all 0.5s;
    display: block;
    height: max-content;
    opacity: 1;
    @starting-style {
      height: 0px;
      opacity: 0;
    }
  `,
  hide: css`
    display: none;
    height: 0px;
    opacity: 0;
  `
};
