import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CTA, CTAType } from '../CTA';
import { styles } from './styles';
import { useOutsideClick } from '@utils/useOutsideClick';
import { PreventClickPropagation } from '../PreventClickPropagation';
import { AppMenu } from '../AppMenu';
import { FavoriteMenu } from '../FavoriteMenu';
import { ClientEngineContext, GeneralModel, UserModel, ViewModel, registry } from '@cyferd/client-engine';
import { useUrlBuilder } from '@utils/useUrlBuilder';
import { TRANS, getIsDynamicPage } from '@constants';
import { Avatar } from '../Avatar';
import { AccountMenu } from '../AccountMenu';
import { NotificationMenu } from '../NotificationMenu';
import { useNotificationSelector } from '../../../client-app/state-mgmt/notification';
import { ToolTip } from '../Tooltip';
import { useFavorite } from '../../../client-app/state-mgmt/favorite';
import { DevMenu, DevMenuTab } from '../DevMenu';
import { GlobalContext } from '../../../builder/state-mgmt/GlobalState';
import { Sidebar } from '../Sidebar';
import { UIContext } from '../../providers/UIprovider';
import { OptionMenu } from '../OptionMenu';
import { useShortLink } from '../../platform/GlobalHeader/Shortlink';
import { usePrint } from '../../platform/GlobalHeader/Print';

export enum GlobalToolName {
  ACCOUNT = 'ACCOUNT',
  NOTIFICATION = 'NOTIFICATION',
  APP = 'APP',
  FAVORITE = 'FAVORITE',
  DEVTOOL = 'DEVTOOL'
}

export interface GlobalToolbarProps {
  hiddenFavorites?: boolean;
  hiddenApps?: boolean;
  hiddenNotifications?: boolean;
  hiddenPreferences?: boolean;
  hiddenShortlink?: boolean;
  hiddenPrint?: boolean;
  verticalOffset?: number;
  verticalOffsetDirection?: 'top' | 'bottom';
}

const STORAGE_DEVTOOL_KEY = 'devtools-tab';

export const LegacyGlobalToolbar = memo(
  ({
    hiddenFavorites,
    hiddenApps,
    hiddenNotifications,
    hiddenPreferences,
    hiddenShortlink,
    hiddenPrint,
    verticalOffset,
    verticalOffsetDirection
  }: GlobalToolbarProps) => {
    const { deps } = useContext(GlobalContext);
    const { useUserSelector } = useContext(ClientEngineContext);
    const favState = useFavorite();
    const user = useUserSelector();
    const [toolOpen, setToolOpen] = useState<GlobalToolName>(null);
    const [devActiveTab, setDevActiveTab] = useState<DevMenuTab>(deps?.storage.get(STORAGE_DEVTOOL_KEY) || /* istanbul ignore next */ DevMenuTab.ACTIONS);
    const { pathname } = useUrlBuilder();
    const isDynamicPage = getIsDynamicPage(pathname);
    const notification = useNotificationSelector();
    const hasUnreadNotifications = useMemo(() => !!notification?.latest?.list?.find(notification => notification?.read === false), [notification?.latest]);
    const hasPermissionForDevtool = user?.roles?.includes?.(UserModel.UserRole.DEVELOPER);
    const { size } = useContext(UIContext);
    const onClose = () => setToolOpen(null);
    const onChangeToolbar = useCallback((event: GlobalToolName) => () => setToolOpen(prev => (prev === event ? null : event)), []);
    const outsideRef = useOutsideClick(onClose);

    const onChangeTab = useCallback(
      (event: DevMenuTab) => {
        setDevActiveTab(event);
        deps?.storage.set(STORAGE_DEVTOOL_KEY, event);
      },
      [deps?.storage]
    );

    useEffect(() => {
      /* istanbul ignore else */
      if (hasPermissionForDevtool) registry.setActionRegistryLimit(25);
    }, [hasPermissionForDevtool]);

    const { onClick: onLink, ShortLinkModal } = useShortLink();
    const { onClick: onPrint, printStyle } = usePrint();

    const optionList = useMemo(
      () =>
        [
          {
            hidden: hiddenNotifications || size !== GeneralModel.AppSize.S,
            label: TRANS.client.nav.notifs,
            image: 'notifications' as GeneralModel.IconName,
            color: 'NEUTRAL_1' as GeneralModel.Color.ThemeColor,
            outlined: true,
            onClick: onChangeToolbar(GlobalToolName.NOTIFICATION),
            dot: hasUnreadNotifications
          },
          {
            hidden: hiddenFavorites || size !== GeneralModel.AppSize.S,
            label: TRANS.client.nav.favs,
            image: 'star' as GeneralModel.IconName,
            color: 'NEUTRAL_1' as GeneralModel.Color.ThemeColor,
            outlined: true,
            onClick: onChangeToolbar(GlobalToolName.FAVORITE)
          },
          {
            hidden: hiddenShortlink || size === GeneralModel.AppSize.L,
            label: TRANS.client.nav.share,
            image: 'share' as GeneralModel.IconName,
            color: 'NEUTRAL_1' as GeneralModel.Color.ThemeColor,
            outlined: true,
            onClick: onLink
          },
          {
            hidden: hiddenPrint || size === GeneralModel.AppSize.L,
            label: TRANS.client.nav.print,
            image: 'print' as GeneralModel.IconName,
            color: 'NEUTRAL_1' as GeneralModel.Color.ThemeColor,
            outlined: true,
            onClick: onPrint
          },
          {
            hidden: !isDynamicPage || !hasPermissionForDevtool || size !== GeneralModel.AppSize.S,
            label: TRANS.client.nav.dev,
            image: 'terminal' as GeneralModel.IconName,
            color: 'NEUTRAL_1' as GeneralModel.Color.ThemeColor,
            outlined: true,
            onClick: onChangeToolbar(GlobalToolName.DEVTOOL)
          }
        ].filter(opt => !opt.hidden),
      [
        onPrint,
        onLink,
        onChangeToolbar,
        hasUnreadNotifications,
        hiddenNotifications,
        size,
        isDynamicPage,
        hasPermissionForDevtool,
        hiddenPrint,
        hiddenShortlink,
        hiddenFavorites
      ]
    );

    if ([hiddenFavorites, hiddenApps, hiddenNotifications, hiddenPreferences, hiddenShortlink, hiddenPrint].every(Boolean)) return null;

    return (
      <div ref={outsideRef} data-testid="GlobalToolbar">
        <div data-testid="toolbar" css={styles.toolbar}>
          {!hiddenFavorites && size !== GeneralModel.AppSize.S && (
            <CTA
              testid="favorites-toggle"
              icon="star"
              outlined={!isDynamicPage || !favState?.current}
              type={CTAType.LINK}
              size={ViewModel.CTASize.LARGE}
              color="NEUTRAL_1"
              tooltip={TRANS.client.nav.favs}
              onClick={onChangeToolbar(GlobalToolName.FAVORITE)}
            />
          )}
          {!hiddenApps && (
            <CTA
              testid="apps-toggle"
              icon="apps"
              outlined={true}
              type={CTAType.LINK}
              size={ViewModel.CTASize.LARGE}
              color="NEUTRAL_1"
              tooltip={TRANS.client.nav.apps}
              onClick={onChangeToolbar(GlobalToolName.APP)}
            />
          )}
          {!hiddenNotifications && size !== GeneralModel.AppSize.S && (
            <div css={styles.relativeContainer}>
              <CTA
                testid="notifications-toggle"
                icon="notifications"
                outlined={true}
                type={CTAType.LINK}
                size={ViewModel.CTASize.LARGE}
                color="NEUTRAL_1"
                tooltip={TRANS.client.nav.notifs}
                onClick={onChangeToolbar(GlobalToolName.NOTIFICATION)}
              />
              {hasUnreadNotifications && <div data-testid="unread-flag" css={styles.dot} />}
            </div>
          )}
          {!!isDynamicPage && hasPermissionForDevtool && size !== GeneralModel.AppSize.S && (
            <CTA
              testid="dev-toggle"
              icon="terminal"
              outlined={true}
              type={CTAType.LINK}
              size={ViewModel.CTASize.LARGE}
              color="NEUTRAL_1"
              tooltip={TRANS.client.nav.dev}
              onClick={onChangeToolbar(GlobalToolName.DEVTOOL)}
            />
          )}
          {!hiddenPreferences && (
            <ToolTip text={TRANS.client.nav.account}>
              <div css={styles.avatar} onClick={onChangeToolbar(GlobalToolName.ACCOUNT)} data-testid="account-toggle">
                <Avatar size="fill" src={user?.recordImage} alt={user?.fullName} />
              </div>
            </ToolTip>
          )}
          <div css={styles.relativeContainer}>
            {hasUnreadNotifications && !hiddenNotifications && size === GeneralModel.AppSize.S && <div data-testid="unread-flag" css={styles.dot} />}
            {optionList.length > 0 && (
              <OptionMenu defaultBtnType={CTAType.LINK} defaultBtnColor="NEUTRAL_1" defaultBtnSize={ViewModel.CTASize.LARGE} optionList={optionList} />
            )}
          </div>
        </div>
        <Sidebar
          open={!!toolOpen}
          wide={toolOpen === GlobalToolName.DEVTOOL}
          verticalOffset={verticalOffset}
          verticalOffsetDirection={verticalOffsetDirection}
          disablePadding={toolOpen === GlobalToolName.ACCOUNT}
        >
          <PreventClickPropagation containerCss={styles.prevent}>
            {toolOpen === GlobalToolName.APP && <AppMenu onClose={onClose} />}
            {toolOpen === GlobalToolName.FAVORITE && <FavoriteMenu onClose={onClose} />}
            {toolOpen === GlobalToolName.ACCOUNT && <AccountMenu onClose={onClose} />}
            {toolOpen === GlobalToolName.NOTIFICATION && <NotificationMenu />}
            {toolOpen === GlobalToolName.DEVTOOL && <DevMenu activeTab={devActiveTab} onChangeTab={onChangeTab} />}
          </PreventClickPropagation>
        </Sidebar>
        <ShortLinkModal />
        <div css={printStyle} />
      </div>
    );
  }
);

LegacyGlobalToolbar.displayName = 'LegacyGlobalToolbar';
