import { isEqual, sortBy, uniq } from 'lodash';

import type { GeneralModel } from '@cyferd/client-engine';
import { getDynamicQuickFilterSelectedOptions } from './getDynamicQuickFilterSelectedOptions';

const arraysEqualUnordered = (arr1: string[], arr2: string[]): boolean => {
  return isEqual(sortBy(uniq(arr1)), sortBy(uniq(arr2)));
};

export const getChangedQuickFilters = (
  qf: GeneralModel.FetchCriteria['quickFilters'] = [],
  newQf: GeneralModel.FetchCriteria['quickFilters'] = []
): string[] => {
  const map1 = new Map(qf.map(obj => [obj.id, obj]));
  const map2 = new Map(newQf.map(obj => [obj.id, obj]));

  const removed = qf.filter(obj => !map2.has(obj.id)).map(obj => obj.id);
  const added = newQf.filter(obj => !map1.has(obj.id)).map(obj => obj.id);
  const modified = qf
    .filter(
      obj =>
        map2.has(obj.id) &&
        !arraysEqualUnordered(
          getDynamicQuickFilterSelectedOptions(obj.filter, obj.fieldId),
          getDynamicQuickFilterSelectedOptions(map2.get(obj.id).filter, obj.fieldId)
        )
    )
    .map(obj => obj.id);

  return [...removed, ...added, ...modified];
};
