import { useEffect, useMemo, useRef, useState } from 'react';
import { finalize, takeUntil } from 'rxjs';

import { swallowError, CollectionModel, useUnmountObservable, useDebounce } from '@cyferd/client-engine';
import type { GeneralModel } from '@cyferd/client-engine';

import type { ChipDropdownProps } from '@components/elements/Chips/ChipDropdown';
import type { ChipProps } from '@components/elements/Chips/Chip';
import { ChipType } from '@components/elements/Chips';
import { getDynamicQuickFilterSelectedOptions } from './getDynamicQuickFilterSelectedOptions';
import { getChangedQuickFilters } from './getChangedQuickFilters';
import type { IuseQuickFilters, IuseQuickFiltersResult } from './types';

export const useQuickFilters = ({ cursor, schema, config, onFetch, disabled, delay = 2000, containerRef }: IuseQuickFilters): IuseQuickFiltersResult => {
  const onDestroy$ = useUnmountObservable();
  const [state, setState] = useState<GeneralModel.FetchCriteria['quickFilters']>(Array.isArray(cursor?.quickFilters) ? cursor?.quickFilters : []);
  const [loadingFilters, setLoadingFilters] = useState<string[]>([]);
  const isUserTriggered = useRef(false);
  const debouncedState = useDebounce(state, delay);
  const subscriptionRef = useRef<any>(null);

  useEffect(() => {
    setState(cursor?.quickFilters || []);
    isUserTriggered.current = false;
  }, [cursor?.quickFilters]);

  useEffect(() => {
    if (isUserTriggered.current) {
      const filterChanges = getChangedQuickFilters(cursor.quickFilters, debouncedState);

      if (filterChanges.length > 0) {
        setLoadingFilters(filterChanges);

        if (containerRef.current) containerRef.current.click();

        if (subscriptionRef?.current) subscriptionRef.current.unsubscribe();
        subscriptionRef.current = onFetch({ ...cursor, quickFilters: debouncedState })
          .pipe(
            takeUntil(onDestroy$),
            swallowError(),
            finalize(() => {
              setLoadingFilters([]);
              subscriptionRef.current = null;
              isUserTriggered.current = false;
            })
          )
          .subscribe();
      }
    }
  }, [containerRef, cursor, debouncedState, onDestroy$, onFetch]);

  const filters: (ChipProps | ChipDropdownProps)[] = useMemo(
    () =>
      config
        .filter(filter => !filter.type || filter.type === CollectionModel.QuickFilterType.CUSTOM || schema?.properties?.[filter.fieldId]?.metadata?.optionList)
        .map(filter => {
          const isDropdown = filter.type === CollectionModel.QuickFilterType.FIELD;
          const active = state.some(f => f?.id === filter.id);

          const activeValues: (number | string)[] = getDynamicQuickFilterSelectedOptions(
            state?.find(cursorQf => cursorQf?.id === filter.id)?.filter,
            filter.fieldId
          );

          const property = schema?.properties?.[filter.fieldId];

          const options: ChipDropdownProps['options'] = (property?.metadata?.optionList || [])
            .filter(opt => !opt.hidden)
            .map(option => ({
              label: option.label,
              value: option.value,
              image: option.image,
              color: option.color,
              onClick: () => {
                const newActive = (() => {
                  if (filter.optionListType === CollectionModel.QuickFilterOptionListType.ONE) return activeValues.includes(option.value) ? [] : [option.value];
                  return activeValues.includes(option.value) ? activeValues.filter(v => v !== option.value) : [...activeValues, option.value];
                })();

                const newFilter =
                  filter.optionListType === CollectionModel.QuickFilterOptionListType.ONE
                    ? {
                        [filter.fieldId]: { $eq: newActive[0] }
                      }
                    : {
                        [filter.fieldId]: { $in: newActive }
                      };

                setState(() => {
                  const filteredState = state.filter(f => f?.id !== filter.id);

                  if (!newActive.length) return filteredState;

                  return filteredState.concat({
                    ...filter,
                    filter: newFilter
                  });
                });

                isUserTriggered.current = true;
              }
            }));

          const updateFilters = () => {
            setState(active ? state.filter(f => f?.id !== filter.id) : [...state, filter]);
            isUserTriggered.current = true;
          };

          return {
            id: filter.id,
            title: isDropdown ? (property?.title ?? property?.label) : filter.name,
            description: filter.description,
            color: filter.color as GeneralModel.Color.ThemeColor,
            icon: filter.icon as GeneralModel.IconName,
            disabled,
            showCheck: true,
            active,
            activeValues: isDropdown ? activeValues : undefined,
            type: isDropdown ? ChipType.DROPDOWN : ChipType.DEFAULT,
            options: isDropdown ? options : undefined,
            onClick: isDropdown ? undefined : updateFilters
          };
        }),
    [config, schema?.properties, state, disabled]
  );

  const filtersWithLoading = useMemo(
    () =>
      filters.map(filter => {
        if (loadingFilters.includes(filter.id)) {
          return {
            ...filter,
            isLoading: true
          };
        }
        return filter;
      }),
    [filters, loadingFilters]
  );

  return {
    filters: filtersWithLoading,
    subscriptionRef
  };
};
