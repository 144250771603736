import { COLOR, EMPTY_CONTAINER_HEIGHT, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  spinnerContainer: css`
    display: flex;
    width: 100%;
    height: 100%;
    flex: 1;
    align-items: center;
    justify-content: center;
    min-height: ${EMPTY_CONTAINER_HEIGHT.XS}px;
    padding: ${GAP.L};
  `,
  chartWrapper: css`
    max-height: 645px;
    padding-bottom: ${GAP.M};
  `,
  chartContainer: css`
    aspect-ratio: 12 / 6;

    .MuiBarElement-root {
      shape-rendering: auto;
      ry: 2px;
    }

    .MuiChartsGrid-line {
      stroke: ${COLOR.NEUTRAL_2} !important;
      opacity: 0.12;
    }

    .MuiLineElement-root {
      stroke-width: ${GAP.XS};
    }

    .MuiChartsAxisHighlight-root {
      stroke: ${COLOR.NEUTRAL_2};
    }
  `,
  chartTooltip: `
    & > div {
      background-color: ${COLOR.TOP_BACKGROUND};
    }
    * {
      font-family: 'Inter', sans-serif !important;
    }
  `,
  empty: css`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: ${EMPTY_CONTAINER_HEIGHT.XS}px;
  `
};
