import type { ChangeEvent, ComponentProps, FormEvent } from 'react';
import { useEffect, useRef, useState } from 'react';

import type { ApiModel } from '@cyferd/client-engine';
import { ViewModel, useDebounce, useIsMounted, useTranslate, useUnmountObservable } from '@cyferd/client-engine';

import { CTA, CTAType } from '@components/elements/CTA';
import { styles } from './styles';
import { Observable, finalize, takeUntil } from 'rxjs';
import { Spinner } from '../Spinner';
import { TRANS } from '@constants';

export interface SearchInputProps {
  autoFocus?: boolean;
  id?: string;
  testid?: string;
  value?: string;
  disabled?: boolean;
  compact?: boolean;
  colapseDelay?: number;
  placeholder?: string;
  alternative?: boolean;
  searchDelay?: number;
  ctaProps?: Partial<ComponentProps<typeof CTA>>;
  onChange: (value: string) => void;
  onClick?: () => void | Observable<ApiModel.APIAction>;
}

export const SearchInput = ({
  autoFocus,
  id,
  testid,
  value,
  disabled,
  compact,
  colapseDelay = 300,
  placeholder = TRANS.client.placeholder.search,
  alternative,
  searchDelay,
  ctaProps,
  onChange,
  onClick
}: SearchInputProps) => {
  const { translate } = useTranslate();
  const safeTestId = testid || 'search-input';
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const [hasFocus, setFocus] = useState<boolean>(false);
  const ref = useRef<HTMLInputElement>();
  const isMounted = useIsMounted();
  const onDestroy$ = useUnmountObservable();
  const [internalValue, setInternalValue] = useState<string>(value);
  const delayedValue = useDebounce(internalValue, searchDelay);
  const onClickRef = useRef(onClick);
  onClickRef.current = onClick;

  const onInternalChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInternalValue(event.target.value);
  };

  const onFetch = () => {
    const result$ = onClickRef.current?.();
    if (!(result$ instanceof Observable)) return;
    setIsLoading(true);
    result$
      .pipe(
        takeUntil(onDestroy$),
        finalize(() => setIsLoading(false))
      )
      .subscribe();
  };

  const onClear = () => {
    onChange?.('');
    focus();
    setTimeout(() => onFetch());
  };

  const focus = () => {
    ref.current?.focus();
    setFocus(true);
  };

  const onExpand = () => {
    setExpanded(true);
    ref.current?.focus();
  };

  const onCollapse = () => {
    setTimeout(() => {
      if (isMounted.current) {
        setExpanded(false);
        setFocus(false);
      }
    }, colapseDelay);
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    onCollapse();
    event?.preventDefault?.();
    if (value !== internalValue) onChange(internalValue);
    setTimeout(() => onFetch());
  };

  useEffect(() => {
    if (value !== internalValue) onChange?.(delayedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delayedValue]);

  useEffect(() => {
    if (value !== internalValue) setInternalValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div css={styles.container} data-testid={`${safeTestId}-container`}>
      <form
        css={[
          styles.form,
          (isExpanded || !compact || !!internalValue) && styles.expandedForm,
          (!!hasFocus || isLoading) && styles.hasFocus,
          !!alternative && styles.alternativeForm
        ]}
        onSubmit={onSubmit}
        data-testid={`${safeTestId}-form`}
      >
        {isLoading ? (
          <div css={styles.spinnerContainer}>
            <Spinner size="18px" />
          </div>
        ) : (
          <CTA
            type={CTAType.LINK}
            color={hasFocus ? 'BRAND_1' : 'NEUTRAL_2'}
            icon="search"
            onClick={onSubmit}
            keepNativeEvent={true}
            disabled={disabled}
            testid={`${safeTestId}-submit-btn`}
          />
        )}
        <span css={styles.inputContainer}>
          <input
            ref={$el => (ref.current = $el)}
            onBlur={onCollapse}
            autoComplete="new-password"
            role="searchbox"
            autoFocus={autoFocus}
            type="text"
            value={internalValue}
            id={id}
            placeholder={translate(placeholder)}
            onFocus={() => setFocus(true)}
            disabled={disabled || isLoading}
            onChange={onInternalChange}
            css={[styles.searchInput]}
            data-testid={safeTestId}
          />
          {!!value && (
            <CTA
              testid={`${safeTestId}-clear`}
              color={'NEUTRAL_2'}
              type={CTAType.LINK}
              icon="close"
              onClick={onClear}
              tooltip="clear"
              disabled={disabled || isLoading}
              preventDefault={true}
              keepNativeEvent={true}
            />
          )}
        </span>
      </form>
      {!isExpanded && !!compact && !internalValue && (
        <div css={styles.expandContainer}>
          <CTA
            type={CTAType.LINK}
            icon="search"
            onClick={onExpand}
            disabled={disabled}
            tooltip={placeholder}
            size={ViewModel.CTASize.LARGE}
            testid={`${safeTestId}-toggle-btn`}
            {...ctaProps}
          />
        </div>
      )}
    </div>
  );
};

SearchInput.displayName = 'SearchInput';
