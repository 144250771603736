import _ from 'lodash';
import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

interface HighlightedParagraphProps {
  text: string;
  searchQuery?: string;
  styles?: SerializedStyles;
}

export const HighlightedParagraph = ({ text, searchQuery, styles }: HighlightedParagraphProps) => {
  if (!text) return null;

  const escapedSearchQuery = _.escapeRegExp(searchQuery);
  // "split" will return the search query because the regex is a "capture group" (parentheses)
  const parts = text.split(new RegExp(`(${escapedSearchQuery})`, 'gi'));

  return (
    <p css={styles}>
      {parts.map((part, index) =>
        part.toLowerCase() === searchQuery?.toLowerCase() ? (
          <span key={index} css={highlightStyles}>
            {part}
          </span>
        ) : (
          part
        )
      )}
    </p>
  );
};

const highlightStyles = css`
  font-weight: 600;
`;
