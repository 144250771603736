import type { ComponentProps, PropsWithChildren, SetStateAction } from 'react';
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { Observable } from 'rxjs';
import { mergeMap, of, takeUntil, tap } from 'rxjs';

import { ApiModel } from '@cyferd/client-engine';
import type { CollectionModel } from '@cyferd/client-engine';

import {
  GeneralModel,
  actions,
  isDeepEqual,
  mergeTruthy,
  noop,
  ofTypeSetData,
  prepareTermForReg,
  useFinalizeWhileMounted,
  usePrevious,
  useTranslate,
  useUnmountObservable
} from '@cyferd/client-engine';
import { useTestingHelper } from '@utils';

import { BUILDER_ROUTE, LIST_ROUTE, isSavedRecord, isTempId } from '@constants';
import { DevView } from '../../shared/DevView';
import { SubHeader } from '../SubHeader';
import { styles } from './styles';
import { Spinner } from '@components/elements/Spinner';
import { useRequest } from '@utils/useRequest';
import type { TabList } from '@components/elements/TabList';
import { Sidebar } from '@components/elements/Sidebar';
import { CyActivityLog } from '@components/smart/CyActivityLog';
import { useOutsideClick } from '@utils/useOutsideClick';
import { PreventClickPropagation } from '@components/elements/PreventClickPropagation';
import { unstable_usePrompt } from 'react-router-dom';
import { formatOptionFieldQuickFilter } from '../../EntityEditor/components/FilterEditor/formatOptionFieldQuickFilter';
import { parseOptionFieldQuickFilter } from '../../EntityEditor/components/FilterEditor/parseOptionFieldQuickFilter';

export interface EditorContextValue<T> {
  item: T;
  setItem: (item: T) => void;
  onScrollTop?: () => void;
}

export const EditorContext = createContext<EditorContextValue<any>>({ item: undefined, setItem: noop, onScrollTop: noop });

export type OnSaveOptions = { force: boolean };

export type Props = PropsWithChildren<
  {
    item?: any;
    hideSubHeader?: boolean;
    title: string;
    editorTitle: string;
    subtitle?: string;
    icon?: string;
    color?: string;
    isLoading?: boolean;
    hideDevView?: boolean;
    tabsIOptionMenu?: ComponentProps<typeof TabList>['optionMenuProps'];
    activityLogConfig?: { collectionId: string; recordId: string };
    onSave: (item: any, options?: OnSaveOptions) => Observable<any>;
    onRemove: (item: any) => Observable<any>;
  } & Omit<ComponentProps<typeof SubHeader>, 'onSave' | 'onRemove'> &
    Pick<ComponentProps<typeof DevView>, 'showCleanEntity' | 'showCleanView'>
>;

export const EditorHome = ({
  item,
  hideSubHeader,
  children,
  routeList,
  rootPath,
  title,
  editorTitle,
  icon,
  color,
  subtitle,
  onRemove,
  onSave,
  showCleanEntity,
  showCleanView,
  isLoading,
  hideDevView,
  tabsIOptionMenu,
  activityLogConfig,
  tabIOptionMenu,
  ...subHeaderProps
}: Props) => {
  const { translate } = useTranslate();
  const { getTestIdProps } = useTestingHelper('home');
  const ref = useRef<HTMLDivElement>();
  const [isOutputVisible, setOutputVisible] = useState<boolean>(false);
  const [state, setState] = useState(undefined);
  /* istanbul ignore next line */
  const onScrollTop = useCallback(() => ref.current?.scrollTo({ top: 0, left: 0 }), []);
  const editorContextValue: EditorContextValue<any> = useMemo(() => ({ item: state, setItem: setState, onScrollTop }), [onScrollTop, state]);
  const previousItem = usePrevious(item);

  const [logOpen, setLogOpen] = useState<boolean>(false);
  /* istanbul ignore next line */
  const onToggleLog = useCallback(() => setLogOpen(p => !p), []);
  /* istanbul ignore next line */
  const onClose = useCallback(() => setLogOpen(false), []);
  const outsideRef = useOutsideClick(onClose);

  const hasChanges = useMemo(() => !isDeepEqual(item, state, true), [item, state]);

  /* istanbul ignore next */
  unstable_usePrompt({
    message: 'Leave page?\n\nChanges you made will not be saved.',
    when: ({ nextLocation }) => {
      if (rootPath === BUILDER_ROUTE.INVITATION_EDITOR.ROOT) return false;
      const isNextSameSection = new RegExp(`^${prepareTermForReg(rootPath)}`).test(nextLocation.pathname);
      const isList = new RegExp(`^${prepareTermForReg(`${rootPath}${LIST_ROUTE}`)}`).test(nextLocation.pathname);
      const isRoot = nextLocation.pathname === rootPath;
      return hasChanges && (!isNextSameSection || isList || isRoot);
    }
  });

  const internalCallbackMap = useMemo(
    () => ({
      onRemove: !!onRemove && (() => onRemove(state)),
      onSave: !!onSave && ((options: OnSaveOptions) => onSave(state, options))
    }),
    [onRemove, onSave, state]
  );

  const onToggleOutputPanel = useCallback(() => setOutputVisible(prev => !prev), []);

  const additionalButtonList = [
    ...(subHeaderProps.additionalButtonList || []),
    !hideDevView && { icon: 'code', label: 'Dev tools', onClick: onToggleOutputPanel }
  ] as ComponentProps<typeof SubHeader>['additionalButtonList'];

  const hasActivityLog = useMemo(
    () => [activityLogConfig?.collectionId, activityLogConfig?.recordId].every(Boolean),
    [activityLogConfig?.collectionId, activityLogConfig?.recordId]
  );

  const safeTabIOptionMenu = useMemo(
    () =>
      mergeTruthy(tabIOptionMenu, {
        optionList: [
          ...(tabIOptionMenu?.optionList || []),
          hasActivityLog && {
            image: 'device_reset',
            tooltip: 'Activity log',
            important: true,
            outlined: true,
            onClick: onToggleLog
          }
        ]
      }),
    [hasActivityLog, onToggleLog, tabIOptionMenu]
  );

  useEffect(() => {
    if (isDeepEqual(item, previousItem, true)) return;
    setState({
      ...item,
      quickFilters: (item as unknown as CollectionModel.Collection)?.quickFilters?.map(filter => parseOptionFieldQuickFilter(filter))
    });
  }, [item, previousItem]);

  useEffect(() => {
    document.title = translate(item?.createdAt ? title : editorTitle);
  }, [editorTitle, item, title, translate]);

  return (
    <EditorContext.Provider value={editorContextValue}>
      <>
        {!hideSubHeader && !isLoading && (
          <SubHeader
            title={title}
            subtitle={subtitle}
            icon={icon}
            color={color}
            hasChanges={hasChanges}
            routeList={routeList}
            rootPath={rootPath}
            optionMenuProps={tabsIOptionMenu}
            tabIOptionMenu={safeTabIOptionMenu}
            {...subHeaderProps}
            {...internalCallbackMap}
            additionalButtonList={additionalButtonList}
          />
        )}
        <div data-testid="router-container" ref={ref}>
          {isLoading ? (
            <div css={styles.spinnerContainer} {...getTestIdProps('loading')}>
              <Spinner />
            </div>
          ) : (
            <>
              {!!hasActivityLog && (
                <div ref={outsideRef}>
                  <Sidebar open={logOpen} wide={true}>
                    <PreventClickPropagation containerCss={styles.sidepanelContent}>
                      <CyActivityLog
                        title="Activity log"
                        collectionId={activityLogConfig.collectionId}
                        recordId={activityLogConfig.recordId}
                        fitToPage={true}
                      />
                    </PreventClickPropagation>
                  </Sidebar>
                </div>
              )}
              {children}
              <DevView
                open={isOutputVisible}
                onClose={onToggleOutputPanel}
                value={state}
                onChange={setState}
                title={title}
                subtitle={subtitle}
                showCleanEntity={showCleanEntity}
                showCleanView={showCleanView}
              />
            </>
          )}
        </div>
      </>
    </EditorContext.Provider>
  );
};

const newItemGetterFb = () => ({});

export const useEditorHomeEffect = (config: {
  itemId: string;
  collectionId: string;
  setOriginal: SetStateAction<any>;
  setLoading: (loading: boolean) => void;
  newItemGetter?: () => any;
}) => {
  const request = useRequest();
  const onDestroy$ = useUnmountObservable();
  const finalize = useFinalizeWhileMounted();
  const { itemId, collectionId, setOriginal, newItemGetter = newItemGetterFb, setLoading } = config;

  useEffect(() => {
    if (!itemId || isTempId(itemId)) {
      setOriginal({ ...newItemGetter(), id: itemId });
      setLoading(false);
      return;
    }
    of(null)
      .pipe(
        tap(() => setLoading(true)),
        mergeMap(() => request(actions.coreGet({ query: { cursor: { collectionId, id: itemId } }, pointer: GeneralModel.IGNORED_POINTER_ID }))),
        takeUntil(onDestroy$),
        ofTypeSetData<ApiModel.ApiValue<ApiModel.ApiValue>>(),
        tap(v =>
          setOriginal(
            (v.record as any).collectionId !== ApiModel.ApiEntity.ENTITY
              ? v.record
              : {
                  ...v.record,
                  quickFilters: (v.record as unknown as CollectionModel.Collection)?.quickFilters?.map(filter => parseOptionFieldQuickFilter(filter))
                }
          )
        ),
        finalize(() => setLoading(false))
      )
      .subscribe();
  }, [collectionId, finalize, itemId, newItemGetter, onDestroy$, request, setLoading, setOriginal]);
};

export const useEditorHomeOnRemove = (collectionId: string) => {
  const request = useRequest();
  return useCallback(
    (item: any) => {
      return isSavedRecord(item) ? request(actions.coreDelete({ query: { cursor: { collectionId, id: item.id } } })) : of(null);
    },
    [collectionId, request]
  );
};

export const useEditorHomeOnSave = (collectionId: string) => {
  const request = useRequest();
  return useCallback(
    (item: any, { force }: OnSaveOptions) => {
      const id = isSavedRecord(item) ? item.id : undefined;
      return request(
        actions.coreUpsert({
          $cyf_escape: [
            {
              query: { cursor: { collectionId, id } },
              record:
                (item as any).collectionId !== ApiModel.ApiEntity.ENTITY
                  ? { ...item, id }
                  : { ...item, id, quickFilters: item.quickFilters?.map(filter => formatOptionFieldQuickFilter(filter, item?.schema)) },
              pointer: GeneralModel.IGNORED_POINTER_ID,
              options: { reset: true, force }
            }
          ]
        } as any)
      );
    },
    [collectionId, request]
  );
};
