const formatNestedLists = doc => {
  const listItems = doc.querySelectorAll('li');

  listItems.forEach(listItem => {
    const nestedLists = listItem.querySelectorAll('ul, ol');

    nestedLists.forEach(nestedList => {
      const prevLi = listItem.previousElementSibling;

      if (prevLi) {
        prevLi.appendChild(nestedList);
        listItem.remove();
      }
    });
  });

  return doc;
};

const formatCheckLists = doc => {
  const checkLists: NodeListOf<HTMLElement> = doc.querySelectorAll('ul[__lexicallisttype="check"]');

  checkLists.forEach(list => {
    // for check list we don't want the bullet to be visible
    list.style.listStyle = 'none';

    // replace list markers with real inputs
    const listItems = list.querySelectorAll(':scope > li');

    listItems.forEach(listItem => {
      const checked = listItem.getAttribute('aria-checked') == 'true';

      const checkbox = document.createElement('input');
      checkbox.type = 'checkbox';

      if (checked) {
        checkbox.checked = checked;
        checkbox.setAttribute('checked', 'true');
      }

      listItem.prepend(checkbox);
    });
  });

  return doc;
};

const cleanClassesFromHTML = doc => {
  const elementsWithClass = doc.querySelectorAll('[class]');

  elementsWithClass.forEach(element => {
    const classes = element.className.split(' ');

    if (!classes.includes('info-block') && !classes.includes('file-block')) {
      element.removeAttribute('class');
    }
  });

  return doc;
};

const formatOrderedLists = doc => {
  const orderedLists = doc.querySelectorAll('ol');

  const setOrderedListType = (list, level = 0) => {
    const types = ['1', 'a', 'i'];

    const type = types[level % types.length];
    list.setAttribute('type', type);

    const nestedLists = list.querySelectorAll(':scope > li > ol');
    nestedLists.forEach(nestedList => setOrderedListType(nestedList, level + 1));
  };

  orderedLists.forEach(list => {
    if (!list.closest('li > ol')) {
      setOrderedListType(list);
    }
  });

  return doc;
};

export const normalizeHtml = htmlString => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  let formattedHtml = cleanClassesFromHTML(doc);
  formattedHtml = formatNestedLists(formattedHtml);
  formattedHtml = formatCheckLists(formattedHtml);
  formattedHtml = formatOrderedLists(formattedHtml);

  return formattedHtml.body.innerHTML;
};
