import type { DOMConversionMap, LexicalEditor, LexicalNode, NodeKey } from 'lexical';
import { $createParagraphNode, $getRoot, createEditor, DecoratorNode } from 'lexical';
import { InfoBlock } from '../components/InfoBlock';
import type { InfoBlockType } from '../components/InfoBlock/types';
import { $createExtendedTextNode } from './ExtendedTextNode';

export class InfoBlockNode extends DecoratorNode<JSX.Element> {
  type: InfoBlockType;
  text: LexicalEditor;

  constructor(type: InfoBlockType = 'info', text?: LexicalEditor, key?: NodeKey) {
    super(key);
    this.type = type;
    this.text = text || createNestedEditorWithText(type);
  }

  static getType() {
    return 'info-block';
  }

  createDOM(): HTMLElement {
    const element = document.createElement('span');

    element.className = 'info-block';
    element.style.display = 'inline-block';
    element.style.width = '100%';

    return element;
  }

  updateDOM(): false {
    return false;
  }

  setType(type: InfoBlockType): void {
    const writable = this.getWritable();
    writable.type = type;
  }

  decorate() {
    return <InfoBlock type={this.type} editor={this.text} nodeKey={this.getKey()} />;
  }

  exportDOM(): { element: HTMLElement } {
    const textContent = this.text.getEditorState().read(() => {
      const root = $getRoot();
      return root.getTextContent();
    });

    return { element: createHtml(this.type, textContent) };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.classList.contains('info-block')) return null;

        return {
          conversion: $parseInfoBlockElement,
          priority: 2
        };
      }
    };
  }

  exportJSON() {
    return {
      version: 1,
      type: this.type,
      text: this.text.getEditorState().toJSON(),
      nodeType: 'info-block'
    };
  }

  static importJSON(serializedNode): InfoBlockNode {
    const infoBlockNode = new InfoBlockNode(serializedNode.type);

    const caption = serializedNode.text;
    const nestedEditor = infoBlockNode.text;
    const editorState = nestedEditor.parseEditorState(caption.editorState);
    if (!editorState.isEmpty()) {
      nestedEditor.setEditorState(editorState);
    }
    return infoBlockNode;
  }

  static clone(node: InfoBlockNode): InfoBlockNode {
    return new InfoBlockNode(node.type, node.text, node.__key);
  }
}

const $parseInfoBlockElement = domNode => {
  const type = domNode.getAttribute('data-type');
  const text = domNode.getAttribute('data-text');

  if (type) {
    const nestedEditor = createNestedEditorWithText(type, text.trim());

    const node = $createInfoBlockNode(type, nestedEditor);
    return { node };
  }

  return null;
};

export const $createInfoBlockNode = (type: InfoBlockType = 'info', editor?: LexicalEditor): InfoBlockNode => {
  return new InfoBlockNode(type, editor);
};

export const $isInfoBlockNode = (node: LexicalNode | null | undefined): node is InfoBlockNode => {
  return node instanceof InfoBlockNode;
};

export const createNestedEditorWithText = (type: InfoBlockType = 'info', text: string = '') => {
  const editor = createEditor();

  const initialText = text || `This is ${type} block...`;

  editor.update(() => {
    const root = $getRoot();

    root.clear();

    const paragraphNode = $createParagraphNode();
    paragraphNode.append($createExtendedTextNode(initialText));

    root.append(paragraphNode);

    paragraphNode.select();
  });

  return editor;
};

const createHtml = (type: InfoBlockType = 'info', text: string = '') => {
  const element = document.createElement('span');

  element.className = 'info-block';

  element.setAttribute('data-type', type);
  element.setAttribute('data-text', text);

  const blockProperties = getColors(type);

  element.style.display = 'inline-block';
  element.style.width = '100%';
  element.style.padding = '4px';
  element.style.borderRadius = '4px';
  element.style.borderLeft = `4px solid ${blockProperties.highlightColor}`;
  element.style.backgroundColor = blockProperties.bgColor;

  element.textContent = text;

  return element;
};

export const getColors = (type: InfoBlockType) => {
  switch (type) {
    case 'info':
      return {
        bgColor: '#E5F2FF',
        highlightColor: '#0561CF'
      };
    case 'warning':
      return {
        bgColor: '#FFF3E5',
        highlightColor: '#F6880F'
      };
    case 'alert':
      return {
        bgColor: '#FFEBEF',
        highlightColor: '#DA0530'
      };
    case 'success':
      return {
        bgColor: '#EBFFF2',
        highlightColor: '#037A2C'
      };
  }
};
