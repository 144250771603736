import type { InfoBlockType } from '../InfoBlock/types';
import type { BaseSelection, LexicalEditor } from 'lexical';
import { $createParagraphNode, $getSelection, $isRangeSelection } from 'lexical';
import { $createInfoBlockNode, createNestedEditorWithText } from '../../nodes/InfoBlockNode';
import { $createExtendedTextNode } from '../../nodes/ExtendedTextNode';

export const formatInfoBlock = (editor: LexicalEditor, type: InfoBlockType) => {
  editor.update(() => {
    const selection = $getSelection();

    if (selection !== null) {
      if ($isRangeSelection(selection) && !selection.isCollapsed()) {
        const text = selection.getTextContent();
        const nestedEditor = createNestedEditorWithText(type, text);
        insertInfoBlock(selection, type, nestedEditor);
      } else {
        insertInfoBlock(selection, type);
      }
    }
  });
};

const insertInfoBlock = (selection: BaseSelection, type: InfoBlockType, nestedEditor?: LexicalEditor) => {
  const infoBlockNode = $createInfoBlockNode(type, nestedEditor);
  const paragraphNode = $createParagraphNode();
  const spaceNode = $createExtendedTextNode(' ');

  selection.insertNodes([infoBlockNode, spaceNode]);
  infoBlockNode.getParent().insertAfter(paragraphNode);
};
