import type { ComponentProps, ReactNode } from 'react';
import { memo, useCallback } from 'react';

import { COLOR, FONT_SIZE } from '@constants';
import { Icon } from '../Icon';
import { getStyles } from './styles';
import { CTA, CTAType } from '../CTA';
import type { GeneralModel } from '@cyferd/client-engine';

export enum ToastStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  WARN = 'warning',
  INFO = 'information'
}

export interface ToastProps {
  id: string;
  title?: ReactNode;
  message?: string;
  status: ToastStatus;
  onClose: (id: string) => void;
  testid?: string;
  ctaProps?: Omit<ComponentProps<typeof CTA>, 'type' | 'color'>;
}

export const toastWidth = '350px';

const statusMap = {
  [ToastStatus.ERROR]: { color: 'RD_1', icon: 'cancel', buttonColor: 'HC_17' },
  [ToastStatus.SUCCESS]: { color: 'GN_7', icon: 'check_circle', buttonColor: 'HC_16' },
  [ToastStatus.WARN]: { color: 'OE_3', icon: 'warning', buttonColor: 'HC_6' },
  [ToastStatus.INFO]: { color: 'BE_9', icon: 'info', buttonColor: 'HC_11' }
};

export const Toast = memo(({ id, title, message, onClose, status, testid = 'toast', ctaProps }: ToastProps) => {
  const statusProps = statusMap[status] || statusMap[ToastStatus.INFO];
  const color = statusProps.color;
  const iconName = statusProps.icon;
  const iconColor = COLOR[color];
  const buttonColor = statusProps.buttonColor;
  const styles = getStyles(color, buttonColor, toastWidth);

  const onInternalClose = useCallback(() => onClose(id), [onClose, id]);
  return (
    <div data-testid={`${testid}-container`} role="alert" css={styles.container}>
      <div data-testid={`${testid}-status-${status}`} css={styles.content}>
        <header css={styles.header}>
          <p css={styles.textIconContainer} data-testid={`${testid}-title`}>
            <span css={styles.icon}>
              <Icon size="25px" name={iconName as ComponentProps<typeof Icon>['name']} fill={iconColor} title="toast header close" />
            </span>
            <div css={styles.textContainer}>
              {!!title && <div css={styles.title}>{title}</div>}
              {!!message && (
                <p data-testid={`${testid}-message`} css={styles.message}>
                  {message}
                </p>
              )}
            </div>
          </p>
          <div css={styles.closeBtn}>
            <Icon name="close" size={FONT_SIZE.L} fill={COLOR.NEUTRAL_1} onClick={onInternalClose} testid={`${testid}-close-btn`} />
          </div>
        </header>
        {!!ctaProps && (
          <div css={styles.cta}>
            <CTA {...ctaProps} type={CTAType.LINK} color={buttonColor as GeneralModel.Color.ThemeColor} />
          </div>
        )}
      </div>
    </div>
  );
});

Toast.displayName = 'Toast';
