import { memo, useRef } from 'react';
import type { Observable } from 'rxjs';

import type { ApiModel, GeneralModel } from '@cyferd/client-engine';

import { ChipList } from '@components/elements/ChipList';
import { CHIP_HEIGHT } from '@components/elements/Chips/Chip/styles';
import { CompactSwitch } from '@components/elements/CompactSwitch';
import { CompactQuickFilters } from './CompactQuickFilters';
import { useQuickFilters } from './useQuickFilters';

export interface QuickFiltersProps {
  config: GeneralModel.FetchCriteria['quickFilters'];
  cursor?: GeneralModel.FetchCriteria;
  schema?: GeneralModel.JSONSchema;
  disabled?: boolean;
  onFetch?: (criteria: GeneralModel.FetchCriteria) => Observable<ApiModel.APIAction>;
  testid?: string;
}

export const QuickFilters = memo(({ config, cursor, schema, disabled, onFetch, testid = 'quick-filters' }: QuickFiltersProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { filters } = useQuickFilters({ cursor, schema, config, onFetch, disabled, containerRef, delay: 1500 });
  if (!filters?.length) return null;
  return (
    <div data-testid={testid} ref={containerRef}>
      <CompactSwitch maxHeight={CHIP_HEIGHT} compact={<CompactQuickFilters quickFilters={filters} />} expanded={<ChipList list={filters} />} />
    </div>
  );
});
