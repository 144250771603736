import { forwardRef, type PropsWithChildren, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { getStyles } from './styles';

import useStyleListener from '@utils/useStyleListener';
import { useBannerData } from '../../../hooks/useBannerData';

export type SidebarProps = PropsWithChildren<{
  open: boolean;
  wide?: boolean;
  verticalOffset?: number;
  verticalOffsetDirection?: 'top' | 'bottom';
  portalId?: string;
  disablePadding?: boolean;
}>;

const SideBarLeft = forwardRef<HTMLDivElement, SidebarProps>(({ open, children, verticalOffset, wide, portalId }, ref) => {
  const { isBannerTop } = useBannerData();
  const { height } = useStyleListener({ dataSelector: { value: 'banner', attribute: 'data-testid' }, stylesToWatch: ['height'] });
  const castHeight = Number.parseInt(height, 10) || 0;
  const styles = useMemo(
    () => getStyles({ verticalOffset: verticalOffset || castHeight, verticalOffsetDirection: isBannerTop ? 'top' : 'bottom' }),
    [verticalOffset, isBannerTop, castHeight]
  );

  const content = (
    <div css={[styles.paneLeft, !!open && styles.paneOpenLeft, open && wide && styles.wideLeft]} data-testid="paneLeft" ref={ref}>
      {children}
    </div>
  );

  if (portalId) {
    const portalElement = document.getElementById(portalId);
    if (portalElement) {
      return ReactDOM.createPortal(content, portalElement);
    }
  }

  return content;
});

export const Sidebar = ({ open, wide, children, verticalOffset, verticalOffsetDirection, portalId, disablePadding = false }: SidebarProps) => {
  const { isBannerTop } = useBannerData();
  const { height } = useStyleListener({ dataSelector: { value: 'banner', attribute: 'data-testid' }, stylesToWatch: ['height'] });
  const castHeight = Number.parseInt(height, 10) || 0;
  const verticalOffsetDefault = useMemo(() => verticalOffset || castHeight, [verticalOffset, castHeight]);
  const verticalOffsetDirectionDefault = useMemo(
    () => (verticalOffsetDirection ? verticalOffsetDirection : isBannerTop ? 'top' : 'bottom'),
    [verticalOffsetDirection, isBannerTop]
  );
  const styles = useMemo(
    () => getStyles({ verticalOffset: verticalOffsetDefault, verticalOffsetDirection: verticalOffsetDirectionDefault }),
    [verticalOffsetDirectionDefault, verticalOffsetDefault]
  );

  const content = (
    <div css={[styles.pane(disablePadding), !!open && styles.paneOpen, !!open && !!wide && styles.wide]} data-testid="pane">
      {children}
    </div>
  );

  if (portalId) {
    const portalElement = document.getElementById(portalId);
    if (portalElement) {
      return ReactDOM.createPortal(content, portalElement);
    }
  }

  return content;
};

Sidebar.FromLeft = SideBarLeft;
