import { CTAType } from '@components/elements/CTA';
import { IconImage } from '@components/elements/Icon/renderIcon';
import type { IOptionListItem } from '@components/elements/OptionMenu';
import { OptionMenu } from '@components/elements/OptionMenu';
import { PreventClickPropagation } from '@components/elements/PreventClickPropagation';
import { ToolTip } from '@components/elements/Tooltip';
import { COLOR, FONT_SIZE, FOREGROUND_COLOR } from '@constants';
import { ViewModel } from '@cyferd/client-engine';
import { useRef, useState } from 'react';
import { styles } from './styles';
import type { INavigationItem } from '@components/platform/GlobalHeader/types';
import { styleHelpers } from '@utils/styleHelpers';
import { HighlightedParagraph } from '../../../HighlightedParagraph';

export interface ItemMenuProps extends INavigationItem {
  optionList?: IOptionListItem[];
  isActive: boolean;
  isOpen: boolean;
  testid?: string;
  onToggle: (id: string) => void;
  onClick: (payload?) => void;
  isShowMore: boolean;
  containChildren: boolean;
  searchQuery?: string;
}

export const ItemMenu = ({
  recordTitle,
  recordImage,
  optionList,
  onToggle,
  isActive,
  isOpen,
  isShowMore,
  onClick,
  testid = '',
  containChildren,
  id,
  recordColor,
  searchQuery
}: ItemMenuProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const showChevron = isOpen && containChildren;

  return (
    <div
      data-testid={testid || 'ItemMenu'}
      css={[styles.container, styleHelpers.flexCenter]}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        ref={containerRef}
        css={[
          styles.optionContent,
          isOpen && styles.optionContentOpen,
          isActive && styles.optionContentActive,
          isOpen && isActive && styles.openOptionContentActive
        ]}
        onClick={isOpen ? onClick : undefined}
      >
        {(!isOpen || (isOpen && !isHovered)) && (
          <ToolTip text={!isOpen ? `${recordTitle}` : ''} side="right">
            <div
              css={[styles.wrapperIcon, isOpen && styles.wrapperIconOpen, isHovered && styles.hoveredMenuItem, isActive && styles.activeApp(recordColor)]}
              data-selector="wrapperIcon"
            >
              <IconImage
                icon={recordImage || 'apps'}
                title=""
                iconProps={{ size: FONT_SIZE.L, outlined: true, fill: isActive ? FOREGROUND_COLOR[recordColor] : COLOR.NEUTRAL_1 }}
                imageProps={{
                  size: FONT_SIZE.L,
                  css: styles.iconImage
                }}
              />
            </div>
          </ToolTip>
        )}

        {isOpen && isHovered && showChevron && (
          <PreventClickPropagation>
            <div css={[styles.wrapperIcon, isOpen && styles.wrapperIconOpen, styles.chevronWrapper]} onClick={() => onToggle(id)}>
              <IconImage
                icon={isShowMore ? 'expand_more' : 'chevron_right'}
                title=""
                iconProps={{ size: FONT_SIZE.L, outlined: true }}
                imageProps={{ size: FONT_SIZE.L }}
              />
            </div>
          </PreventClickPropagation>
        )}

        {isOpen && (
          <div css={styles.recordTitle}>
            <HighlightedParagraph text={recordTitle} searchQuery={searchQuery} />
          </div>
        )}
        {isOpen && (
          <div css={styles.actionContainer} data-selector="actionContainer">
            <PreventClickPropagation>
              <OptionMenu defaultBtnType={CTAType.ACTION_TERTIARY} defaultBtnSize={ViewModel.CTASize.SMALL} optionList={optionList} maxImportant={0} />
            </PreventClickPropagation>
          </div>
        )}
      </div>
    </div>
  );
};
