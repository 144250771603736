import { useEffect, useRef } from 'react';

export const useOutsideClick = (callback: (e?: MouseEvent) => void, eventType: string = 'click', checkPortals: boolean = false) => {
  const ref = useRef();

  useEffect(() => {
    const onClick = event => {
      if (checkPortals) {
        // portal should have `data-portal` attr to be calculated here
        const portals = document.querySelectorAll('[data-portal]');

        const isInsidePortal = Array.from(portals).some(portal => portal.contains(event.target as Node));

        if (ref.current && !(ref.current as any).contains(event.target) && !isInsidePortal) callback(event);
      } else {
        if (ref.current && !(ref.current as any).contains(event.target)) callback(event);
      }
    };

    document.addEventListener(eventType, onClick);
    return () => {
      document.removeEventListener(eventType, onClick);
    };
  }, [callback, eventType, ref, checkPortals]);

  return ref;
};
