import { CollectionModel } from '@cyferd/client-engine';

export type BuilderQuickFilter = CollectionModel.QuickFilter & {
  stringByDefault?: string;
  numberByDefault?: number;
  multipleStringByDefault?: string[];
  multipleNumberByDefault?: number[];
};

export const formatOptionFieldQuickFilter = (filter: BuilderQuickFilter, schema: CollectionModel.Collection['schema']): CollectionModel.QuickFilter => {
  const { optionListType, fieldId, type } = filter;
  const isMultiple = optionListType === CollectionModel.QuickFilterOptionListType.MULTIPLE;
  const fieldType = schema?.properties?.[fieldId]?.type;
  const isString = fieldType === 'string';
  const isNumber = fieldType === 'number';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { stringByDefault, numberByDefault, multipleStringByDefault, multipleNumberByDefault, ...cleanFilter } = filter;

  const hasValidDefault =
    (isMultiple && isString && filter.multipleStringByDefault?.length) ||
    (isMultiple && isNumber && filter.multipleNumberByDefault?.length) ||
    (!isMultiple && isString && filter.stringByDefault) ||
    (!isMultiple && isNumber && filter.numberByDefault);

  if (type !== CollectionModel.QuickFilterType.FIELD || !hasValidDefault) return cleanFilter;

  const defaultFilter = isMultiple
    ? {
        [fieldId]: {
          $in: isString ? filter.multipleStringByDefault : filter.multipleNumberByDefault
        }
      }
    : {
        [fieldId]: {
          $eq: isString ? filter.stringByDefault : filter.numberByDefault
        }
      };

  return { ...cleanFilter, defaultFilter };
};
