import { type PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';

import { ClientEngineContext, ErrorBoundary, GeneralModel, useUnmountObservable } from '@cyferd/client-engine';

import { HasFlagRenderer } from '@components/elements/HasFlagRenderer';
import { Search, type SearchProps } from '@components/elements/Search';
import { UIContext } from '@components/providers/UIprovider';
import { FLAGS } from '@constants';
import { type Storage, useGetElementSize } from '@utils';
import { Banner } from '../Banner';
import { GlobalToolbar } from './components/GlobalToolbar';
import { NavigationMenu } from './components/NavigationMenu';
import { useGlobalHeaderFetcher } from './hooks/useGlobalHeaderFetcher';
import { LegacyGlobalHeader } from './LegacyGlobalHeader';
import { Print } from './Print';
import { Shortlink } from './Shortlink';
import { compactStyles, styles } from './styles';
import { ViewUsageInApps } from './ViewUsageInApps';

export const MENU_WIDTH = '300px';
export const CLOSED_MENU_WIDTH = '50px';

type GlobalHeaderProps = PropsWithChildren<{
  hidden?: boolean;
  searchMenu?: SearchProps;
  hiddenFavorites?: boolean;
  hiddenSearch?: boolean;
  hiddenShortlink?: boolean;
  hiddenNotifications?: boolean;
  hiddenPreferences?: boolean;
  hiddenPrint?: boolean;
  storage?: Storage;
}>;

export const GlobalHeader = (props: GlobalHeaderProps) => (
  <HasFlagRenderer NewComponent={GlobalHeaderCompo} flag={FLAGS.WIP} LegacyComponent={LegacyGlobalHeader} {...props} />
);

export const GlobalHeaderCompo = ({
  children,
  hidden,
  searchMenu,
  hiddenSearch,
  hiddenShortlink,
  hiddenFavorites,
  hiddenNotifications,
  hiddenPreferences,
  hiddenPrint
}: GlobalHeaderProps) => {
  const [isLeftPanelOpen, setIsLeftPanelOpen] = useState(false);
  const { useUserSelector } = useContext(ClientEngineContext);
  const { size } = useContext(UIContext);
  const onDestroy$ = useUnmountObservable();
  const { isLoading, apps, recentViews } = useGlobalHeaderFetcher(onDestroy$);
  const user = useUserSelector();
  const isAdmin = !!user?.roles?.length;
  const isSmallScreen = () => window.matchMedia('(max-width: 768px)').matches;
  const banner = useMemo(() => user?.tenant?.banner ?? {}, [user?.tenant?.banner]);
  const bannerTop = banner?.position === 'top' || isSmallScreen();
  const { ref: bannerRef, height: bannerHeight } = useGetElementSize();

  const toggleLeftPanel = useCallback(() => setIsLeftPanelOpen(prevState => !prevState), []);

  const renderBanner = useCallback(() => {
    return <Banner {...banner} ref={bannerRef} />;
  }, [banner, bannerRef]);

  return (
    <>
      {!hidden && (
        <>
          {renderBanner()}
          <header
            css={styles.header}
            data-testid="global-header"
            data-no-print={true}
            style={{ top: bannerTop ? bannerHeight : 0, left: isLeftPanelOpen ? '300px' : '50px', width: `calc(100% - ${isLeftPanelOpen ? '300px' : '50px'})` }}
          >
            <div css={styles.container}>
              <div css={styles.section}>
                <NavigationMenu
                  isLoading={isLoading}
                  data={apps}
                  user={user}
                  isOpen={isLeftPanelOpen}
                  recentViews={recentViews}
                  onOpenChange={toggleLeftPanel}
                  verticalOffset={bannerHeight}
                  verticalOffsetDirection={bannerTop ? 'top' : 'bottom'}
                />
              </div>

              <div
                css={[
                  styles.section,
                  styles.viewUsageInApps,
                  size === GeneralModel.AppSize.M && styles.viewUsageInAppsMediumScreen,
                  size === GeneralModel.AppSize.L && styles.viewUsageInAppsLargeScreen
                ]}
              >
                <ViewUsageInApps />
              </div>
              <div
                css={[
                  styles.section,
                  styles.searchSection,
                  size === GeneralModel.AppSize.M && styles.searchSectionMediumScreen,
                  size === GeneralModel.AppSize.L && styles.searchSectionLargeScreen
                ]}
              >
                <ErrorBoundary>
                  {!hiddenSearch && <Search {...searchMenu} compact={size === GeneralModel.AppSize.S} expandCtaProps={{ color: 'HC_1' }} />}
                </ErrorBoundary>
                {size === GeneralModel.AppSize.S && <div css={styles.division} />}
              </div>

              <div css={styles.section}>
                {size === GeneralModel.AppSize.L && (
                  <>
                    <ErrorBoundary>{!hiddenShortlink && <Shortlink />}</ErrorBoundary>
                    <ErrorBoundary>{!hiddenPrint && <Print />}</ErrorBoundary>
                    <div css={styles.division} />
                  </>
                )}
                <GlobalToolbar
                  verticalOffset={bannerHeight}
                  hiddenFavorites={hiddenFavorites}
                  hiddenNotifications={hiddenNotifications}
                  hiddenPreferences={hiddenPreferences}
                  hiddenShortlink={hiddenShortlink}
                  hiddenPrint={hiddenPrint}
                  verticalOffsetDirection={bannerTop ? 'top' : 'bottom'}
                />
              </div>
            </div>
          </header>
          <div css={styles.placeholder} />
        </>
      )}
      <div
        css={[isAdmin && styles.pinnedMainNav, isAdmin && isLeftPanelOpen && styles.pinnedOpenMainNav]}
        style={{ paddingBottom: bannerTop ? 0 : bannerHeight, paddingTop: bannerTop ? bannerHeight : 0 }}
      >
        {children}
      </div>
    </>
  );
};

// TODO: This will be the main Component remove the rest after the feature
export const CompactGlobalHeader = ({
  searchMenu,
  hiddenSearch,
  hiddenShortlink,
  hiddenFavorites,
  hiddenNotifications,
  hiddenPreferences,
  hiddenPrint,
  bannerHeight
}) => {
  const { size } = useContext(UIContext);
  return (
    <header css={compactStyles.header} style={{ position: 'relative' }} data-testid="global-header" data-no-print={true}>
      <div css={compactStyles.container}>
        <div
          css={[
            compactStyles.section,
            compactStyles.viewUsageInApps,
            size === GeneralModel.AppSize.M && compactStyles.viewUsageInAppsMediumScreen,
            size === GeneralModel.AppSize.L && compactStyles.viewUsageInAppsLargeScreen
          ]}
        >
          <ViewUsageInApps />
        </div>
        <div css={[compactStyles.section, compactStyles.searchSection]}>
          <ErrorBoundary>
            {!hiddenSearch && <Search {...searchMenu} compact={size === GeneralModel.AppSize.S} expandCtaProps={{ color: 'HC_1' }} />}
          </ErrorBoundary>
          {size === GeneralModel.AppSize.S && <div css={compactStyles.division} />}
        </div>

        <div css={[compactStyles.section, compactStyles.tools, size === GeneralModel.AppSize.S && compactStyles.toolsSectionSmallScreen]}>
          {size === GeneralModel.AppSize.L && (
            <>
              <ErrorBoundary>{!hiddenShortlink && <Shortlink />}</ErrorBoundary>
              <ErrorBoundary>{!hiddenPrint && <Print />}</ErrorBoundary>
              <div css={compactStyles.division} />
            </>
          )}
          <GlobalToolbar
            verticalOffset={bannerHeight}
            hiddenFavorites={hiddenFavorites}
            hiddenNotifications={hiddenNotifications}
            hiddenPreferences={hiddenPreferences}
            hiddenShortlink={hiddenShortlink}
            hiddenPrint={hiddenPrint}
          />
        </div>
      </div>
    </header>
  );
};
