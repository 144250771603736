import { css } from '@emotion/react';
import { CLOSED_MENU_WIDTH, COLOR, CONTAINER_WIDTH, GAP, GLOBAL_HEADER_HEIGHT, MENU_WIDTH } from '@constants';

export const styles = {
  header: css`
    background-color: ${COLOR.BASE_BACKGROUND_ALT_1};
    z-index: 10;
    height: ${GLOBAL_HEADER_HEIGHT};
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
  `,
  placeholder: css`
    height: ${GLOBAL_HEADER_HEIGHT};
    width: 100%;
  `,
  container: css`
    flex: 1;
    padding: 0 ${GAP.M};
    max-width: ${CONTAINER_WIDTH.MAX}px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  section: css`
    display: flex;
    gap: ${GAP.M};
    align-items: center;
    justify-content: center;
  `,
  viewUsageInApps: css`
    margin-left: 10px;
  `,
  viewUsageInAppsMediumScreen: css`
    div {
      max-width: 220px;
    }
  `,
  viewUsageInAppsLargeScreen: css`
    div {
      max-width: 300px;
    }
  `,
  searchSection: css`
    flex-grow: 1;
    padding: 0 ${GAP.M};
  `,
  searchSectionMediumScreen: css`
    padding: 0 50px;
  `,
  searchSectionLargeScreen: css`
    padding: 0 100px;
  `,
  content: css`
    min-height: 100vh;
    padding-bottom: 40px;
  `,
  pinnedMainNav: css`
    padding-left: ${CLOSED_MENU_WIDTH};
  `,
  pinnedOpenMainNav: css`
    padding-left: ${MENU_WIDTH};
  `,
  division: css`
    height: 30px;
    border-left: 1px ${COLOR.NEUTRAL_4} solid;
  `
};

export const compactStyles = {
  header: css`
    background-color: ${COLOR.BASE_BACKGROUND_ALT_1};
    z-index: 10;
    height: ${GLOBAL_HEADER_HEIGHT};
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
  `,
  placeholder: css`
    height: ${GLOBAL_HEADER_HEIGHT};
    width: 100%;
  `,
  container: css`
    flex: 1;
    padding: 0 ${GAP.M};
    max-width: ${CONTAINER_WIDTH.MAX}px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  section: css`
    display: flex;
    gap: ${GAP.M};
    align-items: center;
    justify-content: center;
    flex: 1;
  `,
  viewUsageInAppsMediumScreen: css`
    div {
      max-width: 220px;
    }
  `,
  viewUsageInAppsLargeScreen: css`
    div {
      max-width: 300px;
    }
  `,
  searchSection: css`
    max-width: 400px;
    margin: 0 ${GAP.M};
  `,
  searchSectionMediumScreen: css`
    margin: 0 50px;
  `,
  searchSectionLargeScreen: css`
    margin: 0 100px;
  `,
  tools: css`
    justify-content: flex-end;
  `,
  toolsSectionSmallScreen: css`
    flex: 0;
  `,
  content: css`
    min-height: 100vh;
    padding-bottom: 40px;
  `,
  pinnedMainNav: css`
    padding-left: ${CLOSED_MENU_WIDTH};
  `,
  pinnedOpenMainNav: css`
    padding-left: ${MENU_WIDTH};
  `,
  division: css`
    height: 30px;
    border-left: 1px ${COLOR.NEUTRAL_4} solid;
  `,
  imageContent: css`
    width: 28px;
    height: 28px;
  `
};
