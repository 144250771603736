import { useEffect } from 'react';
import { $getSelection, $isDecoratorNode, $isRangeSelection, KEY_BACKSPACE_COMMAND } from 'lexical';
import { $isExtendedTextNode } from '../nodes/ExtendedTextNode';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

export const BackspacePlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand<KeyboardEvent>(
      KEY_BACKSPACE_COMMAND,
      event => {
        const selection = $getSelection();
        if (!$isRangeSelection(selection)) {
          return false;
        }

        event.preventDefault();

        const nodes = selection.getNodes();

        if (nodes.length !== 1) {
          return false;
        }

        handleDecoratorNodeRemove(nodes);

        return false;
      },
      1
    );
  }, [editor]);

  return null;
};

const handleDecoratorNodeRemove = nodes => {
  const node = nodes[0];
  const sibling = node.getPreviousSibling();

  if ($isExtendedTextNode(node) && node.getTextContent() === ' ' && $isDecoratorNode(sibling)) {
    sibling.remove();
  }
};
