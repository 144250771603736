import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Subject, takeUntil, tap, EMPTY } from 'rxjs';
import type { ApiModel, GeneralModel } from '@cyferd/client-engine';
import {
  createUUID,
  isDeepEqual,
  mergeTruthy,
  ofType,
  parseSchemaProperty,
  swallowError,
  useFinalizeWhileMounted,
  useOlderReference,
  useUnmountObservable
} from '@cyferd/client-engine';
import { ToastStatus } from '@components/elements/Toast';
import { isValidCollectionId } from '@utils';
import { actions as uiActions } from '../../../client-app/state-mgmt/ui/actions';
import { CyWrapperContext } from '../../smart/CyWrapper';

export const useCollectionLookupFetch = ({
  disableFreeText,
  collectionId,
  value,
  fetchCriteria,
  fixedFilter,
  onGetSuccess,
  parseData,
  fullValue,
  limit,
  path
}: {
  disableFreeText: boolean;
  collectionId: GeneralModel.EvaluatorFormula<string>;
  value?: string;
  fetchCriteria?: GeneralModel.FetchCriteria;
  fixedFilter?: GeneralModel.FetchCriteria['fixedFilter'];
  onGetSuccess: (response: any) => any;
  parseData: GeneralModel.ParseDataFn;
  fullValue: ApiModel.ApiRecord;
  limit: number;
  path?: string[];
}) => {
  const dispatch = useDispatch();
  const shouldUseOldBaseCursor = useOlderReference();
  const { useAction } = useContext(CyWrapperContext);
  const finalize = useFinalizeWhileMounted();

  const onDataList = useAction('dataList');

  const onDestroy$ = useUnmountObservable();
  const onCancel$ = useRef(new Subject<void>());

  const [isLoadingList, setLoadingList] = useState<boolean>(false);
  const [isLoadingRecord, setLoadingRecord] = useState<boolean>(false);
  const [listRes, setListRes] = useState<ApiModel.ApiValue>(undefined);
  const allItemsRetrieved = useMemo(() => !!(listRes?.list?.length - (listRes?.query?.cursor?.options?.skip || 0) < limit), [limit, listRes]);

  const parseCriteria = useCallback(
    (criteria: any) =>
      parseSchemaProperty(criteria, {
        parseData,
        fullItem: fullValue,
        path: path?.join?.('.'),
        definition: null,
        value: collectionId
      }),
    [collectionId, fullValue, parseData, path]
  );

  const parsedBaseCursor: GeneralModel.FetchCriteria = useMemo(
    () => mergeTruthy(parseCriteria({ collectionId }), parseCriteria(fetchCriteria)),
    [collectionId, fetchCriteria, parseCriteria]
  );

  const baseCursor = shouldUseOldBaseCursor(parsedBaseCursor, (o, n) => !isDeepEqual(o, n));

  const onGet = useCallback(() => {
    if (!isValidCollectionId(baseCursor?.collectionId) || !isValidCollectionId(value)) return;
    setLoadingRecord(true);
    onDataList({
      query: { cursor: { collectionId: baseCursor?.collectionId, id: value } }
    })
      .pipe(
        takeUntil(onDestroy$),
        ofType('DISPATCH.RESULT'),
        tap(d => {
          if (!d.list[0] && disableFreeText) {
            dispatch(
              uiActions.addToast(
                {
                  id: createUUID(),
                  status: ToastStatus.ERROR,
                  title: 'Not found',
                  message: "We couldn't find the record and this field doesn't support manual inputs"
                },
                {
                  cursor: {
                    collectionId: baseCursor?.collectionId,
                    id: value
                  }
                }
              )
            );
            return EMPTY;
          }

          onGetSuccess({ record: d.list[0], query: d.query });
        }),
        swallowError(),
        finalize(() => setLoadingRecord(false))
      )
      .subscribe();
  }, [baseCursor?.collectionId, disableFreeText, dispatch, finalize, onDataList, onDestroy$, onGetSuccess, value]);

  const onList = useCallback(
    (searchString: string, loadMore?: boolean) => {
      if (!isValidCollectionId(baseCursor?.collectionId)) return;
      if (!loadMore) setListRes(undefined);
      setLoadingList(true);
      onDataList({
        query: {
          cursor: { ...baseCursor, searchString, fixedFilter, options: { limit, ...baseCursor.options, skip: loadMore ? listRes?.list?.length : 0 } }
        }
      })
        .pipe(
          takeUntil(onDestroy$),
          takeUntil(onCancel$.current),
          ofType('DISPATCH.RESULT'),
          tap(response => setListRes(loadMore ? { ...response, list: [...listRes?.list, ...response.list] } : response)),
          finalize(() => setLoadingList(false)),
          swallowError()
        )
        .subscribe();
    },
    [baseCursor, onDataList, fixedFilter, limit, listRes?.list, onDestroy$, finalize]
  );

  return {
    isLoadingList,
    isLoadingRecord,
    allItemsRetrieved,
    baseCursor,
    onGet,
    onList,
    listRes,
    setListRes,
    onCancel$
  };
};
