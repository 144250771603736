import { styles } from './styles';
import { Builder } from './components/Builder';
import { Apps } from './components/Apps';
import type { INavigationList, NavigationMenuProps } from '@components/platform/GlobalHeader/types';
import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BUILDER_ID, DEV_TOOLS_ID, RECENT_VIEWS_ID } from './constants';
import { RecentViews } from './components/RecentViews';
import { DevTools } from './components/DevTools';
import { NoSearchResultsPlaceholder } from '../NavigationMenuSearch';

interface NavigationMenuContentProps {
  isOpen: boolean;
  data: INavigationList;
  recentViews: NavigationMenuProps['recentViews'];
  isAdmin: boolean;
}

export const NavigationMenuContent = ({ isOpen, data, recentViews, isAdmin }: NavigationMenuContentProps) => {
  const { pathname, search } = useLocation();

  const isBuilderActive = pathname === '/ADMIN' || pathname.split('/')[1] === 'builder';
  const isAppActive = useCallback(
    (appId: string) => {
      const id = new URLSearchParams(search).get('id');
      return pathname === '/APP_HOME' && appId === id;
    },
    [pathname, search]
  );
  const getActiveId = () => {
    if (isBuilderActive) return BUILDER_ID;

    const activeAppId = data.find(item => isAppActive(item.id))?.id;

    if (activeAppId) return activeAppId;

    return null;
  };
  const [openId, setOpenId] = useState<string | null>(getActiveId());

  const onToggleItem = useCallback((id: string) => {
    setOpenId(prevId => (prevId === id ? null : id));
  }, []);

  return (
    <>
      <div css={[styles.content]} data-testid="AppNavigationWrapper">
        <NoSearchResultsPlaceholder />

        {isAdmin && <Builder isOpen={isOpen} onToggle={onToggleItem} isBuilderActive={isBuilderActive} openId={openId} />}

        <Apps isOpen={isOpen} data={data} openId={openId} setOpenId={setOpenId} isAppActive={isAppActive} />
      </div>

      <div css={styles.footer}>
        <div css={styles.divider} />
        <RecentViews recentViews={recentViews} isOpen={isOpen} isShowMore={RECENT_VIEWS_ID === openId} onClick={() => onToggleItem(RECENT_VIEWS_ID)} />
        <DevTools isOpen={isOpen} isShowMore={DEV_TOOLS_ID === openId} onClick={() => onToggleItem(DEV_TOOLS_ID)} />
      </div>
    </>
  );
};
