import { LogLevel } from '@cyferd/client-engine';

import packageJson from '../../package.json';
import { env } from './env.source';

const API_PATH = 'api/';

export const ENV = {
  BUILD_SHA: env.VITE_BUILD_SHA,
  PUBLIC_URL: window.location.origin,
  ENVIRONMENT: env.NODE_ENV,
  IS_TEST: env.NODE_ENV === 'test',
  IS_DEV: env.MODE === 'development',
  CLIENT_ENGINE_VERSION: packageJson.dependencies['@cyferd/client-engine'],
  VERSION: packageJson.version,
  WS_URL: env.VITE_WS_URL || `ws${document.location.origin.replace(/^http/, '')}/${API_PATH}websocket`,
  AUTH_URL: env.VITE_AUTH_URL || `${document.location.origin}/api/login`,
  TOKEN_URL: env.VITE_TOKEN_URL || `${document.location.origin}/api/token`,
  LOGOUT_URL: env.VITE_LOGOUT_URL || `${document.location.origin}/${API_PATH}logout`,
  ADMIN_URL: env.VITE_ADMIN_URL,
  CLIENT_APP_URL: env.VITE_CLIENT_APP_URL,
  FILES_URL: env.VITE_FILES_URL || `${document.location.origin}/${API_PATH}files`,
  WS_TOKEN_TIMEOUT: Number(env.VITE_WS_TOKEN_TIMEOUT),
  WS_REQUEST_TIMEOUT: Number(env.VITE_WS_REQUEST_TIMEOUT),
  TOAST_TIME: Number(env.VITE_TOAST_TIME) || 5000,
  PRIVACY_POLICY_URL: env.VITE_PRIVACY_POLICY || 'https://cyferd.com/about/PrivacyPolicy.html',
  THIRD_PARTY_SOFTWARE_URL: env.VITE_THIRD_PARTY_SOFTWARE || 'https://cyferd.com/about/3rdParty.htm',
  WS_RETRY_DELAY: Number(env.VITE_WS_RETRY_DELAY),
  WS_MAX_RETRIES: Number(env.VITE_WS_MAX_RETRIES),
  DOMAIN: env.VITE_DOMAIN || 'cyferd.cloud',
  APP_LOGGER_HEADER: '[web]',
  EVENT_REPORT_TOKEN: '4845b116c0964c5e935782d6a8031839',
  STORAGE_KEY: {},
  INPUT_DEBOUNCE_TIME: Number(env.VITE_INPUT_DEBOUNCE_TIME),
  LOADING_DEBOUNCE_TIME: Number(env.VITE_LOADING_DEBOUNCE_TIME),
  ENABLED_LOG_LEVEL_LIST: env.VITE_ENABLED_LOG_LEVEL_LIST?.split(',')
    .map(l => LogLevel[l.trim()])
    .filter(Boolean) as LogLevel[],
  LIST_DEFAULT_LIMIT: Number(env.VITE_LIST_DEFAULT_LIMIT),
  INFO_TIMEOUT: Number(env.VITE_INFO_TIMEOUT ?? 2000)
};
