import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';

interface NavigationMenuContextValue {
  toggleSidebarVisibility: () => void;
  toggleSidebarMode: () => void;
  isHidden: boolean;
  isExpanded: boolean;
}

const NavigationMenuContext = createContext<NavigationMenuContextValue | undefined>(undefined);

export const NavigationMenuProvider = ({ children }: { children: ReactNode }) => {
  const [status, setStatus] = useState(SidebarNavStatus.COLLAPSED);
  const [lastStatus, setLastStatus] = useState(SidebarNavStatus.COLLAPSED);

  const isHidden = status === SidebarNavStatus.HIDDEN;
  const isExpanded = status === SidebarNavStatus.EXPANDED;

  useEffect(() => {
    if (status === SidebarNavStatus.HIDDEN) return;

    setLastStatus(status);
  }, [status]);

  const toggleSidebarMode = useCallback(() => {
    const newStatus = status === SidebarNavStatus.EXPANDED ? SidebarNavStatus.COLLAPSED : SidebarNavStatus.EXPANDED;

    setStatus(newStatus);
  }, [status]);

  const toggleSidebarVisibility = useCallback(() => {
    const newStatus = status === SidebarNavStatus.HIDDEN ? lastStatus : SidebarNavStatus.HIDDEN;

    setStatus(newStatus);
  }, [status, lastStatus]);

  return (
    <NavigationMenuContext.Provider value={{ toggleSidebarVisibility, toggleSidebarMode, isHidden, isExpanded }}>{children}</NavigationMenuContext.Provider>
  );
};

export const useNavigationMenu = () => {
  const context = useContext(NavigationMenuContext);

  if (!context) {
    throw new Error('useNavigationMenu must be used within a NavigationMenuProvider');
  }

  return context;
};

enum SidebarNavStatus {
  HIDDEN = 'hidden',
  COLLAPSED = 'collpased',
  EXPANDED = 'expanded'
}
