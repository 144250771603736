import { styleHelpers } from '@utils/styleHelpers';
import { styles } from './styles';
import { Icon } from '@components/elements/Icon';
import { COLOR } from '@constants';
import React, { useEffect, useState } from 'react';
import { SearchInput } from '@components/elements/SearchInput';
import { useNavigationMenu } from '../../../../context/NavigationMenuContext';
import { useOutsideClick } from '@utils';
import { useSearchContext } from '@components/platform/MainLayout/context/SearchContext';

// TODO: use memo here ?
export const NavigationMenuSearch = () => {
  const [isSearchVisible, setIsSearchVisible] = useState(false);

  const { isHidden, isExpanded } = useNavigationMenu();
  const { searchQuery, onSearch } = useSearchContext();

  const ref = useOutsideClick(() => {
    setTimeout(() => onSearch(''), 500);
    setIsSearchVisible(false);
  }, 'mousedown');

  useEffect(() => {
    if (isHidden || !isExpanded) setIsSearchVisible(false);
  }, [isHidden, isExpanded]);

  if (!isExpanded) return;

  return (
    <>
      {isSearchVisible && (
        <div css={styles.searchInputWrapper} ref={ref}>
          <SearchInput value={searchQuery} placeholder="" autoFocus={true} onChange={onSearch} searchDelay={300} />
        </div>
      )}
      {!isSearchVisible && (
        <div css={[styleHelpers.flexCenter, styles.searchIconWrapper]} onClick={() => setIsSearchVisible(true)}>
          <Icon name="search" size="24px" fill={COLOR.NEUTRAL_1} />
        </div>
      )}
    </>
  );
};

export const NoSearchResultsPlaceholder = () => {
  const { searchQuery, isSomeResults } = useSearchContext();

  if (isSomeResults || !searchQuery) return null;

  return (
    <div css={styles.noSearchPlaceholder}>
      <p css={styles.noSearchParagraph}>
        No results for '<span css={styles.boldSearch}>{searchQuery}</span>'
      </p>
    </div>
  );
};
