import type { ComponentProps } from 'react';
import React, { useCallback, useMemo } from 'react';
import { isEmpty } from 'lodash';
import type { AssociationFilterOutput, parseSchemaForFilters } from '@cyferd/client-engine';
import { ErrorBoundary, FilterModel, Translate, ViewModel, deepClone, removeKeyList } from '@cyferd/client-engine';

import { BaseForm } from '../../smart/CyForm/components/BaseForm';
import { CTA, CTAType } from '../CTA';
import type { IOptionListItem } from '../OptionMenu';
import { OptionMenu } from '../OptionMenu';
import { PreventClickPropagation } from '../PreventClickPropagation';
import { FilterRow } from './components/FilterRow';
import { styles } from './styles';
import { InlineDropdown } from '../InlineDropdown';
import { Icon } from '../Icon';
import { COLOR, TRANS } from '@constants';

export interface AdvancedFilterProps {
  value: FilterModel.AdvancedFilterValue;
  propOptionList: ReturnType<typeof parseSchemaForFilters>['propOptionList'];
  flatPropDefinitionMap: ReturnType<typeof parseSchemaForFilters>['flatPropDefinitionMap'];
  associationFilterList: AssociationFilterOutput[];
  delegateOptionsToValueInput?: boolean;
  onChange: (value: FilterModel.AdvancedFilterValue) => void;
  renderSchemaForm?: ComponentProps<typeof FilterRow>['renderSchemaForm'];
  disabled?: boolean;
  deepLevel?: number;
  headerOptions?: ComponentProps<typeof OptionMenu>['optionList'];
  displayLabel?: boolean;
  allowFormula?: boolean;
}

const isOperatorValid = (operator: string): operator is keyof FilterModel.AdvancedFilterValue => {
  return operator === '$and' || operator === '$or';
};

export const AdvancedFilter = ({
  value = {},
  propOptionList = [],
  flatPropDefinitionMap = {},
  associationFilterList = [],
  delegateOptionsToValueInput,
  onChange,
  renderSchemaForm = props => <BaseForm {...props} />,
  disabled,
  deepLevel = 0,
  headerOptions,
  displayLabel,
  allowFormula
}: AdvancedFilterProps) => {
  const testid = 'advanced-filter';

  const logicOperator = Object.keys(value).find(k => FilterModel.logicOpList.includes(k as FilterModel.LogicOperator)) as FilterModel.LogicOperator;
  const filterList: FilterModel.AdvancedFilterValue[] = useMemo(
    () => (Array.isArray((value as FilterModel.AdvancedFilterValue)[logicOperator]) ? (value as FilterModel.AdvancedFilterValue)[logicOperator] : []),
    [logicOperator, value]
  );

  const onSafeChange = useCallback(
    (
      op: FilterModel.LogicOperator = FilterModel.logicOpList[0],
      list: (FilterModel.FilterRowValue | FilterModel.FilterRowAssociatedValue | FilterModel.AdvancedFilterValue)[]
    ) => {
      const finalValue = list.length > 0 ? { [op]: list } : {};
      const innerValue: Record<string, any> = { ...removeKeyList(value, FilterModel.logicOpList as unknown as string[]), ...finalValue };
      onChange(isEmpty(innerValue) && deepLevel > 0 ? undefined : innerValue);
    },
    [onChange, value, deepLevel]
  );

  const onLogicOperatorChange = useCallback((op: FilterModel.LogicOperator) => onSafeChange(op, filterList), [filterList, onSafeChange]);
  const onAddCondition = useCallback(() => onSafeChange(logicOperator, [...filterList, {}]), [filterList, logicOperator, onSafeChange]);
  const onAddGroup = useCallback(() => onSafeChange(logicOperator, [...filterList, { [logicOperator]: [{}] }]), [filterList, logicOperator, onSafeChange]);
  const color = logicOperator === '$and' ? 'BRAND_1' : 'BRAND_2';

  // if not schema
  if (!Object.keys(flatPropDefinitionMap).length) return null;

  // if no fields to filter by
  if (!propOptionList?.length && !associationFilterList?.length) return null;

  return (
    <div css={styles.wrapper({ color, evenColor: deepLevel % 2 !== 0 })} data-testid={`${testid}-container`}>
      {!deepLevel && !!displayLabel && (
        <div css={styles.labelContainer}>
          <Icon name="filter_alt" size="24px" fill={COLOR.NEUTRAL_1} />
          <div css={styles.label}>
            <Translate>{TRANS.client.buttons.filters}</Translate>
          </div>
        </div>
      )}
      {(!!headerOptions || filterList.length > 1) && (
        <div css={styles.header}>
          <div>
            {filterList.length > 1 && (
              <div css={styles.logicOperatorContainer}>
                <InlineDropdown
                  label=""
                  minWidth={55}
                  onChange={onLogicOperatorChange}
                  value={logicOperator}
                  options={[
                    { value: '$and', label: 'AND', color: 'BRAND_1' },
                    { value: '$or', label: 'OR', color: 'BRAND_2' }
                  ]}
                />
              </div>
            )}
          </div>
          {!!headerOptions && (
            <div css={styles.actionContainer} data-testid={`${testid}-condition-option-btn`}>
              <PreventClickPropagation>
                <OptionMenu defaultBtnType={CTAType.ACTION_SECONDARY} defaultBtnSize={ViewModel.CTASize.SMALL} optionList={headerOptions} maxImportant={0} />
              </PreventClickPropagation>
            </div>
          )}
        </div>
      )}

      <div css={styles.flexColumn}>
        {filterList.filter(Boolean).map((row, index, list) => {
          const operator = Object.keys(row)[0];
          const rowIsArray = Array.isArray(row[operator]);
          const onChildChange = (
            filterRowValue: FilterModel.FilterRowValue | FilterModel.FilterRowAssociatedValue | Partial<FilterModel.AdvancedFilterValue>
          ) =>
            onSafeChange(
              logicOperator,
              list.map(i => (i === row ? filterRowValue : i))
            );
          const onCopy = () => onSafeChange(logicOperator, [...list.slice(0, index), deepClone(row), ...list.slice(index, list.length)]);
          const onRemove = () =>
            onSafeChange(
              logicOperator,
              list.filter((_, innerIdx) => index !== innerIdx)
            );

          const fullRenderSchemaForm: typeof renderSchemaForm = props => {
            const getOptionMenu = () =>
              [
                {
                  testid: `${testid}-condition-${index}-copy-btn`,
                  size: ViewModel.CTASize.SMALL,
                  image: 'content_copy',
                  label: TRANS.client.buttons.duplicate,
                  onClick: onCopy
                },
                {
                  testid: `${testid}-condition-${index}-remove-btn`,
                  size: ViewModel.CTASize.SMALL,
                  image: 'delete',
                  label: TRANS.client.buttons.remove,
                  color: 'RD_3',
                  onClick: onRemove
                }
              ] as IOptionListItem[];
            return renderSchemaForm(
              delegateOptionsToValueInput ? { ...props, getOptionMenu, id: `${index}-${deepLevel}` } : { ...props, id: `${index}-${deepLevel}` }
            );
          };

          if (rowIsArray && isOperatorValid(operator)) {
            return (
              <ErrorBoundary key={`${index}-${deepLevel}`}>
                <div css={styles.flexRow}>
                  <AdvancedFilter
                    renderSchemaForm={renderSchemaForm}
                    disabled={disabled}
                    delegateOptionsToValueInput={delegateOptionsToValueInput}
                    value={row}
                    associationFilterList={associationFilterList}
                    propOptionList={propOptionList}
                    flatPropDefinitionMap={flatPropDefinitionMap}
                    onChange={onChildChange}
                    deepLevel={deepLevel + 1}
                    allowFormula={allowFormula}
                    headerOptions={[
                      {
                        testid: `${testid}-condition-${index}-remove-btn`,
                        important: false,
                        type: CTAType.ACTION_SECONDARY,
                        image: 'delete',
                        color: 'RD_3',
                        label: TRANS.client.buttons.removeGroup,
                        size: ViewModel.CTASize.SMALL,
                        onClick: onRemove
                      }
                    ]}
                  />
                </div>
              </ErrorBoundary>
            );
          }

          return (
            <ErrorBoundary key={`${index}-${deepLevel}`}>
              <div css={styles.columnConditionContainer} data-selector="FilterRow">
                <FilterRow
                  disabled={disabled}
                  testid={`${testid}-condition-${index}`}
                  value={row as FilterModel.FilterRowValue | FilterModel.FilterRowAssociatedValue}
                  onChange={onChildChange}
                  propOptionList={propOptionList}
                  flatPropDefinitionMap={flatPropDefinitionMap}
                  associationFilterList={associationFilterList}
                  renderSchemaForm={fullRenderSchemaForm}
                  allowFormula={allowFormula}
                />
                {!delegateOptionsToValueInput && (
                  <>
                    <div css={styles.actionContainer}>
                      <CTA
                        testid={`${testid}-condition-${index}-copy-btn`}
                        icon="content_copy"
                        type={CTAType.ACTION_SECONDARY}
                        tooltip={TRANS.client.buttons.duplicate}
                        onClick={onCopy}
                        size={ViewModel.CTASize.SMALL}
                      />
                    </div>
                    <div css={styles.actionContainer}>
                      <CTA
                        testid={`${testid}-condition-${index}-remove-btn`}
                        icon="delete"
                        type={CTAType.ACTION_SECONDARY}
                        tooltip={TRANS.client.buttons.remove}
                        onClick={onRemove}
                        color="RD_3"
                        size={ViewModel.CTASize.SMALL}
                      />
                    </div>
                  </>
                )}
              </div>
            </ErrorBoundary>
          );
        })}
        <div css={styles.addConditionContainer}>
          <CTA
            testid={`${testid}-add-btn-${deepLevel}`}
            size={ViewModel.CTASize.SMALL}
            icon={{ position: 'start', name: 'add' }}
            label={TRANS.client.buttons.addField}
            type={CTAType.LINK}
            onClick={onAddCondition}
          />
          <CTA
            testid={`${testid}-add-group-btn-${deepLevel}`}
            size={ViewModel.CTASize.SMALL}
            icon={{ position: 'start', name: 'library_add' }}
            label={TRANS.client.buttons.addGroup}
            type={CTAType.LINK}
            onClick={onAddGroup}
          />
        </div>
      </div>
    </div>
  );
};
