import { useCyActions } from '@utils';

export const useGetNavigateTo = () => {
  const { onDispatchNavigateTo } = useCyActions();

  const getNavigateTo =
    (payload: Parameters<typeof onDispatchNavigateTo>[0], eventOverride?: Parameters<typeof onDispatchNavigateTo>, callback?) =>
    (event: Parameters<typeof onDispatchNavigateTo>) => {
      callback?.();
      return onDispatchNavigateTo(payload, { ...event, ...eventOverride });
    };

  return { getNavigateTo };
};
