import { css } from '@emotion/react';
import { GAP } from '@constants';

export const styles = {
  list: css`
    display: flex;
    flex-direction: column;
  `,
  content: css`
    [data-selector='cta-label'] {
      text-decoration: none;
    }
    button {
      gap: ${GAP.M};
    }
  `,
  contentContainer: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.L};
  `
};
