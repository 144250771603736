import { CTAType } from '@components/elements/CTA';
import { ENV } from '@constants';
import { Translate, useTranslate } from '@cyferd/client-engine';
import { useOutsideClick, useSafeState } from '@utils';
import { isValidElement, useCallback, useEffect, useId, useRef } from 'react';
import { createPortal } from 'react-dom';
import { usePopover } from '../../../client-app/state-mgmt/zustand/popoverProvider';
import { OptionMenu } from '../OptionMenu';
import { PreventClickPropagation } from '../PreventClickPropagation';
import { RichText } from '../RichTextEditor';
import { styles } from './styles';
import type { IDialog, IPopover, IPopoverTrigger } from './types';
import { ArrowPosition } from './types';
import { useCalculateCoords } from './useCalculateCoords';

export const POPOVER_PORTAL_ID = 'popover-portal-container';

export const Dialog = ({ dialogRef, value, onClose, coords, label, testId, optionList, color, timeout = ENV.INFO_TIMEOUT }: IDialog) => {
  const { translate } = useTranslate();
  const dialogInnerRef = useOutsideClick(onClose);
  const timerRef = useRef<NodeJS.Timeout>();

  const { top = 0, left = 0, arrowPosition = ArrowPosition.TOP_LEFT } = coords || {};
  const shouldShow = top > 0 && left > 0;

  const onMouseLeave = useCallback(
    (e: React.MouseEvent<HTMLDialogElement>) => {
      timerRef.current = setTimeout(() => {
        if (onClose) {
          onClose(e);
        }
      }, timeout);
    },
    [onClose, timeout]
  );

  const onMouseEnter = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  const output = (
    <dialog
      css={styles.dialog({ top, left, shouldShow, arrowPosition, color })}
      data-testid={testId}
      open={true}
      ref={dialogInnerRef}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
    >
      <div css={styles.dialogWrapper({ arrowPosition, color })} ref={dialogRef}>
        <div css={styles.close}>
          <OptionMenu defaultBtnType={CTAType.LINK} defaultBtnColor="NEUTRAL_2" optionList={optionList} />
        </div>
        <div css={styles.dialogContent}>
          {!!label && (
            <p css={styles.label}>
              <Translate>{label}</Translate>
            </p>
          )}
          {isValidElement(value) ? value : <RichText value={translate(value)} />}
        </div>
      </div>
    </dialog>
  );

  /** used to avoid z-index issues when popover is contained in absoulte containers. Leaving conditional to facilitate testing */
  const container = document.getElementById(POPOVER_PORTAL_ID);

  return <>{container ? createPortal(output, container) : output}</>;
};

export const Popover = ({ value, open, onClose, coords, dialogRef, label, testId = 'popover-dialog', optionList, color }: IPopover) => {
  if (!open) return null;
  return <Dialog value={value} dialogRef={dialogRef} onClose={onClose} coords={coords} label={label} testId={testId} optionList={optionList} color={color} />;
};

export const PopoverTrigger = ({
  label,
  value,
  testId = 'popover-trigger',
  children,
  containerCss,
  contentCss,
  optionList,
  avoidOpenOnClick,
  color,
  timeout = ENV.INFO_TIMEOUT
}: IPopoverTrigger) => {
  const [{ top, left }, setCoords] = useSafeState({});
  const popoverId = useId();
  const closePopover = usePopover(state => state.closePopover);
  const openPopover = usePopover(state => state.openPopover);
  const isActivePopover = usePopover(state => state.popoverIds[popoverId]);
  const hoverTimeoutRef = useRef<NodeJS.Timeout>(null);
  const { dialogRef, coords } = useCalculateCoords({ left, top });

  const toggle = useCallback(() => (isActivePopover ? closePopover() : openPopover(popoverId)), [isActivePopover, closePopover, openPopover, popoverId]);

  const onMouseEnter = useCallback(() => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = null;
    }

    hoverTimeoutRef.current = setTimeout(() => {
      if (!isActivePopover) {
        openPopover(popoverId);
      }
    }, timeout);
  }, [openPopover, timeout, popoverId, isActivePopover]);

  const onMousemove = useCallback(
    (e: React.MouseEvent<HTMLSpanElement>) => {
      if (!isActivePopover) {
        setCoords({ left: e.clientX, top: e.clientY });
      }
    },
    [setCoords, isActivePopover]
  );

  const onMouseLeave = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = null;
    }
  };

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLSpanElement>) => {
      /* istanbul ignore next line */
      if (avoidOpenOnClick) return;
      e.preventDefault();
      setCoords({ left: e.clientX, top: e.clientY });
      toggle();
    },
    [avoidOpenOnClick, setCoords, toggle]
  );

  const onClose = useCallback(
    (e?: MouseEvent | React.MouseEvent<HTMLDialogElement>) => {
      e?.preventDefault?.();
      e?.stopPropagation?.();
      if (isActivePopover) {
        closePopover();
      }
    },
    [closePopover, isActivePopover]
  );

  return (
    <>
      <PreventClickPropagation containerCss={containerCss}>
        <span
          css={[styles.wrapper, contentCss]}
          data-testid={testId}
          onMouseMove={onMousemove}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {children}
        </span>
      </PreventClickPropagation>
      <Popover
        dialogRef={dialogRef}
        coords={coords}
        label={label}
        value={value}
        open={isActivePopover}
        onClose={onClose}
        optionList={optionList}
        color={color}
      />
    </>
  );
};
