import { useCallback, useEffect, useMemo, useState } from 'react';
import { EMPTY } from 'rxjs';

import { GeneralModel, ViewModel, prepareTermForReg, useDebounce } from '@cyferd/client-engine';

import { ToolTip } from '@components/elements/Tooltip';
import { CyText } from '@components/smart/CyText';
import { TRANS } from '@constants';
import { isIcon } from '@utils';
import { CTA, CTAType } from '../../../CTA';
import { Icon } from '../../../Icon/Icon';
import { Image } from '../../../Image';
import { Input } from '../../../Input';
import { Layout } from '../../../Layout';
import { Modal } from '../../../Modal';
import { SearchInput } from '../../../SearchInput';
import { SelectDropdown } from '../../../SelectDropdown';
import { styles } from './styles';

const categoryOptions = [...GeneralModel.iconCategoryList].sort((a, b) => a.localeCompare(b)).map(c => ({ value: c, label: c.replace(/&/g, ' & ') }));

export interface IconImagePickerModalProps {
  label: string;
  description?: string;
  open: boolean;
  onChange: (value: string) => void;
  onClose: () => void;
  value?: string;
  iconOnly?: boolean;
  delay?: number;
}

const findCategoryByValue = (value: string): string => {
  if (!value) return null;
  const iconConfig = GeneralModel.iconConfigMap[value];
  return iconConfig?.categories?.[0] || null;
};

export const IconImagePickerModal = ({ open, ...rest }: IconImagePickerModalProps) => {
  if (!open) return null;
  return <IconImagePickerModalOpen {...rest} />;
};

enum TabOptions {
  ICON = 'Icon',
  IMAGE = 'Image'
}

const IconImagePickerModalOpen = ({ value, label, description, iconOnly, delay, onClose, onChange }: Omit<IconImagePickerModalProps, 'open'>) => {
  const tabList: ViewModel.LayoutTab[] = useMemo(() => [{ title: TabOptions.ICON }, !iconOnly && { title: TabOptions.IMAGE }].filter(Boolean), [iconOnly]);

  const [activeTab, setActiveTab] = useState<string>(TabOptions.ICON);
  const [error, setError] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [category, setCategory] = useState(() => findCategoryByValue(value));
  const debouncedSearchValue = useDebounce(searchValue, delay);

  const [selected, setSelected] = useState<string>(value);

  const iconList = useMemo(
    () =>
      GeneralModel.iconNameList.filter(iconName => {
        const safeIconName = iconName.replace(/_/g, ' ');
        const reg = new RegExp(prepareTermForReg(debouncedSearchValue), 'i');
        return (
          /** matches category or no category */
          (!category || GeneralModel.iconConfigMap[iconName]?.categories.includes(category)) &&
          /** matches icon name or tag */
          (reg.test(safeIconName) || reg.test(iconName) || GeneralModel.iconConfigMap[iconName]?.tags?.some(c => reg.test(c)))
        );
      }),
    [category, debouncedSearchValue]
  );

  const onChangeTab = useCallback(
    (event: string) => {
      const isIconTab = activeTab === TabOptions.ICON;

      setActiveTab(event);
      setError(false);
      setSelected(isIconTab ? (value && !isIcon(value) ? value : '') : value || '');
      setSearchValue('');

      return EMPTY;
    },
    [value, activeTab]
  );

  const closeModal = useCallback(() => {
    onClose();
    setSearchValue('');
    setCategory(null);
  }, [onClose]);

  const onSelect = useCallback(() => {
    onChange(selected);
    closeModal();
  }, [closeModal, onChange, selected]);

  const changeUrl = useCallback((event: string) => {
    setError(false);
    setSelected(event);
  }, []);

  useEffect(() => {
    setSelected(value);
    setCategory(() => findCategoryByValue(value));
    setActiveTab(!!value && !isIcon(value) && !iconOnly ? tabList[1]?.title : tabList[0].title);
  }, [iconOnly, tabList, value]);

  const disabled = !selected || value === selected;

  return (
    <Modal
      title={label}
      description={description}
      icon="add_circle"
      footer={
        <div css={styles.footer}>
          <CTA type={CTAType.SECONDARY} label={TRANS.client.buttons.cancel} onClick={closeModal} />
          <CTA type={CTAType.PRIMARY} disabled={disabled} label="Select" testid="select-icon-button" onClick={onSelect} />
        </div>
      }
      open={true}
      onClose={closeModal}
      testid="icon-picker-modal"
      placeOnTop={true}
    >
      <Layout activeTab={activeTab} onChangeTab={onChangeTab} tabList={tabList} type={ViewModel.LayoutType.TAB}>
        <div>
          <div css={styles.searchContainer}>
            <SelectDropdown
              testid="category"
              emptyLabel="Select a category"
              allowEmpty={true}
              value={category}
              onChange={setCategory}
              options={categoryOptions}
            />
            <div css={styles.searchInput}>
              <SearchInput
                autoFocus={true}
                onChange={value => setSearchValue(value)}
                searchDelay={500}
                testid="search-icon"
                value={searchValue}
                alternative={true}
              />
            </div>
          </div>
          {!iconList.length && (
            <div css={styles.oops}>
              <CyText title="Oops, no result found!" titleAlignment={ViewModel.Alignment.CENTER} />
            </div>
          )}
          <div css={styles.iconPickerWrapper}>
            {!!iconList.length &&
              iconList.map(icon => (
                <ToolTip text={icon} key={icon}>
                  <button
                    type="button"
                    css={[styles.iconButton, selected === icon && styles.selectedIconButton]}
                    data-testid={icon}
                    onClick={() => setSelected(icon)}
                  >
                    <Icon name={icon} testid="icon-to-pick" size="28px" />
                    <div data-testid="icon-name">{icon}</div>
                  </button>
                </ToolTip>
              ))}
          </div>
        </div>
        <div>
          <div css={styles.inputContainer}>
            <Input autoFocus={true} label="Media" onChange={changeUrl} testid="image-url-input" type={GeneralModel.JSONSchemaFormat.URL} value={selected} />
            {!!selected && (
              <div css={styles.selectedImage}>
                {
                  /* istanbul ignore next */ error ? (
                    <div css={styles.imageNotFound}>
                      <Icon name="broken_image" size="60px" />
                      <h3>Image not found</h3>
                      <span>Please make sure your URL is valid</span>
                    </div>
                  ) : (
                    <Image alt="selected-icon" testid="selected-img" onError={() => setError(true)} src={selected} />
                  )
                }
              </div>
            )}
          </div>
        </div>
      </Layout>
    </Modal>
  );
};
