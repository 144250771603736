import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useState } from 'react';

interface SearchContextValue {
  searchQuery: string;
  onSearch: (v) => void;
  isSomeResults: boolean;
  setBuilderHasResults: (v) => void;
  setAppsHaveResults: (v) => void;
}

const SearchContext = createContext<SearchContextValue | undefined>(undefined);

export const SearchContextProvider = ({ children }: { children: ReactNode }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const [builderHasResults, setBuilderHasResults] = useState(false);
  const [appsHaveResults, setAppsHaveResults] = useState(false);

  const onSearch = useCallback(value => setSearchQuery(value), []);

  return (
    <SearchContext.Provider value={{ searchQuery, onSearch, setBuilderHasResults, setAppsHaveResults, isSomeResults: builderHasResults || appsHaveResults }}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = () => {
  const context = useContext(SearchContext);

  if (!context) {
    throw new Error('useSearchContext must be used within a SearchContextProvider');
  }

  return context;
};
