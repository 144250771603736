import { ClientEngineContext } from '@cyferd/client-engine';
import { useContext, useState, useMemo, useEffect } from 'react';

export const useBannerData = () => {
  const { useUserSelector } = useContext(ClientEngineContext);
  const user = useUserSelector();
  const [isSmallScreen, setIsSmallScreen] = useState(window.matchMedia('(max-width: 768px)').matches);
  const bannerData = useMemo(() => user?.tenant?.banner ?? {}, [user?.tenant?.banner]);
  const isBannerTop = bannerData?.position === 'top' || isSmallScreen;
  const containBannerData = Object.keys(bannerData).length > 0;

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.matchMedia('(max-width: 768px)').matches);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    bannerData,
    containBannerData,
    isBannerTop
  };
};
