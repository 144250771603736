import { COLOR } from '@constants';

export const OnlyLogo = ({ width = 16, height = 16, color = COLOR.NEUTRAL_2 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M55.0374 15.3645L29.5607 1L3 15.3645V44.3645L29.5607 59L55.0374 44.3645L12.215 20.514L29.5607 10.486L55.0374 25.1215L29.5607 38.9439L12.215 29.729L29.5607 20.514L55.0374 34.6075L29.5607 49.243L12.215 38.9439L55.0374 15.3645Z"
        stroke={color}
      />
    </svg>
  );
};

export const OnlyLogoV2 = ({ width = 16, height = 16, color = COLOR.NEUTRAL_2 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M52 17.625L30.4583 5.47919L8.00003 17.625V42.1458L30.4583 54.5208L52 42.1458L15.7917 21.9792L30.4583 13.5L52 25.875L30.4583 37.5625L15.7917 29.7708L30.4583 21.9792L52 33.8958L30.4583 46.2708L15.7917 37.5625L52 17.625Z"
        stroke={color}
        stroke-width="1.5"
      />
    </svg>
  );
};
