import { flip, offset, shift, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import React, { useEffect, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection, $isRangeSelection } from 'lexical';
import { getSelectedNode } from '../../utils/positioning';
import { $findMatchingParent } from '@lexical/utils';
import {
  $deleteTableColumn__EXPERIMENTAL,
  $deleteTableRow__EXPERIMENTAL,
  $insertTableColumn__EXPERIMENTAL,
  $insertTableRow__EXPERIMENTAL,
  $isTableCellNode,
  $isTableNode
} from '@lexical/table';
import { styles } from '@components/elements/RichTextEditor/RichEditorComposition/plugins/TableActionsPlugin/styles';
import { Icon } from '@components/elements/Icon';
import { styleHelpers } from '@utils/styleHelpers';
import { DropdownOption } from '@components/elements/DropdownOption';
import { DropdownMenu } from '@components/elements/DropdownMenu';
import { COLOR } from '@constants';
import type { GeneralModel } from '@cyferd/client-engine';

// TODO: call on right click
export const TableActionsPlugin = () => {
  const [isTableSelected, setIsTableSelected] = useState(false);

  const [editor] = useLexicalComposerContext();

  const { x, y, strategy, refs, update, context } = useFloating({
    middleware: [
      offset(({ rects }) => ({
        mainAxis: -rects.reference.height / 2 - rects.floating.height / 2,
        crossAxis: rects.reference.width / 2 - 10
      })),
      flip(),
      shift({ padding: 8 })
    ],
    open: isTableSelected,
    onOpenChange: setIsTableSelected
  });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  useEffect(() => {
    const updateToolbar = () => {
      editor.getEditorState().read(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          const node = getSelectedNode(selection);
          const tableNode = $findMatchingParent(node, $isTableCellNode);

          const tableElement = editor.getElementByKey(tableNode?.getKey());
          if (tableElement && $isTableCellNode(tableNode)) {
            setIsTableSelected(true);
            refs.setReference(tableElement);
            update();
          } else {
            setIsTableSelected(false);
          }
        } else {
          setIsTableSelected(false);
        }
      });
    };

    updateToolbar();

    const listener = editor.registerUpdateListener(updateToolbar);

    return () => listener();
  }, [editor, refs, update]);

  if (!isTableSelected) return null;

  const handleDeleteTable = () => {
    editor.update(() => {
      const selection = $getSelection();

      if ($isRangeSelection(selection)) {
        const node = getSelectedNode(selection);
        const tableNode = $findMatchingParent(node, $isTableNode);

        tableNode.remove();
      }

      setIsTableSelected(false);
    });
  };

  const handleInsertTableRowAtSelection = (shouldInsertAfter: boolean) => editor.update(() => $insertTableRow__EXPERIMENTAL(shouldInsertAfter));

  const handleDeleteTableRowAtSelection = () => editor.update(() => $deleteTableRow__EXPERIMENTAL());

  const handleInsertTableColumnAtSelection = (shouldInsertRight: boolean) => editor.update(() => $insertTableColumn__EXPERIMENTAL(shouldInsertRight));

  const handleDeleteTableColumnAtSelection = () => editor.update(() => $deleteTableColumn__EXPERIMENTAL());

  return (
    <div
      ref={refs.setFloating}
      {...getFloatingProps()}
      style={{
        position: strategy,
        top: y ?? 0,
        left: x ?? 0
      }}
    >
      <DropdownMenu
        horizontalResponsive={false}
        {...getReferenceProps()}
        renderTrigger={({ onClick, ref }) => (
          <div ref={ref} onClick={onClick} css={[styleHelpers.flexBetween, styleHelpers.cursorPointer]}>
            <Icon name="keyboard_arrow_down" />
          </div>
        )}
      >
        <div css={styles.selectorWrapper}>
          <DropdownOption
            key={tableActionsOptions[0].value}
            image={tableActionsOptions[0].icon}
            title={tableActionsOptions[0].label as string}
            value={tableActionsOptions[0].value}
            onClick={() => handleInsertTableRowAtSelection(false)}
            imageOutlined={true}
          />
          <DropdownOption
            key={tableActionsOptions[1].value}
            image={tableActionsOptions[1].icon}
            title={tableActionsOptions[1].label as string}
            value={tableActionsOptions[1].value}
            onClick={() => handleInsertTableRowAtSelection(true)}
            imageOutlined={true}
          />
          <hr css={styles.hr} />
          <DropdownOption
            key={tableActionsOptions[2].value}
            image={tableActionsOptions[2].icon}
            title={tableActionsOptions[2].label as string}
            value={tableActionsOptions[2].value}
            onClick={() => handleInsertTableColumnAtSelection(false)}
            imageOutlined={true}
          />
          <DropdownOption
            key={tableActionsOptions[3].value}
            image={tableActionsOptions[3].icon}
            title={tableActionsOptions[3].label as string}
            value={tableActionsOptions[3].value}
            onClick={() => handleInsertTableColumnAtSelection(true)}
            imageOutlined={true}
          />
          <hr css={styles.hr} />
          <DropdownOption
            key={tableActionsOptions[4].value}
            image={tableActionsOptions[4].icon}
            title={tableActionsOptions[4].label as string}
            imageFill={tableActionsOptions[4].color}
            value={tableActionsOptions[4].value}
            onClick={handleDeleteTableRowAtSelection}
            imageOutlined={true}
          />
          <DropdownOption
            key={tableActionsOptions[5].value}
            image={tableActionsOptions[5].icon}
            title={tableActionsOptions[5].label as string}
            imageFill={tableActionsOptions[5].color}
            value={tableActionsOptions[5].value}
            onClick={handleDeleteTableColumnAtSelection}
            imageOutlined={true}
          />
          <DropdownOption
            key={tableActionsOptions[6].value}
            image={tableActionsOptions[6].icon}
            title={tableActionsOptions[6].label as string}
            imageFill={tableActionsOptions[6].color}
            value={tableActionsOptions[6].value}
            onClick={handleDeleteTable}
            imageOutlined={true}
          />
        </div>
      </DropdownMenu>
    </div>
  );
};

const tableActionsOptions = [
  { value: 'insert_row_above', label: 'Insert row above', icon: 'add' },
  { value: 'insert_row_below', label: 'Insert row below', icon: 'add' },
  { value: 'insert_col_left', label: 'Insert column left', icon: 'add' },
  { value: 'insert_col_right', label: 'Insert column right', icon: 'add' },
  { value: 'delete_row', label: <span css={styles.deleteLabel}>Delete row</span>, icon: 'delete', color: COLOR.HC_5 as GeneralModel.Color.ThemeColor },
  { value: 'delete_col', label: <span css={styles.deleteLabel}>Delete column</span>, icon: 'delete', color: COLOR.HC_5 as GeneralModel.Color.ThemeColor },
  { value: 'delete_table', label: <span css={styles.deleteLabel}>Delete all table</span>, icon: 'delete', color: COLOR.HC_5 as GeneralModel.Color.ThemeColor }
];
