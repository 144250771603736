import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';
import { $isRangeSelection, PASTE_COMMAND, $getSelection } from 'lexical';
import { $createQuoteNode } from '@lexical/rich-text';
import { $generateNodesFromDOM } from '@lexical/html';

import { $createExtendedTextNode } from '../nodes/ExtendedTextNode';

const parser = new DOMParser();

export const PastePlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      PASTE_COMMAND,
      (event: ClipboardEvent) => {
        const clipboardData = event.clipboardData;
        const htmlContent = clipboardData.getData('text/html');

        if (htmlContent) {
          event.preventDefault();

          const dom = parser.parseFromString(htmlContent, 'text/html');
          const lexicalNodes = $generateNodesFromDOM(editor, dom);

          const modifiedNodes = lexicalNodes.map(node => {
            if (node.getType() === 'quote') {
              const blockquoteNode = $createQuoteNode();

              const textContent = node.getTextContent() || '';
              const textNode = $createExtendedTextNode(textContent);

              blockquoteNode.append(textNode);

              return blockquoteNode;
            }

            return node;
          });

          editor.update(() => {
            const selection = $getSelection();
            if (selection && $isRangeSelection(selection)) {
              selection.insertNodes(modifiedNodes);
            }
          });

          return true;
        }

        return false;
      },
      1
    );
  }, [editor]);

  return null;
};
